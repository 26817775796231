<template>
  <div class="o-layout">
    <Loader :loading="isLoading"></Loader>
    <div class="o-layout__primary" :class="{ 'is-loading' : editingLoading }" v-if="dataLoaded">

      <section class="c-section" v-if="productData.slug != 'custom-product' && availableOptions.species">

        <h2 class="c-section__heading">Species</h2>
        <ul class="c-options-list">

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">
              {{ productData.speciesLabelOverride ? productData.speciesLabelOverride : 'Wood Type' }}<br/>
              <small v-if="productData.speciesHelperText">({{ productData.speciesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.speciesLabelOverride ? productData.speciesLabelOverride : 'Wood Type' }}<br/>
                <small v-if="productData.speciesHelperText">({{ productData.speciesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.species" @change="speciesDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.species">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

        </ul>
      </section>

      <section class="c-section" v-if="availableOptions.modules && productData.slug != 'custom-product'">
        <h2 class="c-section__heading">Modules</h2>
        <ul class="c-options-list">
          <li class="c-options-list__row" v-if="selectedOptions.module0.code">
            <h3 class="c-options-list__heading">Module 1 (LSF)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 1 (LSF)</label>
              <div class="c-select">
                <select v-model="selectedOptions.module0" @change="moduleConditions(0)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[0]"
                    :value="{id: option.id, code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
            <div class="c-options-list__input-group">
              <figure class="c-options-list__line-art c-options-list__line-art--module">
                <img :src="fetchModuleLineArt" alt="Module line art">
              </figure>
            </div>
          </li>

          <li class="c-options-list__row" v-if="selectedOptions.module1.code">
            <h3 class="c-options-list__heading">Module 2 (Arm)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 2 (Arm)</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && selectedOptions.module0.code === 'SET' },'c-select']">
                <select v-model="selectedOptions.module1" :disabled="this.isThreeSeat === 1 && selectedOptions.module0.code === 'SET'" @change="moduleConditions(1)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[1]"
                    :value="{id: option.id, code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="selectedOptions.module2.code">
            <h3 class="c-options-list__heading">Module 3</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 3</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && selectedOptions.module0.code === 'SET' },'c-select']">
                <select v-model="selectedOptions.module2" :disabled="this.isThreeSeat === 1 && selectedOptions.module0.code === 'SET'" @change="moduleConditions(2)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[2]"
                    :value="{id: option.id, code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="selectedOptions.module3.code">
            <h3 class="c-options-list__heading">Module 4 (Arm)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 4 (Arm)</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && selectedOptions.module2.code === 'SET' },'c-select']">
                <select v-model="selectedOptions.module3" :disabled="this.isThreeSeat === 1 && selectedOptions.module2.code === 'SET'" @change="moduleConditions(3)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[3]"
                    :value="{id: option.id, code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="selectedOptions.module4.code">
            <h3 class="c-options-list__heading">Module 5 (RSF)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 5 (RSF)</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && selectedOptions.module2.code === 'SET' },'c-select']">
                <select v-model="selectedOptions.module4" :disabled="this.isThreeSeat === 1 && selectedOptions.module2.code === 'SET'" @change="moduleConditions(4)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[4]"
                    :value="{id: option.id, code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <section class="c-section" v-if="productData.slug != 'custom-product'">
        <div style="display:none;">{{ noTableTops = (this.isTwoSeat === 1 || this.isThreeSeat === 1) && (selectedOptions.module0.code != 'INL') && (selectedOptions.module2.code != 'INL') && (selectedOptions.module4.code != 'INL')}}</div>

        <h2 class="c-section__heading">Options</h2>
        <ul class="c-options-list">

          <li class="c-options-list__row" v-if="availableOptions.armHeights">
            <h3 class="c-options-list__heading">
              {{ productData.armHeightLabelOverride ? productData.armHeightLabelOverride : 'Arm Height' }}<br/>
              <small v-if="productData.armHeightHelperText">({{ productData.armHeightHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.armHeightLabelOverride ? productData.armHeightLabelOverride : 'Arm Height' }}<br/>
                <small v-if="productData.armHeightHelperText">({{ productData.armHeightHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.armHeight">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name, armYardage: option.armYardage}" v-for="(option, index) in productData.armHeights">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.finishType">
            <h3 class="c-options-list__heading">
                Finish Type
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                  Finish Type
              </label>
              <div class="c-select">
                <select id="finishType" name="finishType" v-model="selectedOptions.finishType">
                  <option value="woodFinish" selected>Wood Finish</option>
                  <option value="paintedWood">Painted Wood</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && !availableOptions.legMaterials && !(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3' || productData.title == 'HK01-01' || productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && !this.isMaru() && !(availableOptions.finishType && selectedOptions.finishType === 'paintedWood')">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="woodFrameFinish" name="woodFrameFinish" v-model="selectedOptions.woodFinish">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WCW' && option.code !== 'WCB') && selectedOptions.species.code === 'A') || (availableOptions.species && option.code === 'WCW' && selectedOptions.species.code === 'W') || (availableOptions.species && option.code === 'WCB' && selectedOptions.species.code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.paintedWood && !(availableOptions.finishType && selectedOptions.finishType === 'woodFinish')">
            <h3 class="c-options-list__heading">
              {{ productData.paintedWoodLabelOverride ? productData.paintedWoodLabelOverride : 'Painted Wood' }}<br/>
              <small v-if="productData.paintedWoodHelperText">({{ productData.paintedWoodHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.paintedWoodLabelOverride ? productData.paintedWoodLabelOverride : 'Painted Wood' }}<br/>
                <small v-if="productData.paintedWoodHelperText">({{ productData.paintedWoodHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="paintedWoodFrameFinish" name="paintedWoodFrameFinish" v-model="selectedOptions.paintedWood">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.paintedWood">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.tableTopSizes">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopSizeLabelOverride ? productData.tableTopSizeLabelOverride : 'Table Top Size' }}<br/>
              <small v-if="productData.tableTopSizeHelperText">({{ productData.tableTopSizeHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopSizeLabelOverride ? productData.tableTopSizeLabelOverride : 'Table Top Size' }}<br/>
                <small v-if="productData.tableTopSizeHelperText">({{ productData.tableTopSizeHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.tableTopSize">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopSizes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.armCapMaterials">
            <h3 class="c-options-list__heading">
              {{ productData.armCapMaterialLabelOverride ? productData.armCapMaterialLabelOverride : 'Arm Cap Material' }}<br/>
              <small v-if="productData.armCapMaterialHelperText">({{ productData.armCapMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.armCapMaterialLabelOverride ? productData.armCapMaterialLabelOverride : 'Arm Cap Material' }}<br/>
                <small v-if="productData.armCapMaterialHelperText">({{ productData.armCapMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.armCapMaterial">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.armCapMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.corian.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.corian">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.armCapMaterial.code !== 'WAC'">
              <input class="c-input" type="text" id="armCapMaterialName" name="armCapMaterialName" placeholder="Arm Cap Material Name (Optional)" v-model="selectedOptions.armCapMaterialName" />
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.pads" @change="padsDependencies">
            <h3 class="c-options-list__heading">
              {{ productData.padsLabelOverride ? productData.padsLabelOverride : 'Pads' }}<br/>
              <small v-if="productData.padsHelperText">({{ productData.padsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.padsLabelOverride ? productData.padsLabelOverride : 'Pads' }}<br/>
                <small v-if="productData.padsHelperText">({{ productData.padsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.pads">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name, padYardage: option.padYardage}" v-for="option in productData.pads">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.tables">
            <h3 class="c-options-list__heading">
              {{ productData.tablesLabelOverride ? productData.tablesLabelOverride : 'Tables' }}<br/>
              <small v-if="productData.tablesHelperText">({{ productData.tablesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tablesLabelOverride ? productData.tablesLabelOverride : 'Tables' }}<br/>
                <small v-if="productData.tablesHelperText">({{ productData.tablesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.tables" :disabled="((productData.title == 'SG03-IND-S5-86W-BC2-BG4' || productData.title == 'SG03-IND-S5-86W-BC4-BG2') && selectedOptions.powerModules.code !== 'XX')" @change="tablesVsPower">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tables">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.partitions">
            <h3 class="c-options-list__heading">
              {{ productData.partitionsLabelOverride ? productData.partitionsLabelOverride : 'Partitions' }}<br/>
              <small v-if="productData.partitionsHelperText">({{ productData.partitionsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.partitionsLabelOverride ? productData.partitionsLabelOverride : 'Partitions' }}<br/>
                <small v-if="productData.partitionsHelperText">({{ productData.partitionsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.partitions">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.partitions" v-if="!availableOptions.species || (availableOptions.species && (option.code === 'XX' || (option.code === 'SCR1B' && selectedOptions.species.code === 'B') || (option.code === 'SCR1A' && selectedOptions.species.code === 'A') || (option.code === 'SCR1W' && selectedOptions.species.code === 'W') || (option.code === 'SCR1OM' && selectedOptions.species.code === 'OM')))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.tableTopMaterials && !isMaru()) || (isMaru() && ((availableOptions.tableTopMaterials && !availableOptions.tables) || (availableOptions.tableTopMaterials && availableOptions.tables && selectedOptions.tables.code != 'XX')))">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialLabelOverride ? productData.tableTopMaterialLabelOverride : 'Table Top Material' }}<br/>
              <small v-if="productData.tableTopMaterialHelperText">({{ productData.tableTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialLabelOverride ? productData.tableTopMaterialLabelOverride : 'Table Top Material' }}<br/>
                <small v-if="productData.tableTopMaterialHelperText">({{ productData.tableTopMaterialHelperText }})</small>
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="selectedOptions.tableTopMaterial" :disabled="noTableTops" @change="speciesDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterials" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WTW' && option.code !== 'VTW' && option.code !== 'VTB' && option.code !== 'WTB') && selectedOptions.species.code === 'A') || (availableOptions.species && (option.code === 'WTW' && option.code === 'VTW') && selectedOptions.species.code === 'W') || (availableOptions.species && (option.code !== 'WTA' && option.code !== 'VTA' && option.code !== 'VTB' && option.code !== 'WTB') && selectedOptions.species.code === 'W') || (availableOptions.species && (option.code === 'WTA' && option.code === 'VTA') && selectedOptions.species.code === 'A') || (availableOptions.species && (option.code === 'VTB' || option.code === 'FTT' || option.code === 'WTB') && selectedOptions.species.code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small v-if="(productData.tableTopMaterials && noTableTops === false)" class="c-select--more-info">
                <div v-for="(material, index) in productData.tableTopMaterials" v-if="material.code == 'FTT' && pricelists.forbo.length">
                    <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
                </div>

                <div v-for="(material, index) in productData.tableTopMaterials" v-if="material.code == 'CT1' && pricelists.corian.length">
                    <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.corian">Browse {{ option.title }}</a>
                </div>

                <div v-for="(material, index) in productData.tableTopMaterials" v-if="material.code == 'LTT' && pricelists.laminates.length">
                    <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.laminates">Browse {{ option.title }}</a>
                </div>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="(selectedOptions.tableTopMaterial.code !== 'WTT' && selectedOptions.tableTopMaterial.code !== 'WTA' && selectedOptions.tableTopMaterial.code !== 'WTW' && selectedOptions.tableTopMaterial.code !== 'VTA' && selectedOptions.tableTopMaterial.code !== 'VTW' && selectedOptions.tableTopMaterial.code !== 'WTB' && selectedOptions.tableTopMaterial.code !== 'VTB' && selectedOptions.tableTopMaterial.code !== 'UPH' && selectedOptions.tableTopMaterial.code !== 'FTT' && selectedOptions.tableTopMaterial.code !== 'MTT') && !isBSide()">
              <input class="c-input" type="text" id="tableTopMaterialName" name="tableTopMaterialName" placeholder="Table Top Material Name (Optional)" v-model="selectedOptions.tableTopMaterialName" :disabled="noTableTops" />
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.tableTopMaterial.code === 'FTT' && !isBSide()">
                <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                    <select v-model="selectedOptions.tableTopMaterialForboFinish" :disabled="noTableTops">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.tableTopMaterialFinishesSideA && availableOptions.tableTopMaterials)">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialFinishSideALabelOverride ? productData.tableTopMaterialFinishSideALabelOverride : ((selectedOptions.tableTopMaterial.code === 'FTT' || selectedOptions.tableTopMaterial.code === 'LTT') ? 'Table Top Finish - Side A' : 'Table Top Finish') }}
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialFinishSideALabelOverride ? productData.tableTopMaterialFinishSideALabelOverride : ((selectedOptions.tableTopMaterial.code === 'FTT' || selectedOptions.tableTopMaterial.code === 'LTT') ? 'Table Top Finish - Side A' : 'Table Top Finish') }}<br/>
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="selectedOptions.tableTopMaterialFinishSideA" :disabled="noTableTops">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialFinishesSideA" v-if="(selectedOptions.tableTopMaterial.code === 'VTA' && isAshFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'VTW' && isWalnutFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'FTT' && isForboFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'SST' && isSolidSurfaceFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'CTT' && isCorkFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'RTT' && isReallyFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'LTT' && isLaminateFinish(option.code))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.tableTopMaterialFinishesSideB && (availableOptions.tableTopMaterials && (selectedOptions.tableTopMaterial.code === 'FTT' || selectedOptions.tableTopMaterial.code === 'LTT')))">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialFinishSideBLabelOverride ? productData.tableTopMaterialFinishSideBLabelOverride : 'Table Top Finish - Side B' }}
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialFinishSideBLabelOverride ? productData.tableTopMaterialFinishSideBLabelOverride : 'Table Top Finish - Side B' }}<br/>
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="selectedOptions.tableTopMaterialFinishSideB" :disabled="noTableTops">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialFinishesSideB" v-if="(selectedOptions.tableTopMaterial.code === 'VTA' && isAshFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'VTW' && isWalnutFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'FTT' && isForboFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'SST' && isSolidSurfaceFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'CTT' && isCorkFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'RTT' && isReallyFinish(option.code)) || (selectedOptions.tableTopMaterial.code === 'LTT' && isLaminateFinish(option.code))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.tableTopMaterialMetalFinishes">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialMetalFinishLabelOverride ? productData.tableTopMaterialMetalFinishLabelOverride : 'Metal Finishes' }}
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialMetalFinishLabelOverride ? productData.tableTopMaterialMetalFinishLabelOverride : 'Metal Finishes' }}
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="selectedOptions.tableTopMaterialMetalFinish" :disabled="selectedOptions.tableTopMaterial.code !== 'MTT'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialMetalFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.metal.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.metal">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && !availableOptions.legMaterials && !(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3' || productData.title == 'HK01-01' || productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && this.isMaru()">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="woodFrameFinish" name="woodFrameFinish" v-model="selectedOptions.woodFinish">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WCW' && option.code !== 'WCB') && selectedOptions.species.code === 'A') || (availableOptions.species && option.code === 'WCW' && selectedOptions.species.code === 'W') || (availableOptions.species && option.code === 'WCB' && selectedOptions.species.code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && !availableOptions.legMaterials && (productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && selectedOptions.tableTopMaterial.code != 'FTT'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="woodFrameFinish" name="woodFrameFinish" v-model="selectedOptions.woodFinish">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="(option.code !== 'WCW' && selectedOptions.tableTopMaterial.code === 'VTA') || (option.code === 'WCW' && selectedOptions.tableTopMaterial.code === 'VTW')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.powerModules">
            <h3 class="c-options-list__heading">
              {{ productData.powerModulesLabelOverride ? productData.powerModulesLabelOverride : '(No. of) Power Modules' }}<br/>
              <small v-if="productData.powerModulesHelperText">({{ productData.powerModulesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.powerModulesLabelOverride ? productData.powerModulesLabelOverride : '(No. of) Power Modules' }}<br/>
                <small v-if="productData.powerModulesHelperText">({{ productData.powerModulesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.powerModules" :disabled="((productData.title == 'SG03-IND-S5-86W-BC2-BG4' || productData.title == 'SG03-IND-S5-86W-BC4-BG2') && selectedOptions.tables.code !== 'XX')">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.powerModules" v-if="!(option.code === 'PM2' && productData.name === 'Maru 3-slat, 3/4-length Indoor Configuration 3' && selectedOptions.tables.code !== 'XX')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.multTextilesOption === true || availableOptions.textileBasicOptions === true) && (productData.title != 'KN01-S1' || (productData.title == 'KN01-S1' && selectedOptions.tableTopMaterial.code == 'UPH'))">

            <div class="c-options-list__multiple-headings">
              <h3 class="c-options-list__heading" v-if="availableOptions.multTextilesOption === true">Textile Configuration</h3>

              <label for="armTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AT'">
                Arm <small>(Total Yardage: {{ (productData.armHeights.length ? parseFloat(selectedOptions.armHeight.armYardage).toFixed(2) : productData.yardage.arm) }} yards)</small>
              </label>
              <label for="armBackTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'ST'">
                Arm, Back <small>(Total Yardage: {{ (productData.armHeights.length ? (parseFloat(selectedOptions.armHeight.armYardage) + parseFloat(productData.yardage.back)).toFixed(2) : parseFloat(productData.yardage.arm) + parseFloat(productData.yardage.back)) }} yards)</small>
              </label>
              <label for="armSeatTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'BT'">
                Arm, Seat <small>(Total Yardage: {{ (productData.armHeights.length ? (parseFloat(selectedOptions.armHeight.armYardage) + parseFloat(productData.yardage.seat)).toFixed(2) : parseFloat(productData.yardage.arm) + parseFloat(productData.yardage.seat)) }} yards)</small>
              </label>
              <label for="backTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'BT')">
                Back <small>(Total Yardage: {{ productData.yardage.back }} yards)</small>
              </label>
              <label for="seatTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'ST')">
                Seat <small>(Total Yardage: {{ (productData.pads.length ? (selectedOptions.pads.code !== 'XX' ? (parseFloat(selectedOptions.pads.padYardage)).toFixed(2) : productData.yardage.seat) : productData.yardage.seat) }} yards)</small>
              </label>
              <label for="seatBackTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AT'">
                Seat, Back <small>(Total Yardage: {{ parseFloat(productData.yardage.back) + parseFloat(productData.yardage.seat) }} yards)</small>
              </label>
              <label for="sideATextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AB'">
                Side A <small>(Total Yardage: {{ parseFloat(productData.yardage.sideA) }} yards)</small>
              </label>
              <label for="sideBTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AB'">
                Side B <small>(Total Yardage: {{ parseFloat(productData.yardage.sideB) }} yards)</small>
              </label>
              <label for="singleTextileGrade" class="single-heading" v-if="availableOptions.textileBasicOptions && (selectedOptions.multTextilesOption.code != 'AT' && selectedOptions.multTextilesOption.code != 'TA' && selectedOptions.multTextilesOption.code != 'BT' && selectedOptions.multTextilesOption.code != 'ST' && selectedOptions.multTextilesOption.code != 'AB')">
                {{ productData.textileLabelOverride ? productData.textileLabelOverride : 'Textile' }} <small>(Total Yardage: {{ moduleYardage > 0 ? moduleYardage : (productData.armHeights.length ? (parseFloat(selectedOptions.armHeight.armYardage) + parseFloat(productData.yardage.seat) + parseFloat(productData.yardage.back)).toFixed(2) : (productData.pads.length ? (selectedOptions.pads.code !== 'XX' ? (parseFloat(selectedOptions.pads.padYardage) + parseFloat(productData.yardage.seat) + parseFloat(productData.yardage.back)).toFixed(2) : productData.yardage.total) : productData.yardage.total)) }} yards)</small><br/>
                <small v-if="productData.textileHelperText">({{ productData.textileHelperText }})</small>
              </label>
            </div>

            <div class="c-options-list__input-group">
              <label for="multTextilesOption" class="u-hidden-visually">Textile Configuration</label>
              <div v-if="availableOptions.multTextilesOption === true" class="c-select">
                <select id="multTextilesOption" name="multTextilesOption" v-model="selectedOptions.multTextilesOption" @change="textileConditions">
                  <option disabled="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.multTextilesOption">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AT'">
                <br/>
                <div class="c-select">
                  <select id="armTextileGrade" name="armTextileGrade" v-model="selectedOptions.armTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.armTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'ST'">
                <br/>
                <div class="c-select">
                  <select id="armBackTextileGrade" name="armBackTextileGrade" v-model="selectedOptions.armBackTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.armBackTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'BT'">
                <br/>
                <div class="c-select">
                  <select id="armSeatTextileGrade" name="armSeatTextileGrade" v-model="selectedOptions.armSeatTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.armSeatTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'BT')">
                <br/>
                <div class="c-select">
                  <select id="backTextileGrade" name="backTextileGrade" v-model="selectedOptions.backTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.backTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'ST')">
                <br/>
                <div class="c-select">
                  <select id="seatTextileGrade" name="seatTextileGrade" v-model="selectedOptions.seatTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.seatTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AT'">
                <br/>
                <div class="c-select">
                  <select id="seatBackTextileGrade" name="seatBackTextileGrade" v-model="selectedOptions.seatBackTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.seatBackTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AB'">
                <br/>
                <div class="c-select">
                  <select id="sideATextileGrade" name="sideATextileGrade" v-model="selectedOptions.sideATextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.sideATextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AB'">
                <br/>
                <div class="c-select">
                  <select id="sideBTextileGrade" name="sideBTextileGrade" v-model="selectedOptions.sideBTextileGrade" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.sideBTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.textileBasicOptions && (selectedOptions.multTextilesOption.code != 'AT' && selectedOptions.multTextilesOption.code != 'TA' && selectedOptions.multTextilesOption.code != 'BT' && selectedOptions.multTextilesOption.code != 'ST' && selectedOptions.multTextilesOption.code != 'AB')">
                <br/>
                <div class="c-select">
                  <select id="singleTextileGrade" name="singleTextileGrade" v-model="selectedOptions.singleTextileGrade" :disabled="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code !== 'XX'">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="selectedOptions.singleTextileGrade.code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>

              <small class="c-select--more-info" v-if="pricelists.textiles.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.textiles">Browse {{ option.title }}</a>
              </small>
            </div>

            <div class="c-options-list__input-group">
              <div class="c-select" v-if="availableOptions.multTextilesOption === true" style="visibility: hidden;">
                <select></select>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AT'">
                <br/>
                <input class="c-input" type="text" id="armTextileGradeName" name="armTextileGradeName" placeholder="Arm Textile Name (Optional)" v-model="selectedOptions.armTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'ST'">
                <br/>
                <input class="c-input" type="text" id="armBackTextileGradeName" name="armBackTextileGradeName" placeholder="Arm, Back Textile Name (Optional)" v-model="selectedOptions.armBackTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'BT'">
                <br/>
                <input class="c-input" type="text" id="armSeatTextileGradeName" name="armSeatTextileGradeName" placeholder="Arm, Seat Textile Name (Optional)" v-model="selectedOptions.armSeatTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'BT')">
                <br/>
                <input class="c-input" type="text" id="backTextileGradeName" name="backTextileGradeName" placeholder="Back Textile Name (Optional)" v-model="selectedOptions.backTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'ST')">
                <br/>
                <input class="c-input" type="text" id="seatTextileGradeName" name="seatTextileGradeName" placeholder="Seat Textile Name (Optional)" v-model="selectedOptions.seatTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AT'">
                <br/>
                <input class="c-input" type="text" id="seatBackTextileGradeName" name="seatBackTextileGradeName" placeholder="Seat, Back Textile Name (Optional)" v-model="selectedOptions.seatBackTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AB'">
                <br/>
                <input class="c-input" type="text" id="sideATextileGradeName" name="sideATextileGradeName" placeholder="Side A Textile Name (Optional)" v-model="selectedOptions.sideATextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && selectedOptions.multTextilesOption.code === 'AB'">
                <br/>
                <input class="c-input" type="text" id="sideBTextileGradeName" name="sideBTextileGradeName" placeholder="Side B Textile Name (Optional)" v-model="selectedOptions.sideBTextileGradeName" />
              </div>
              <div v-if="availableOptions.textileBasicOptions && (selectedOptions.multTextilesOption.code != 'AT' && selectedOptions.multTextilesOption.code != 'TA' && selectedOptions.multTextilesOption.code != 'BT' && selectedOptions.multTextilesOption.code != 'ST' && selectedOptions.multTextilesOption.code != 'AB')">
                <br/>
                <input class="c-input" type="text" id="singleTextileGradeName" name="singleTextileGradeName" placeholder="Textile Name (Optional)" :disabled="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code !== 'XX'" v-model="selectedOptions.singleTextileGradeName" />
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.largeInsertTopMaterials">
            <h3 class="c-options-list__heading">
              {{ productData.largeInsertTopMaterialLabelOverride ? productData.largeInsertTopMaterialLabelOverride : 'Large Insert Top Material' }}<br/>
              <small v-if="productData.largeInsertTopMaterialHelperText">({{ productData.largeInsertTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.largeInsertTopMaterialLabelOverride ? productData.largeInsertTopMaterialLabelOverride : 'Large Insert Top Material' }}<br/>
                <small v-if="productData.largeInsertTopMaterialHelperText">({{ productData.largeInsertTopMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="largeInsertTopMaterial" name="largeInsertTopMaterial" v-model="selectedOptions.largeInsertTopMaterial" @change="insertDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.largeInsertTopMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="selectedOptions.largeInsertTopMaterial.code == 'FTL' && pricelists.forbo.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
              </small>
              <small class="c-select--more-info" v-if="selectedOptions.largeInsertTopMaterial.code == 'SSL' && pricelists.solidSurface.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.solidSurface">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.largeInsertTopMaterial.code === 'FTL'">
                <div class="c-select">
                    <select v-model="selectedOptions.largeInsertTopMaterialForboFinish">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.largeInsertTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.largeInsertTopMaterial.code === 'SSL'">
                <div class="c-select">
                    <select v-model="selectedOptions.largeInsertTopMaterialSolidSurfaceFinish">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.largeInsertTopMaterialSolidSurfaceFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.smallInsertTopMaterials && productData.title === 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
              <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
                <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="smallInsertTopMaterial" name="smallInsertTopMaterial" v-model="selectedOptions.smallInsertTopMaterial" @change="idaShelfDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="selectedOptions.smallInsertTopMaterial.code == 'FTS' && pricelists.forbo.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
              </small>
              <small class="c-select--more-info" v-if="selectedOptions.smallInsertTopMaterial.code == 'SSS' && pricelists.solidSurface.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.solidSurface">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.smallInsertTopMaterial.code === 'FTS'">
                <div class="c-select">
                    <select v-model="selectedOptions.smallInsertTopMaterialForboFinish">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.smallInsertTopMaterial.code === 'SSS'">
                <div class="c-select">
                    <select v-model="selectedOptions.smallInsertTopMaterialSolidSurfaceFinish">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialSolidSurfaceFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.smallInsertTopMaterials && productData.title !== 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
              <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
                <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="smallInsertTopMaterial" name="smallInsertTopMaterial" v-model="selectedOptions.smallInsertTopMaterial" @change="insertDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="selectedOptions.smallInsertTopMaterial.code == 'FTS' && pricelists.forbo.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
              </small>
              <small class="c-select--more-info" v-if="selectedOptions.smallInsertTopMaterial.code == 'SSS' && pricelists.solidSurface.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.solidSurface">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.smallInsertTopMaterial.code === 'FTS'">
                <div class="c-select">
                    <select v-model="selectedOptions.smallInsertTopMaterialForboFinish">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div class="c-options-list__input-group" v-if="selectedOptions.smallInsertTopMaterial.code === 'SSS'">
                <div class="c-select">
                    <select v-model="selectedOptions.smallInsertTopMaterialSolidSurfaceFinish">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialSolidSurfaceFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.caseworkSurfaceMaterials">
            <h3 class="c-options-list__heading">
              {{ productData.caseworkSurfaceMaterialsLabelOverride ? productData.caseworkSurfaceMaterialsLabelOverride : 'Case Surface Material' }}<br/>
              <small v-if="productData.caseworkSurfaceMaterialsHelperText">({{ productData.caseworkSurfaceMaterialsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.caseworkSurfaceMaterialsLabelOverride ? productData.caseworkSurfaceMaterialsLabelOverride : 'Case Surface Material' }}<br/>
                <small v-if="productData.caseworkSurfaceMaterialsHelperText">({{ productData.caseworkSurfaceMaterialsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.caseworkSurfaceMaterial">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.caseworkSurfaceMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>

              <small class="c-select--more-info" v-if="pricelists.corian.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.corian">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group">
              <input class="c-input" type="text" id="caseworkSurfaceMaterialName" name="caseworkSurfaceMaterialName" placeholder="Case Surface Material Name (Optional)" v-model="selectedOptions.caseworkSurfaceMaterialName" />
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.columnShroudPaints">
            <h3 class="c-options-list__heading">
              {{ productData.columnShroudPaintLabelOverride ? productData.columnShroudPaintLabelOverride : 'Column Shroud Paint' }}<br/>
              <small v-if="productData.columnShroudPaintHelperText">({{ productData.columnShroudPaintHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.columnShroudPaintLabelOverride ? productData.columnShroudPaintLabelOverride : 'Column Shroud Paint' }}<br/>
                <small v-if="productData.columnShroudPaintHelperText">({{ productData.columnShroudPaintHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.columnShroudPaint">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.columnShroudPaints">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.legMaterials && productData.title !== 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
              <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
                <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.legMaterial">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.legMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3')">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.woodFinish" :disabled="selectedOptions.largeInsertTopMaterial.code !== 'VAL' && selectedOptions.largeInsertTopMaterial.code !== 'VWL' && selectedOptions.smallInsertTopMaterial.code !== 'VAS' && selectedOptions.smallInsertTopMaterial.code !== 'VWS'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="((selectedOptions.largeInsertTopMaterial.code === 'FTL' || selectedOptions.largeInsertTopMaterial.code === 'SSL') && (selectedOptions.smallInsertTopMaterial.code === 'FTS' || selectedOptions.smallInsertTopMaterial.code === 'SSS' || selectedOptions.smallInsertTopMaterial.code === 'STS' || selectedOptions.smallInsertTopMaterial.code === 'XXS')) || (option.code !== 'WCW' && (selectedOptions.largeInsertTopMaterial.code === 'VAL' || selectedOptions.smallInsertTopMaterial.code === 'VAS')) || (option.code === 'WCW' && (selectedOptions.largeInsertTopMaterial.code === 'VWL' || selectedOptions.smallInsertTopMaterial.code === 'VWS'))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && productData.title == 'HK01-01'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.woodFinish">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WCW' && option.code !== 'WCB') && selectedOptions.species.code === 'A') || (availableOptions.species && option.code === 'WCW' && selectedOptions.species.code === 'W') || (availableOptions.species && option.code === 'WCB' && selectedOptions.species.code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && availableOptions.smallInsertTopMaterials && productData.title === 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.woodFinish" :disabled="selectedOptions.smallInsertTopMaterial.code !== 'VAS' && selectedOptions.smallInsertTopMaterial.code !== 'VWS' && selectedOptions.smallInsertTopMaterial.code !== 'SAS' && selectedOptions.smallInsertTopMaterial.code !== 'SWS'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="(selectedOptions.smallInsertTopMaterial.code !== 'VAS' && selectedOptions.smallInsertTopMaterial.code !== 'VWS' && selectedOptions.smallInsertTopMaterial.code !== 'SAS' && selectedOptions.smallInsertTopMaterial.code !== 'SWS') || ((selectedOptions.smallInsertTopMaterial.code === 'VAS' || selectedOptions.smallInsertTopMaterial.code === 'SAS') && (option.code !== 'WCW' && option.code !== 'WCB')) || ((selectedOptions.smallInsertTopMaterial.code === 'VWS' || selectedOptions.smallInsertTopMaterial.code === 'SWS') && option.code === 'WCW')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.smallInsertTopMaterials && availableOptions.stoneQuartzTableTops">
            <h3 class="c-options-list__heading">
              {{ productData.stoneQuartzTableTopLabelOverride ? productData.stoneQuartzTableTopLabelOverride : 'Stone/Quartz Finishes' }}<br/>
              <small v-if="productData.stoneQuartzTableTopHelperText">({{ productData.stoneQuartzTableTopHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.stoneQuartzTableTopLabelOverride ? productData.stoneQuartzTableTopLabelOverride : 'Stone/Quartz Finishes' }}<br/>
                <small v-if="productData.stoneQuartzTableTopHelperText">({{ productData.stoneQuartzTableTopHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.stoneQuartzTableTop" :disabled="selectedOptions.smallInsertTopMaterial.code !== 'STS'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.stoneQuartzTableTops">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.legMaterials && productData.title === 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
              <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
                <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.legMaterial">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.legMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.metalFinishes && availableOptions.legMaterials) || (availableOptions.metalFinishes && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3' || productData.title == 'HK01-01' || productData.title == 'HK01-09' || productData.title == 'HK01-11') || (availableOptions.metalFinishes && this.isBSide()))">
            <h3 class="c-options-list__heading">
              {{ productData.metalFinishesLabelOverride ? productData.metalFinishesLabelOverride : 'Metal Finishes' }}<br/>
              <small v-if="productData.metalFinishesHelperText">({{ productData.metalFinishesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.metalFinishesLabelOverride ? productData.metalFinishesLabelOverride : 'Metal Finishes' }}<br/>
                <small v-if="productData.metalFinishesHelperText">({{ productData.metalFinishesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.metalFinish" :disabled="selectedOptions.legMaterial.code === 'LW'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.metalFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.metal.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.metal">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.swivelBaseTypes">
            <h3 class="c-options-list__heading">
              {{ productData.swivelBaseTypeLabelOverride ? productData.swivelBaseTypeLabelOverride : 'Swivel Base Types' }}<br/>
              <small v-if="productData.swivelBaseTypeHelperText">({{ productData.swivelBaseTypeHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.swivelBaseTypeLabelOverride ? productData.swivelBaseTypeLabelOverride : 'Swivel Base Types' }}<br/>
                <small v-if="productData.swivelBaseTypeHelperText">({{ productData.swivelBaseTypeHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.swivelBaseType">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.swivelBaseTypes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.leatherArmWraps">
            <h3 class="c-options-list__heading">
              {{ productData.leatherArmWrapLabelOverride ? productData.leatherArmWrapLabelOverride : 'Leather Arm Wraps' }}<br/>
              <small v-if="productData.leatherArmWrapHelperText">({{ productData.leatherArmWrapHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.leatherArmWrapLabelOverride ? productData.leatherArmWrapLabelOverride : 'Leather Arm Wraps' }}<br/>
                <small v-if="productData.leatherArmWrapHelperText">({{ productData.leatherArmWrapHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.leatherArmWrap">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.leatherArmWraps">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.leather.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.leather">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && availableOptions.legMaterials && productData.title !== 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.woodFinish" :disabled="selectedOptions.legMaterial.code === 'LM'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.moistureBarrier">
            <h3 class="c-options-list__heading">Moisture Barrier</h3>
            <div class="c-options-list__input-group">
              <label for="moistureBarrier" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="moistureBarrier" name="moistureBarrier" value="1" v-model="selectedOptions.moistureBarrier" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.removeableCovers">
            <h3 class="c-options-list__heading">Removeable Covers</h3>
            <div class="c-options-list__input-group">
              <label for="removeableCovers" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="removeableCovers" name="removeableCovers" value="1" v-model="selectedOptions.removeableCovers" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.calTb133">
            <h3 class="c-options-list__heading">CAL TB 133</h3>
            <div class="c-options-list__input-group">
              <label for="calTb133" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="calTb133" name="calTb133" value="1" v-model="selectedOptions.calTb133" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.casters">
            <h3 class="c-options-list__heading">Casters</h3>
            <div class="c-options-list__input-group">
              <label for="casters" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="casters" name="casters" value="1" v-model="selectedOptions.casters" :true-value="1" :false-value="0" :disabled="availableOptions.legMaterials && selectedOptions.legMaterial.code === 'LW'">
                <span class="c-checkbox__indicator"></span>
              </label>
              <div style="display:none;">{{ selectedOptions.casters = (availableOptions.legMaterials && selectedOptions.legMaterial.code === 'LW') ? 0 : selectedOptions.casters }}</div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.removeableArmPads">
            <h3 class="c-options-list__heading">Removeable Arm Pads (Pair)</h3>
            <div class="c-options-list__input-group">
              <label for="removeableArmPads" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="removeableArmPads" name="removeableArmPads" value="1" v-model="selectedOptions.removeableArmPads" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.hangingFixture">
            <h3 class="c-options-list__heading">Hanging Fixture</h3>
            <div class="c-options-list__input-group">
              <label for="hangingFixture" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="hangingFixture" name="hangingFixture" value="1" v-model="selectedOptions.hangingFixture" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="productData.hinges[0]">
            <h3 class="c-options-list__heading">Hinge Setup #1</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Hinge Setup #1</label>
              <div class="c-select">
                <select v-model="selectedOptions.hinge0">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.hinges[0]">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="productData.hinges[1]">
            <h3 class="c-options-list__heading">Hinge Setup #2</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Hinge Setup #2</label>
              <div class="c-select">
                <select v-model="selectedOptions.hinge1">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.hinges[1]">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="productData.hinges[2]">
            <h3 class="c-options-list__heading">Hinge Setup #3</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Hinge Setup #3</label>
              <div class="c-select">
                <select v-model="selectedOptions.hinge2">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.hinges[2]">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.pulls">
            <h3 class="c-options-list__heading">
              {{ productData.pullsLabelOverride ? productData.pullsLabelOverride : 'Pulls' }}<br/>
              <small v-if="productData.pullsHelperText">({{ productData.pullsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.pullsLabelOverride ? productData.pullsLabelOverride : 'Pulls' }}<br/>
                <small v-if="productData.pullsHelperText">({{ productData.pullsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.pull">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.pulls">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
            <div class="c-options-list__input-group">
              <figure class="c-options-list__line-art">
                <img :src="fetchPullsLineArt" alt="Pulls line art">
              </figure>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.wraps">
            <h3 class="c-options-list__heading">
              {{ productData.wrapLabelOverride ? productData.wrapLabelOverride : 'Wrap' }}<br/>
              <small v-if="productData.wrapHelperText">({{ productData.wrapHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.wrapLabelOverride ? productData.wrapLabelOverride : 'Wrap' }}<br/>
                <small v-if="productData.wrapHelperText">({{ productData.wrapHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.wrap">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.wraps">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.drawerLiner">
            <h3 class="c-options-list__heading">Drawer Liner</h3>
            <div class="c-options-list__input-group">
              <label for="drawerLiner" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="drawerLiner" name="drawerLiner" value="1" v-model="selectedOptions.drawerLiner" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.veneerOuterCase">
            <h3 class="c-options-list__heading">
              {{ productData.veneerOuterCaseLabelOverride ? productData.veneerOuterCaseLabelOverride : 'Veneer Outer Case' }}<br/>
              <small v-if="productData.veneerOuterCaseHelperText">({{ productData.veneerOuterCaseHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.veneerOuterCaseLabelOverride ? productData.veneerOuterCaseLabelOverride : 'Veneer Outer Case' }}<br/>
                <small v-if="productData.veneerOuterCaseHelperText">({{ productData.veneerOuterCaseHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="selectedOptions.veneerOuterCase">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.veneerOuterCase">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.paperTowelDispenser">
            <h3 class="c-options-list__heading">Paper Towerl Dispenser Cut-Out</h3>
            <div class="c-options-list__input-group">
              <label for="paperTowelDispenser" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="paperTowelDispenser" name="paperTowelDispenser" value="1" v-model="selectedOptions.paperTowelDispenser" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.extraAdjustableShelf">
            <h3 class="c-options-list__heading">Extra Adustable Shelf</h3>
            <div class="c-options-list__input-group">
              <label for="extraAdjustableShelf" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="extraAdjustableShelf" name="extraAdjustableShelf" value="1" v-model="selectedOptions.extraAdjustableShelf" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.fileHolders">
            <h3 class="c-options-list__heading">File Holders</h3>
            <div class="c-options-list__input-group">
              <label for="fileHolders" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="fileHolders" name="fileHolders" value="1" v-model="selectedOptions.fileHolders" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.gloveCutOuts">
            <h3 class="c-options-list__heading">Glove Cut Outs</h3>
            <div class="c-options-list__input-group">
              <label for="gloveCutOuts" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="gloveCutOuts" name="gloveCutOuts" value="1" v-model="selectedOptions.gloveCutOuts" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>
        </ul>
      </section>

      <section class="c-section" v-if="productData.slug == 'custom-product'">
        <div style="display:none;">{{ noTableTops = (this.isTwoSeat === 1 || this.isThreeSeat === 1) && (selectedOptions.module0.code != 'INL') && (selectedOptions.module2.code != 'INL') && (selectedOptions.module4.code != 'INL')}}</div>

        <h2 class="c-section__heading">Product Details</h2>
        <ul class="c-options-list">

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Product Name</h3>
            <div class="c-options-list__input-group">
              <label for="customProductName" class="c-label u-hidden-visually">Product Name</label>
              <input class="c-input" type="text" id="customProductName" name="customProductName" v-model="customProductName" />
            </div>
          </li>

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Product Code</h3>
            <div class="c-options-list__input-group">
              <label for="customProductCode" class="c-label u-hidden-visually">Product Code</label>
              <input class="c-input" type="text" id="customProductCode" name="customProductCode" v-model="customProductCode" v-uppercase />
            </div>
          </li>

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Product Description</h3>
            <div class="c-options-list__input-group">
              <label for="productDescription" class="c-label u-hidden-visually">productDescription</label>
              <ckeditor :editor="editor" v-model="productDescription" :config="editorConfig"></ckeditor>
            </div>
          </li>

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Options, Finishes & Textiles</h3>
            <div class="c-options-list__input-group">
              <label for="optionsFinishesTextiles" class="c-label u-hidden-visually">Options, Finishes & Textiles</label>
              <textarea cols="30" rows="5" class="c-input" id="optionsFinishesTextiles" name="optionsFinishesTextiles" v-model="optionsFinishesTextiles"></textarea>
            </div>
          </li>

        </ul>
      </section>

      <section class="c-section">
        <h2 class="c-section__heading">Additional Details</h2>

        <li class="c-options-list__row">
          <h3 class="c-options-list__heading">Product Tag(s)</h3>
          <div class="c-options-list__input-group">
            <label for="productTag" class="c-label u-hidden-visually">Product Tag(s)</label>
            <input class="c-input" type="text" id="productTag" name="productTag" v-model="selectedOptions.productTag" />
          </div>
        </li>

        <li class="c-options-list__row">
          <h3 class="c-options-list__heading">Additional Notes</h3>
          <div class="c-options-list__input-group">
            <label for="additionalNotes" class="c-label u-hidden-visually">Additional Notes</label>
            <textarea cols="30" rows="5" class="c-input" id="additionalNotes" name="additionalNotes" v-model="selectedOptions.additionalNotes"></textarea>
          </div>
        </li>
      </section>
    </div>

    <div class="o-layout__secondary" v-if="dataLoaded">
      <div class="c-sidebar">
        <h3 class="c-sidebar__heading" v-if="productData.slug != 'custom-product'">{{ productData.name }}</h3>
        <h3 class="c-sidebar__heading" v-if="productData.slug == 'custom-product'">Custom Product<br>{{ customProductName }}</h3>

        <div class="c-line-item" v-if="productData.slug != 'custom-product'">
          <span class="c-line-item__label">{{ productData.title }}</span>
          <span class="c-line-item__price">List Price<br>{{ productData.baseCom | formatPrice }}</span>
        </div>

        <h3 class="c-sidebar__heading" v-if="availableOptions.modules && productData.slug != 'custom-product'">Modules</h3>
        <ul class="c-sidebar__list" v-if="availableOptions.modules && productData.slug != 'custom-product'">
          <li class="c-sidebar__list-item" v-if="selectedOptions.module0.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.module0.code }} - {{ selectedOptions.module0.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.module0.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.module1.code && !(this.isThreeSeat === 1 && selectedOptions.module0.code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.module1.code }} - {{ selectedOptions.module1.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.module1.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.module2.code && !(this.isThreeSeat === 1 && selectedOptions.module0.code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.module2.code }} - {{ selectedOptions.module2.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.module2.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.module3.code && !(this.isThreeSeat === 1 && selectedOptions.module2.code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.module3.code }} - {{ selectedOptions.module3.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.module3.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.module4.code && !(this.isThreeSeat === 1 && selectedOptions.module2.code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.module4.code }} - {{ selectedOptions.module4.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.module4.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>
        </ul>

        <h3 class="c-sidebar__heading" v-if="productData.slug != 'custom-product'">Options, Finishes, &amp; Textiles</h3>
        <ul class="c-sidebar__list" v-if="productData.slug != 'custom-product'">

          <li class="c-sidebar__list-item" v-if="availableOptions.species && selectedOptions.species.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.species.code }} - {{ selectedOptions.species.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.species.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.armHeights && selectedOptions.armHeight.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.armHeight.code }} - {{ selectedOptions.armHeight.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.armHeight.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tableTopSize.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tableTopSize.code }} - {{ selectedOptions.tableTopSize.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tableTopSize.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.armCapMaterial.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.armCapMaterial.code }} - {{ selectedOptions.armCapMaterial.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.armCapMaterial.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.pads.code && selectedOptions.pads.code !== 'XX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.pads.code }} - {{ selectedOptions.pads.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.pads.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tables.code && selectedOptions.tables.code !== 'XX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tables.code }} - {{ selectedOptions.tables.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tables.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.partitions.code && selectedOptions.partitions.code !== 'XX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.partitions.code }} - {{ selectedOptions.partitions.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.partitions.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tableTopMaterial.code && ((((isTwoSeat === 1 || isThreeSeat === 1) && (selectedOptions.module0.code === 'INL' || selectedOptions.module2.code === 'INL' || selectedOptions.module4.code === 'INL')) || (availableOptions.modules === false) && !isMaru()) || (isMaru() && ((availableOptions.tableTopMaterials && !availableOptions.tables) || (availableOptions.tableTopMaterials && availableOptions.tables && selectedOptions.tables.code != 'XX'))))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tableTopMaterial.code }} - {{ selectedOptions.tableTopMaterial.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tableTopMaterial.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tableTopMaterialFinishSideA.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tableTopMaterialFinishSideA.code }} - {{ selectedOptions.tableTopMaterialFinishSideA.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tableTopMaterialFinishSideA.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tableTopMaterialFinishSideB.code && ((availableOptions.tableTopMaterials && (selectedOptions.tableTopMaterial.code === 'FTT' || selectedOptions.tableTopMaterial.code === 'LTT')))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tableTopMaterialFinishSideB.code }} - {{ selectedOptions.tableTopMaterialFinishSideB.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tableTopMaterialFinishSideB.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tableTopMaterial.code === 'FTT' && selectedOptions.tableTopMaterialForboFinish.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tableTopMaterialForboFinish.code }} - {{ selectedOptions.tableTopMaterialForboFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tableTopMaterialForboFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.tableTopMaterial.code === 'MTT' && selectedOptions.tableTopMaterialMetalFinish.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.tableTopMaterialMetalFinish.code }} - {{ selectedOptions.tableTopMaterialMetalFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.tableTopMaterialMetalFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.largeInsertTopMaterial.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.largeInsertTopMaterial.code }} - {{ selectedOptions.largeInsertTopMaterial.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.largeInsertTopMaterial.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.largeInsertTopMaterial.code === 'FTL' && selectedOptions.largeInsertTopMaterialForboFinish.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.largeInsertTopMaterialForboFinish.code }} - {{ selectedOptions.largeInsertTopMaterialForboFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.largeInsertTopMaterialForboFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.largeInsertTopMaterial.code === 'SSL' && selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code }} - {{ selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.smallInsertTopMaterial.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.smallInsertTopMaterial.code }} - {{ selectedOptions.smallInsertTopMaterial.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.smallInsertTopMaterial.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.smallInsertTopMaterial.code === 'FTS' && selectedOptions.smallInsertTopMaterialForboFinish.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.smallInsertTopMaterialForboFinish.code }} - {{ selectedOptions.smallInsertTopMaterialForboFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.smallInsertTopMaterialForboFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.smallInsertTopMaterial.code === 'SSS' && selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code }} - {{ selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="productData.title === 'NC01-T1' && selectedOptions.woodFinish.code && (selectedOptions.smallInsertTopMaterial.code == 'VAS' || selectedOptions.smallInsertTopMaterial.code == 'VWS' || selectedOptions.smallInsertTopMaterial.code == 'SAS' || selectedOptions.smallInsertTopMaterial.code == 'SWS')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.woodFinish.code }} - {{ selectedOptions.woodFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.woodFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.smallInsertTopMaterial.code === 'STS' && selectedOptions.stoneQuartzTableTop.code && productData.title === 'NC01-T1'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.stoneQuartzTableTop.code }} - {{ selectedOptions.stoneQuartzTableTop.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.stoneQuartzTableTop.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.caseworkSurfaceMaterial.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.caseworkSurfaceMaterial.code }} - {{ selectedOptions.caseworkSurfaceMaterial.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.caseworkSurfaceMaterial.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.columnShroudPaint.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.columnShroudPaint.code }} - {{ selectedOptions.columnShroudPaint.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.columnShroudPaint.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.legMaterial.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.legMaterial.code }} - {{ selectedOptions.legMaterial.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.legMaterial.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(selectedOptions.metalFinish.code && selectedOptions.legMaterial.code === 'LM') || (selectedOptions.metalFinish.code && isBSide())">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.metalFinish.code }} - {{ selectedOptions.metalFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.metalFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="!((productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && selectedOptions.tableTopMaterial.code == 'FTT') && !(availableOptions.finishType && selectedOptions.finishType === 'paintedWood') && ((selectedOptions.woodFinish.code && selectedOptions.legMaterial.code !== 'LM') && !(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3')) || ((productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3') && (selectedOptions.largeInsertTopMaterial.code == 'VAL' || selectedOptions.largeInsertTopMaterial.code == 'VWL' || selectedOptions.smallInsertTopMaterial.code == 'VAS' || selectedOptions.smallInsertTopMaterial.code == 'VWS'))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.woodFinish.code }} - {{ selectedOptions.woodFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.woodFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.paintedWood.code && !(availableOptions.finishType && selectedOptions.finishType === 'woodFinish')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.paintedWood.code }} - {{ selectedOptions.paintedWood.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.paintedWood.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.metalFinish.code && (productData.title == 'HK01-01' || productData.title == 'HK01-09' || productData.title == 'HK01-11')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.metalFinish.code }} - {{ selectedOptions.metalFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.metalFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.smallInsertTopMaterial.code === 'STS' && selectedOptions.stoneQuartzTableTop.code && productData.title !== 'NC01-T1'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.stoneQuartzTableTop.code }} - {{ selectedOptions.stoneQuartzTableTop.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.stoneQuartzTableTop.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.swivelBaseType.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.swivelBaseType.code }} - {{ selectedOptions.swivelBaseType.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.swivelBaseType.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.leatherArmWrap.code && selectedOptions.leatherArmWrap.code !== 'LXXX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.leatherArmWrap.code }} - {{ selectedOptions.leatherArmWrap.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.leatherArmWrap.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.metalFinish.code && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.metalFinish.code }} - {{ selectedOptions.metalFinish.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.metalFinish.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.powerModules.code && selectedOptions.powerModules.code !== 'XX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.powerModules.code }} - {{ selectedOptions.powerModules.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.powerModules.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.moistureBarrier">
            <div class="c-line-item">
              <span class="c-line-item__label">MB - Moisture Barrier</span>
              <span class="c-line-item__price">{{ productData.pricing.MB | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.removeableCovers">
            <div class="c-line-item">
              <span class="c-line-item__label">RC - Removeable Covers</span>
              <span class="c-line-item__price">{{ productData.pricing.RC | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.calTb133">
            <div class="c-line-item">
              <span class="c-line-item__label">FB - CAL TB 133</span>
              <span class="c-line-item__price">{{ productData.pricing.FB | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.casters">
            <div class="c-line-item">
              <span class="c-line-item__label">CAS - Casters</span>
              <span class="c-line-item__price">{{ productData.pricing.CAS | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.removeableArmPads">
            <div class="c-line-item">
              <span class="c-line-item__label">AP - Removeable Arm Pads (Pair)</span>
              <span class="c-line-item__price">{{ productData.pricing.AP | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.hangingFixture">
            <div class="c-line-item">
              <span class="c-line-item__label">DJ - Hanging Fixture</span>
              <span class="c-line-item__price">{{ productData.pricing.DJ | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.hinge0.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.hinge0.code }} - {{ selectedOptions.hinge0.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.hinge0.code+'1'.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.hinge1.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.hinge1.code }} - {{ selectedOptions.hinge1.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.hinge1.code+'2'.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.hinge2.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.hinge2.code }} - {{ selectedOptions.hinge2.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.hinge2.code+'3'.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.pull.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.pull.code }} - {{ selectedOptions.pull.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.pull.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.wrap.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.wrap.code }} - {{ selectedOptions.wrap.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.wrap.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.drawerLiner">
            <div class="c-line-item">
              <span class="c-line-item__label">Drawer Liner</span>
              <span class="c-line-item__price">{{ productData.pricing.DL | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.veneerOuterCase.code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.veneerOuterCase.code }} - {{ selectedOptions.veneerOuterCase.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.veneerOuterCase.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.paperTowelDispenser">
            <div class="c-line-item">
              <span class="c-line-item__label">Paper Towerl Dispenser Cut-Out</span>
              <span class="c-line-item__price">{{ productData.pricing.TD | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.extraAdjustableShelf">
            <div class="c-line-item">
              <span class="c-line-item__label">Extra Adjustable Shelf</span>
              <span class="c-line-item__price">{{ productData.pricing.AS | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.fileHolders">
            <div class="c-line-item">
              <span class="c-line-item__label">File Holders</span>
              <span class="c-line-item__price">{{ productData.pricing.FH | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.gloveCutOuts">
            <div class="c-line-item">
              <span class="c-line-item__label">Glove Cut Outs</span>
              <span class="c-line-item__price">{{ productData.pricing.GH | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="selectedOptions.multTextilesOption.code && selectedOptions.multTextilesOption.code !== 'XX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ selectedOptions.multTextilesOption.code }} - {{ selectedOptions.multTextilesOption.name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[selectedOptions.multTextilesOption.code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(availableOptions.textileBasicOptions && (selectedOptions.multTextilesOption.code != 'AT' && selectedOptions.multTextilesOption.code != 'TA' && selectedOptions.multTextilesOption.code != 'BT' && selectedOptions.multTextilesOption.code != 'ST' && selectedOptions.multTextilesOption.code != 'AB') && (productData.title != 'KN01-S1' || (productData.title == 'KN01-S1' && selectedOptions.tableTopMaterial.code == 'UPH')))">
            <div class="c-line-item">
              <span class="c-line-item__label">Textile - {{ selectedOptions.singleTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ singleTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code === 'AT'">
            <div class="c-line-item">
              <span class="c-line-item__label">Arm Textile - {{ selectedOptions.armTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ armTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code === 'ST'">
            <div class="c-line-item">
              <span class="c-line-item__label">Arm, Back Textile - {{ selectedOptions.armBackTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ (armTextilePrice + backTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code === 'BT'">
            <div class="c-line-item">
              <span class="c-line-item__label">Arm, Seat Textile - {{ selectedOptions.armSeatTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ (armTextilePrice + backTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'BT')">
            <div class="c-line-item">
              <span class="c-line-item__label">Back Textile - {{ selectedOptions.backTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ backTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && (selectedOptions.multTextilesOption.code === 'TA' || selectedOptions.multTextilesOption.code === 'ST')">
            <div class="c-line-item">
              <span class="c-line-item__label">Seat Textile - {{ selectedOptions.seatTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ seatTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code === 'AT'">
            <div class="c-line-item">
              <span class="c-line-item__label">Seat, Back Textile - {{ selectedOptions.seatBackTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ (seatTextilePrice + backTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code === 'AB'">
            <div class="c-line-item">
              <span class="c-line-item__label">Side A Textile - {{ selectedOptions.sideATextileGrade.name }}</span>
              <span class="c-line-item__price">{{ (sideATextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && selectedOptions.multTextilesOption.code === 'AB'">
            <div class="c-line-item">
              <span class="c-line-item__label">Side B Textile - {{ selectedOptions.sideBTextileGrade.name }}</span>
              <span class="c-line-item__price">{{ (sideBTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item">
            <div class="c-line-item">
              <span class="c-line-item__label c-line-item__label--semi-bold"><br/>Total List</span>
              <span class="c-line-item__price"><br/>{{ (unitListPrice + unitTextilePrice) | formatPrice }}</span>
            </div>
          </li>
        </ul>

        <h3 class="c-sidebar__heading c-sidebar__heading--primary">
          {{ productData.slug != 'custom-product' ? fullCode : this.customProductCode }}
        </h3>

        <figure class="c-sidebar__overview-line-art c-sidebar--sticky" v-if="productData && productData.slug != 'custom-product'">
          <transition name="fade">
            <img :src="fetchLineArtUrl" alt="Product line art">
          </transition>
        </figure>

        <div class="c-sidebar__overview">
          <div class="c-sidebar__input" v-if="productData.slug == 'custom-product'">
            <label for="" class="c-label">List Price ($)</label>
            <input type="text" class="c-input" v-model.number="productData.baseCom">
          </div>

          <div class="c-sidebar__half-half-inputs">
            <div class="c-sidebar__half-half-inputs__input-wrapper">
              <label for="" class="c-label">Quantity</label>
              <input type="number" class="c-input" v-model.number="quantity" min="1">
            </div>

            <div class="c-sidebar__half-half-inputs__input-wrapper">
              <label for="" class="c-label">Discount (%)</label>
              <input type="number" class="c-input" v-model.number="productDiscount" :min="0" :max="100">
            </div>
          </div>
          
          <div class="c-line-item c-line-item--center">
            <div>
              <label class="c-checkbox c-checkbox--stacked" for="oversizeGoods">
                Oversize Goods
                <input class="c-checkbox__input" type="checkbox" id="oversizeGoods" name="oversizeGoods" value="0" v-model="oversizeGoods" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>

          </div>

          <div class="c-line-item" v-if="extendedListPrice">
            <span class="c-line-item__label c-line-item__label--semi-bold">Extended List</span>
            <span class="c-line-item__price">{{ extendedListPrice + extendedTextilePrice | formatPrice }}</span>
          </div>

          <div class="c-line-item" v-if="extendedNetPrice">
            <span class="c-line-item__label c-line-item__label--semi-bold">Extended Net</span>
            <span class="c-line-item__price">{{ extendedNetPrice | formatNetPrice }}</span>
          </div>

          <button class="c-button c-button--primary c-button--fill" @click.prevent="saveProduct" v-bind:disabled="isLoading === 1">Add product to estimate</button>

          <div class="c-line-item c-line-item--center" style="justify-content: center;">
            <a :href="'/quote/edit/'+quoteId">Cancel</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import currency from 'currency.js';
import Loader from './Loader';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { print } from 'graphql';
import gql from 'graphql-tag';

const apiToken = 'sGev6T821A4zxzZvxpjpqgoN4e7LIcRB';
const apiUrl = '/api';
const saveQuoteMutation = gql`
    mutation quoteMutation(
        $id: ID,
        $quoteID: String,
        $title: String,
        $customerName: String,
        $company: String,
        $customerContact: String,
        $emailAddress: String,
        $customerPhone: String,
        $project: String,
        $address: String,
        $terms: String,
        $shippingType: String,
        $shipTo: String,
        $specifiedBy: String,
        $dealer: String,
        $architect: String,
        $contact: String,
        $shipDate: String,
        $createdBy: String,
        $leadTime: String,
        $notes: String,
        $productsJSON: productsJSON_MatrixInput,
        $salesTax: Number,
        $freightSurcharge: Number,
        $liftGate: Number,
        $liftGateUpcharge: Number,
        $residentialDelivery: Number,
        $residentialDeliveryUpcharge: Number,
        $deliveryAppointment: Number,
        $deliveryAppointmentUpcharge: Number,
        $totalListPrice: Number,
        $totalTextilePrice: Number,
        $totalNetPrice: Number,
        $totalTaxPrice: Number,
        $totalQuotePrice: Number,
        $minimumOrderSurcharge: Number,
        $minimumOrderThreshold: Number,
        $lastSavedDiscount: Number,
        $quoteType: String,
        $waiveMinimumOrderSurcharge: String,
        $authorId: ID,
        $lastApprovalRequestDate: String,
        $approvalState: String,
        $approvalMessage: String,
        $dateQuoteUpdated: String,
        $validUntilDate: String,
        $lastSaveBy: [Int]
    ) {
        save_quotes_quotes_Entry(
            id: $id,
            quoteID: $quoteID,
            title: $title,
            customerName: $customerName,
            company: $company,
            customerContact: $customerContact,
            emailAddress: $emailAddress,
            customerPhone: $customerPhone,
            project: $project,
            address: $address,
            terms: $terms,
            shippingType: $shippingType,
            shipTo: $shipTo,
            specifiedBy: $specifiedBy,
            dealer: $dealer,
            architect: $architect,
            contact: $contact,
            shipDate: $shipDate,
            createdBy: $createdBy,
            leadTime: $leadTime,
            notes: $notes,
            productsJSON: $productsJSON,
            salesTax: $salesTax,
            freightSurcharge: $freightSurcharge,
            liftGate: $liftGate,
            liftGateUpcharge: $liftGateUpcharge,
            residentialDelivery: $residentialDelivery,
            residentialDeliveryUpcharge: $residentialDeliveryUpcharge,
            deliveryAppointment: $deliveryAppointment,
            deliveryAppointmentUpcharge: $deliveryAppointmentUpcharge,
            totalListPrice: $totalListPrice,
            totalTextilePrice: $totalTextilePrice,
            totalNetPrice: $totalNetPrice,
            totalTaxPrice: $totalTaxPrice,
            totalQuotePrice: $totalQuotePrice,
            minimumOrderSurcharge: $minimumOrderSurcharge,
            minimumOrderThreshold: $minimumOrderThreshold,
            lastSavedDiscount: $lastSavedDiscount,
            quoteType: $quoteType,
            waiveMinimumOrderSurcharge: $waiveMinimumOrderSurcharge,
            authorId: $authorId,
            lastApprovalRequestDate: $lastApprovalRequestDate,
            approvalState: $approvalState,
            approvalMessage: $approvalMessage,
            dateQuoteUpdated: $dateQuoteUpdated,
            validUntilDate: $validUntilDate,
            lastSaveBy: $lastSaveBy
        ) {
            id
        }
    }
`;
const configureApi = (url, token) => {
  return {
    baseURL: url,
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest'
    }
  };
};
 // Execute a GraphQL query by sending an XHR to our api endpoint
const executeMutation = (api, query, variables, callback) => {
  api.post('', {
    query: print(query),
    variables: variables
  }).then((result) => {
    if (callback) {
      callback(result.data);
    }
  }).catch((error) => {
    console.log(error);
  })
};

export default {
  name: 'AddProduct',
  props: {
    'product-id': {
      type: Number,
      required: true
    },
    'quote-id': {
      type: Number,
      required: true
    },
    'user-id': {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [ 'bold', 'italic', 'link' ]
        },
        link: {
            addTargetToExternalLinks: true,
        }
      },
      quoteApi: axios.create(configureApi(apiUrl, apiToken)),
      editingLoading: 1,
      isLoading: 1,
      dataLoaded: 0,
      productSaved: false,
      quoteData: {},
      productData: {},
      productsSortOrder: [],
      formattedProductData: {},
      textileData: {},
      availableOptions: {
        species: false,
        modules: false,
        armHeights: false,
        tableTopSizes: false,
        armCapMaterials: false,
        pads: false,
        tables: false,
        partitions: false,
        powerModules: false,
        tableTopMaterials: false,
        tableTopMaterialFinishesSideA: false,
        tableTopMaterialFinishesSideB: false,
        tableTopMaterialForboFinishes: false,
        tableTopMaterialMetalFinishes: false,
        largeInsertTopMaterials: false,
        largeInsertTopMaterialForboFinishes: false,
        largeInsertTopMaterialSolidSurfaceFinishes: false,
        smallInsertTopMaterials: false,
        smallInsertTopMaterialForboFinishes: false,
        smallInsertTopMaterialSolidSurfaceFinishes: false,
        caseworkSurfaceMaterials: false,
        columnShroudPaints: false,
        legMaterials: false,
        finishType: false,
        woodFinishes: false,
        paintedWood: false,
        metalFinishes: false,
        stoneQuartzTableTops: false,
        swivelBaseTypes: false,
        leatherArmWraps: false,
        moistureBarrier: false,
        removeableCovers: false,
        calTb133: false,
        casters: false,
        removeableArmPads: false,
        hangingFixture: false,
        drawerLiner: false,
        paperTowelDispenser: false,
        extraAdjustableShelf: false,
        fileHolders: false,
        gloveCutOuts: false,
        hinges: false,
        pulls: false,
        wraps: false,
        veneerOuterCase: false,
        textileBasicOptions: false,
        multTextilesOption: false
      },
      selectedOptions: {
        module0: {},
        module1: {},
        module2: {},
        module3: {},
        module4: {},
        species: {},
        armHeight: {},
        tableTopSize: {},
        armCapMaterial: {},
        pads: {},
        tables: {},
        partitions: {},
        powerModules: {},
        tableTopMaterial: {},
        tableTopMaterialFinishSideA: {},
        tableTopMaterialFinishSideB: {},
        tableTopMaterialForboFinish: {},
        tableTopMaterialMetalFinish: {},
        largeInsertTopMaterial: {},
        largeInsertTopMaterialForboFinish: {},
        largeInsertTopMaterialSolidSurfaceFinish: {},
        smallInsertTopMaterial: {},
        smallInsertTopMaterialForboFinish: {},
        smallInsertTopMaterialSolidSurfaceFinish: {},
        caseworkSurfaceMaterial: {},
        columnShroudPaint: {},
        legMaterial: {},
        finishType: '',
        woodFinish: {},
        paintedWood: {},
        metalFinish: {},
        stoneQuartzTableTop: {},
        swivelBaseType: {},
        leatherArmWrap: {},
        moistureBarrier: 0,
        removeableCovers: 0,
        calTb133: 0,
        casters: 0,
        removeableArmPads: 0,
        hangingFixture: 0,
        hinge0: {},
        hinge1: {},
        hinge2: {},
        pull: {},
        wrap: {},
        drawerLiner: 0,
        veneerOuterCase: {},
        paperTowelDispenser: 0,
        extraAdjustableShelf: 0,
        fileHolders: 0,
        gloveCutOuts: 0,
        singleTextileGrade: {},
        multTextilesOption: {},
        armTextileGrade: {},
        armBackTextileGrade: {},
        armSeatTextileGrade: {},
        backTextileGrade: {},
        seatTextileGrade: {},
        seatBackTextileGrade: {},
        sideATextileGrade: {},
        sideBTextileGrade: {}
      },
      customProductName: '',
      customProductCode: 'CUSTOM',
      productDescription: '',
      optionsFinishesTextiles: '',
      quantity: 1,
      productDiscount: null,
      oversizeGoods: 0,
      isTwoSeat: 0,
      isThreeSeat: 0,
      singleTextilePrice: 0,
      armTextilePrice: 0,
      armBackTextilePrice: 0,
      armSeatTextilePrice: 0,
      backTextilePrice: 0,
      seatTextilePrice: 0,
      seatBackTextilePrice: 0,
      sideATextilePrice: 0,
      sideBTextilePrice: 0,
      moduleYardage: 0
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var vm = this;
      this.isLoading = 1;
      // Load quote data
      axios.get(`/quote/${this.quoteId}.json`)
        .then(function (response) {
          vm.quoteData = response.data;
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // Load product data
          axios.get(`/product/${vm.productId}.json`)
            .then(function (response) {
              vm.productData = response.data;
            })
            .catch(function (error) {
              console.log(error);
            })
            .then(function () {
              // Load textile data
              axios.get(`/textiles.json`)
                .then(function (response) {
                  vm.textileData = response.data;
                  
                  // If Orca, let's remove some textile options
                  if ((vm.productData.title === 'AB01-V') || (vm.productData.title === 'AB01-H')) {
                    
                    // Remove 2
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR02');
                    
                    // Remove 4
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR04');
                    
                    // Remove 6
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR06');
                    
                    // Remove 9
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR09');
                    
                    // Remove 11
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR11');
                    
                    // Remove 12
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR12');
                    
                    // Remove 13
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR13');
                    
                    // Remove 14
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR14');
                    
                    // Remove 15
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR15');
                  }
                  
                  
                  // Run functions
                  vm.validateOptions();
                  vm.setDefaultOptions();
                  vm.speciesDependencies();
                  vm.idaShelfDependencies();
                })
                .catch(function (error) {
                  console.log(error);
                })
                .then(function () {
                  // Load textile data
                  axios.get(`/pricelists.json`)
                    .then(function (response) {
                      vm.pricelists = response.data;
                      vm.editingLoading = 0;
                      vm.dataLoaded = 1;
                      vm.productDiscount = (vm.quoteData.lastSavedDiscount) ? vm.quoteData.lastSavedDiscount : 0;
                    })
                    .catch(function (error) {
                      console.log(error);
                    })
                    .then(function () {
                      vm.isLoading = 0;
                    })
                });
            });
        });
    },
    validateOptions() {
        this.availableOptions.species = (this.productData.species.length > 1 || (this.isMaru() && this.productData.species.length > 0)) ? true : false;
        this.availableOptions.modules = this.productData.modules.length ? true : false;
        this.availableOptions.armHeights = this.productData.armHeights.length ? true : false;
        this.availableOptions.tableTopSizes = this.productData.tableTopSizes.length ? true : false;
        this.availableOptions.armCapMaterials = this.productData.armCapMaterials.length ? true : false;
        this.availableOptions.pads = this.productData.pads.length ? true : false;
        this.availableOptions.tables = this.productData.tables.length ? true : false;
        this.availableOptions.partitions = this.productData.partitions.length ? true : false;
        this.availableOptions.powerModules = this.productData.powerModules.length ? true : false;
        this.availableOptions.tableTopMaterials = this.productData.tableTopMaterials.length ? true : false;
        this.availableOptions.tableTopMaterialFinishesSideA = this.productData.tableTopMaterialFinishesSideA.length ? true : false;
        this.availableOptions.tableTopMaterialFinishesSideB = this.productData.tableTopMaterialFinishesSideB.length ? true : false;
        this.availableOptions.tableTopMaterialForboFinishes = this.productData.tableTopMaterialForboFinishes.length ? true : false;
        this.availableOptions.tableTopMaterialMetalFinishes = this.productData.tableTopMaterialMetalFinishes.length ? true : false;
        this.availableOptions.largeInsertTopMaterials = this.productData.largeInsertTopMaterials.length ? true : false;
        this.availableOptions.largeInsertTopMaterialForboFinishes = this.productData.largeInsertTopMaterialForboFinishes.length ? true : false;
        this.availableOptions.largeInsertTopMaterialSolidSurfaceFinishes = this.productData.largeInsertTopMaterialSolidSurfaceFinishes.length ? true : false;
        this.availableOptions.smallInsertTopMaterials = this.productData.smallInsertTopMaterials.length ? true : false;
        this.availableOptions.smallInsertTopMaterialForboFinishes = this.productData.smallInsertTopMaterialForboFinishes.length ? true : false;
        this.availableOptions.smallInsertTopMaterialSolidSurfaceFinishes = this.productData.smallInsertTopMaterialSolidSurfaceFinishes.length ? true : false;
        this.availableOptions.caseworkSurfaceMaterials = this.productData.caseworkSurfaceMaterials.length ? true : false;
        this.availableOptions.columnShroudPaints = this.productData.columnShroudPaints.length ? true : false;
        this.availableOptions.legMaterials = this.productData.legMaterials.length ? true : false;
        this.availableOptions.finishType = (this.productData.woodFinishes.length && this.productData.paintedWood.length) ? true : false;
        this.availableOptions.woodFinishes = this.productData.woodFinishes.length ? true : false;
        this.availableOptions.paintedWood = this.productData.paintedWood.length ? true : false;
        this.availableOptions.metalFinishes = this.productData.metalFinishes.length ? true : false;
        this.availableOptions.stoneQuartzTableTops = this.productData.stoneQuartzTableTops.length ? true : false;
        this.availableOptions.swivelBaseTypes = this.productData.swivelBaseTypes.length ? true : false;
        this.availableOptions.leatherArmWraps = this.productData.leatherArmWraps.length ? true : false;
        this.availableOptions.moistureBarrier = this.productData.otherOptions.filter(option => (option.code === 'MB')).length > 0;
        this.availableOptions.removeableCovers = this.productData.otherOptions.filter(option => (option.code === 'RC')).length > 0;
        this.availableOptions.calTb133 = this.productData.otherOptions.filter(option => (option.code === 'FB')).length > 0;
        this.availableOptions.casters = this.productData.otherOptions.filter(option => (option.code === 'CAS')).length > 0;
        this.availableOptions.removeableArmPads = this.productData.otherOptions.filter(option => (option.code === 'AP')).length > 0;
        this.availableOptions.hangingFixture = this.productData.otherOptions.filter(option => (option.code === 'DJ')).length > 0;
        this.availableOptions.drawerLiner = this.productData.otherOptions.filter(option => (option.code === 'DL')).length > 0;
        this.availableOptions.paperTowelDispenser = this.productData.otherOptions.filter(option => (option.code === 'TD')).length > 0;
        this.availableOptions.extraAdjustableShelf = this.productData.otherOptions.filter(option => (option.code === 'AS')).length > 0;
        this.availableOptions.fileHolders = this.productData.otherOptions.filter(option => (option.code === 'FH')).length > 0;
        this.availableOptions.gloveCutOuts = this.productData.otherOptions.filter(option => (option.code === 'GH')).length > 0;
        this.availableOptions.hinges = this.productData.hinges.length ? true : false;
        this.availableOptions.pulls = this.productData.pulls.length ? true : false;
        this.availableOptions.wraps = this.productData.wraps.length ? true : false;
        this.availableOptions.veneerOuterCase = this.productData.veneerOuterCase.length ? true : false;
        this.availableOptions.multTextilesOption =
          (((this.productData.yardage.seat > 0 && this.productData.yardage.back > 0)
          || (this.productData.yardage.seat > 0 && this.productData.yardage.arm > 0)
          || (this.productData.yardage.seat > 0 && this.productData.yardage.tan > 0)
          || (this.productData.yardage.seat > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.back > 0 && this.productData.yardage.arm > 0)
          || (this.productData.yardage.back > 0 && this.productData.yardage.tan > 0)
          || (this.productData.yardage.back > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.arm > 0 && this.productData.yardage.tan > 0)
          || (this.productData.yardage.arm > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.tan > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.sideA > 0 && this.productData.yardage.sideB > 0))
          && this.productData.multTextilesOption.length)
            ? true : false;
        this.availableOptions.textileBasicOptions =
          (this.productData.yardage.seat > 0 ||
            this.productData.yardage.back > 0 ||
            this.productData.yardage.arm > 0 ||
            this.productData.yardage.tan > 0 ||
            this.productData.yardage.set > 0 ||
            this.productData.yardage.top > 0 ||
            this.productData.yardage.sideA > 0 ||
            this.productData.yardage.sideB > 0 ||
            this.productData.title == 'HK01-T1' ||
            this.productData.title == 'HK01-T2') ? true : false;
    },
    setDefaultOptions() {
        // Modules
        if (this.productData.modules.length === 3) {
            this.selectedOptions.module0 = this.productData.modules[0][0];
            this.selectedOptions.module1 = this.productData.modules[1][1];
            this.selectedOptions.module2 = this.productData.modules[2][0];
            this.isTwoSeat = 1;
            this.isThreeSeat = 0;
        } else if (this.productData.modules.length === 5) {
            this.selectedOptions.module0 = this.productData.modules[0][0];
            this.selectedOptions.module1 = this.productData.modules[1][1];
            this.selectedOptions.module2 = this.productData.modules[2][0];
            this.selectedOptions.module3 = this.productData.modules[3][1];
            this.selectedOptions.module4 = this.productData.modules[4][0];
            this.isTwoSeat = 0;
            this.isThreeSeat = 1;
        }

        // Hinges
        if (this.productData.hinges.length === 1) {
            this.selectedOptions.hinge0 = this.productData.hinges[0][0];
        } else if (this.productData.hinges.length === 2) {
            this.selectedOptions.hinge0 = this.productData.hinges[0][0];
            this.selectedOptions.hinge1 = this.productData.hinges[1][0];
        } else if (this.productData.hinges.length === 3) {
            this.selectedOptions.hinge0 = this.productData.hinges[0][0];
            this.selectedOptions.hinge1 = this.productData.hinges[1][0];
            this.selectedOptions.hinge2 = this.productData.hinges[2][0];
        }

        // Options
        if (this.availableOptions.species) this.selectedOptions.species = this.productData.species[0];
        if (this.availableOptions.armHeights) this.selectedOptions.armHeight = this.productData.armHeights[0];
        if (this.availableOptions.tableTopSizes) this.selectedOptions.tableTopSize = this.productData.tableTopSizes[0];
        if (this.availableOptions.armCapMaterials) this.selectedOptions.armCapMaterial = this.productData.armCapMaterials[0];
        if (this.availableOptions.pads) this.selectedOptions.pads = this.productData.pads[0];
        if (this.availableOptions.tables) this.selectedOptions.tables = this.productData.tables[0];
        if (this.availableOptions.partitions) this.selectedOptions.partitions = this.productData.partitions[0];
        if (this.availableOptions.powerModules) this.selectedOptions.powerModules = this.productData.powerModules[0];
        if (this.availableOptions.tableTopMaterials) this.selectedOptions.tableTopMaterial = this.productData.tableTopMaterials[0];
        if (this.availableOptions.tableTopMaterialFinishesSideA) this.selectedOptions.tableTopMaterialFinishSideA = this.productData.tableTopMaterialFinishesSideA[0];
        if (this.availableOptions.tableTopMaterialFinishesSideB) this.selectedOptions.tableTopMaterialFinishSideB = this.productData.tableTopMaterialFinishesSideB[0];
        if (this.availableOptions.tableTopMaterialForboFinishes) this.selectedOptions.tableTopMaterialForboFinish = this.productData.tableTopMaterialForboFinishes[0];
        if (this.availableOptions.tableTopMaterialMetalFinishes) this.selectedOptions.tableTopMaterialMetalFinish = this.productData.tableTopMaterialMetalFinishes[0];
        if (this.availableOptions.largeInsertTopMaterials) this.selectedOptions.largeInsertTopMaterial = this.productData.largeInsertTopMaterials[0];
        if (this.availableOptions.largeInsertTopMaterialForboFinishes) this.selectedOptions.largeInsertTopMaterialForboFinish = this.productData.largeInsertTopMaterialForboFinishes[0];
        if (this.availableOptions.largeInsertTopMaterialSolidSurfaceFinishes) this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish = this.productData.largeInsertTopMaterialSolidSurfaceFinishes[0];
        if (this.availableOptions.smallInsertTopMaterials) this.selectedOptions.smallInsertTopMaterial = this.productData.smallInsertTopMaterials[0];
        if (this.availableOptions.smallInsertTopMaterialForboFinishes) this.selectedOptions.smallInsertTopMaterialForboFinish = this.productData.smallInsertTopMaterialForboFinishes[0];
        if (this.availableOptions.smallInsertTopMaterialSolidSurfaceFinishes) this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish = this.productData.smallInsertTopMaterialSolidSurfaceFinishes[0];
        if (this.availableOptions.caseworkSurfaceMaterials) this.selectedOptions.caseworkSurfaceMaterial = this.productData.caseworkSurfaceMaterials[0];
        if (this.availableOptions.columnShroudPaints) this.selectedOptions.columnShroudPaint = this.productData.columnShroudPaints[0];
        if (this.availableOptions.legMaterials) this.selectedOptions.legMaterial = this.productData.legMaterials[0];
        if (this.availableOptions.woodFinishes && this.availableOptions.paintedWood) this.selectedOptions.finishType = 'woodFinish';
        if (this.availableOptions.woodFinishes) this.selectedOptions.woodFinish = this.productData.woodFinishes[0];
        if (this.availableOptions.paintedWood) this.selectedOptions.paintedWood = this.productData.paintedWood[0];
        if (this.availableOptions.metalFinishes) this.selectedOptions.metalFinish = this.productData.metalFinishes[0];
        if (this.availableOptions.stoneQuartzTableTops) this.selectedOptions.stoneQuartzTableTop = this.productData.stoneQuartzTableTops[0];
        if (this.availableOptions.swivelBaseTypes) this.selectedOptions.swivelBaseType = this.productData.swivelBaseTypes[0];
        if (this.availableOptions.leatherArmWraps) this.selectedOptions.leatherArmWrap = this.productData.leatherArmWraps[0];
        if (this.availableOptions.pulls) this.selectedOptions.pull = this.productData.pulls[0];
        if (this.availableOptions.wraps) this.selectedOptions.wrap = this.productData.wraps[0];
        if (this.availableOptions.veneerOuterCase) this.selectedOptions.veneerOuterCase = this.productData.veneerOuterCase[0];

        // Set defaults if single textile grade
        if (this.availableOptions.textileBasicOptions) {
            this.selectedOptions.singleTextileGrade = this.textileData.grades[0];
        }

        // Set defaults if multiple textile grades are available
        if (this.availableOptions.multTextilesOption) {
            this.selectedOptions.multTextilesOption = this.productData.multTextilesOption[0];
            this.selectedOptions.armTextileGrade = this.textileData.grades[0];
            this.selectedOptions.armBackTextileGrade = this.textileData.grades[0];
            this.selectedOptions.armSeatTextileGrade = this.textileData.grades[0];
            this.selectedOptions.backTextileGrade = this.textileData.grades[0];
            this.selectedOptions.seatTextileGrade = this.textileData.grades[0];
            this.selectedOptions.seatBackTextileGrade = this.textileData.grades[0];
            this.selectedOptions.sideATextileGrade = this.textileData.grades[0];
            this.selectedOptions.sideBTextileGrade = this.textileData.grades[0];
        }
    },
    moduleConditions(x) {
      // Run on two seat product(s)
      if (this.isTwoSeat === 1) {
        // Check if module 1 was updated
        if (x === 0) {
          // If module 1 is set to INL, force TAN on module 3
          this.selectedOptions.module2 = (this.selectedOptions.module0.code === 'INL') ? this.productData.modules[2][0] : this.selectedOptions.module2;
        }
        // Check if module 3 was updated
        if (x === 2) {
          // If module 3 is set to INL, force TAN on module 1
          this.selectedOptions.module0 = (this.selectedOptions.module2.code === 'INL') ? this.productData.modules[0][0] : this.selectedOptions.module0;
        }
      }
      // Run on three seat product(s)
      if (this.isThreeSeat === 1) {
        // Check if module 1 was updated
        if (x === 0) {
          // If module 1 is set to SET, force TAN on module 3
          this.selectedOptions.module2 = (this.selectedOptions.module0.code === 'SET') ? this.productData.modules[2][0] : this.selectedOptions.module2;
          // If setting module 1 to INL, for module(s) 3 and 5 to not be INL
          if (this.selectedOptions.module0.code === 'INL') {
            // Check module 3 and reset if needed
            this.selectedOptions.module2 = (this.selectedOptions.module2.code === 'INL') ? this.productData.modules[2][0] : this.selectedOptions.module2;
            // Check module 3 and reset if needed
            this.selectedOptions.module4 = (this.selectedOptions.module4.code === 'INL') ? this.productData.modules[4][0] : this.selectedOptions.module4;
          }
        }
        // Check if module 2 was updated
        if (x === 1) {
          // Ensure that module(s) 2 and 4 are the same when module 3 is set to to INL
          if (this.selectedOptions.module2.code === 'INL') {
            // Set module 4 to match module 2
            this.selectedOptions.module3 = (this.selectedOptions.module1.code === 'IA') ? this.productData.modules[3][0] : this.productData.modules[3][1];
          }
        }
        // Check if module 3 was updated
        if (x === 2) {
          // If setting module 3 to INL, for module(s) 1 and 5 to not be INL
          // Also ensure that module(s) 2 and 4 are the same when setting module 3 to INL
          if (this.selectedOptions.module2.code === 'INL') {
            // Check module 1 and reset if needed
            this.selectedOptions.module0 = (this.selectedOptions.module0.code === 'INL') ? this.productData.modules[0][0] : this.selectedOptions.module0;
            // Check module 3 and reset if needed
            this.selectedOptions.module4 = (this.selectedOptions.module4.code === 'INL') ? this.productData.modules[4][0] : this.selectedOptions.module4;
            // Set module 4 to match module 2 when setting module 3 to INL
            this.selectedOptions.module3 = (this.selectedOptions.module1.code === 'IA') ? this.productData.modules[3][0] : this.productData.modules[3][1];
          }
        }
        // Check if module 4 was updated
        if (x === 3) {
          // Ensure that module(s) 2 and 4 are the same when module 3 is set to to INL
          if (this.selectedOptions.module2.code === 'INL') {
            // Set module 2 to match module 4
            this.selectedOptions.module1 = (this.selectedOptions.module3.code === 'IA') ? this.productData.modules[1][0] : this.productData.modules[1][1];
          }
        }
        // Check if module 5 was updated
        if (x === 4) {
          // If setting module 5 to INL, for module(s) 1 and 3 to not be INL
          if (this.selectedOptions.module4.code === 'INL') {
            // Check module 1 and reset if needed
            this.selectedOptions.module0 = (this.selectedOptions.module0.code === 'INL') ? this.productData.modules[0][0] : this.selectedOptions.module0;
            // Check module 3 and reset if needed
            this.selectedOptions.module2 = (this.selectedOptions.module2.code === 'INL') ? this.productData.modules[2][0] : this.selectedOptions.module2;
          }
        }
      }
    },
    textileConditions() {
      if (this.selectedOptions.multTextilesOption && this.selectedOptions.multTextilesOption.code !== 'XX') {
        this.selectedOptions.singleTextileGrade = this.textileData.grades[0];
      }
    },
    idaShelfDependencies(x) {

        if (this.availableOptions.woodFinishes && this.availableOptions.smallInsertTopMaterials && this.productData.title === 'NC01-T1') {

            for (var i = 0; i < this.productData.woodFinishes.length; i++) {

                if (((this.productData.woodFinishes[i].code !== 'WCW' && this.productData.woodFinishes[i].code !== 'WCB' && (this.selectedOptions.woodFinish.code === 'WCW' || this.selectedOptions.woodFinish.code === 'WCB')) && (this.selectedOptions.smallInsertTopMaterial.code === 'VAS' || this.selectedOptions.smallInsertTopMaterial.code === 'SAS')) ||
                (this.productData.woodFinishes[i].code === 'WCW' && (this.selectedOptions.smallInsertTopMaterial.code === 'VWS' || this.selectedOptions.smallInsertTopMaterial.code === 'SWS'))) {

                    // Set wood finish to this value
                    this.selectedOptions.woodFinish = this.productData.woodFinishes[i]
                    break;
                }
            }
        }
    },
    insertDependencies(x) {
      // Set the triggering select menu
      var _trigger = x.target.id;

      // If triggered by large insert
      if (_trigger === 'largeInsertTopMaterial') {
        // If large insert is set to ash
        // and small insert is set to walnut,
        // force small insert to ash
        if (this.selectedOptions.largeInsertTopMaterial.code === 'VAL' && this.selectedOptions.smallInsertTopMaterial.code === 'VWS') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.smallInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.smallInsertTopMaterials[i].code === 'VAS') {
              // Update selected
              _selected = this.productData.smallInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.selectedOptions.smallInsertTopMaterial = _selected;
          }
        }

        // If large insert is set to walnut
        // and small insert is set to ash,
        // force small insert to walnut
        if (this.selectedOptions.largeInsertTopMaterial.code === 'VWL' && this.selectedOptions.smallInsertTopMaterial.code === 'VAS') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.smallInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.smallInsertTopMaterials[i].code === 'VWS') {
              // Update selected
              _selected = this.productData.smallInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.selectedOptions.smallInsertTopMaterial = _selected;
          }
        }
      }

      // If triggered by small insert
      if (_trigger === 'smallInsertTopMaterial') {
        // If small insert is set to ash
        // and large insert is set to walnut,
        // force large insert to ash
        if (this.selectedOptions.smallInsertTopMaterial.code === 'VAS' && this.selectedOptions.largeInsertTopMaterial.code === 'VWL') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.largeInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.largeInsertTopMaterials[i].code === 'VAL') {
              // Update selected
              _selected = this.productData.largeInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.selectedOptions.largeInsertTopMaterial = _selected;
          }
        }

        // If small insert is set to walnut
        // and large insert is set to ash,
        // force large insert to walnut
        if (this.selectedOptions.smallInsertTopMaterial.code === 'VWS' && this.selectedOptions.largeInsertTopMaterial.code === 'VAL') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.largeInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.largeInsertTopMaterials[i].code === 'VWL') {
              // Update selected
              _selected = this.productData.largeInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.selectedOptions.largeInsertTopMaterial = _selected;
          }
        }
      }

      // Run Chord table dependencies
      this.chordTableDependencies();
    },
    padsDependencies() {

        // Run if Maru product
        if (this.isMaru()) {

            // Multiple textiles
            if (this.productData.pads.length && this.productData.multTextilesOption.length) {

                // Reset textile selections
                this.selectedOptions.multTextilesOption = this.productData.multTextilesOption[0];
                this.selectedOptions.armTextileGrade = this.textileData.grades[0];
                this.selectedOptions.armBackTextileGrade = this.textileData.grades[0];
                this.selectedOptions.armSeatTextileGrade = this.textileData.grades[0];
                this.selectedOptions.backTextileGrade = this.textileData.grades[0];
                this.selectedOptions.seatTextileGrade = this.textileData.grades[0];
                this.selectedOptions.seatBackTextileGrade = this.textileData.grades[0];
                this.selectedOptions.sideATextileGrade = this.textileData.grades[0];
                this.selectedOptions.sideBTextileGrade = this.textileData.grades[0];

                // If pads are selected, enable multiple textile
                if (this.selectedOptions.pads.code !== 'XX') {
                    this.availableOptions.multTextilesOption = true;
                } else {
                    this.availableOptions.multTextilesOption = false;
                }
            }

            // Single textile
            if (this.productData.pads.length && !this.productData.multTextilesOption.length) {

                // Reset textile selection
                this.selectedOptions.singleTextileGrade = this.textileData.grades[0];

                // If pads are selected, enable basic textiles
                if (this.selectedOptions.pads.code !== 'XX') {
                    this.availableOptions.textileBasicOptions = true;
                } else {
                    this.availableOptions.textileBasicOptions = false;
                }
            }
        }

        // Run if April product
        if (this.isApril()) {

            // Single textile
            if (this.productData.pads.length && !this.productData.multTextilesOption.length) {

                // Reset textile selection
                this.selectedOptions.singleTextileGrade = this.textileData.grades[0];

                // If pads are selected, enable basic textiles
                if (this.selectedOptions.pads.code !== 'XX') {
                    this.availableOptions.textileBasicOptions = true;
                } else {
                    this.availableOptions.textileBasicOptions = false;
                }
            }
        }
    },
    textileDependencies(x) {
      // Safe proof and only run if multiple textiles are available
      // and set not set to run as a single textile
      if (this.availableOptions.multTextilesOption && this.selectedOptions.multTextilesOption.code !== 'XX') {
        // Set the triggering select menu
        var _trigger = x.target.id;

        // If triggered by Arm
        if (_trigger === 'armTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.armTextileGrade;

          // If Arm, Seat/Back
          if (this.selectedOptions.multTextilesOption.code === 'AT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.seatBackTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.seatBackTextileGrade.code === 'GRCOM') {
                this.selectedOptions.seatBackTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Arm/Back
        if (_trigger === 'armBackTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.armBackTextileGrade;

          // If Seat, Arm/Back
          if (this.selectedOptions.multTextilesOption.code === 'ST') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.seatTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.seatTextileGrade.code === 'GRCOM') {
                this.selectedOptions.seatTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Arm/Seat
        if (_trigger === 'armSeatTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.armSeatTextileGrade;

          // If Back, Arm/Seat
          if (this.selectedOptions.multTextilesOption.code === 'BT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.backTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.backTextileGrade.code === 'GRCOM') {
                this.selectedOptions.backTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Back
        if (_trigger === 'backTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.backTextileGrade;

          // If Seat, Back
          if (this.selectedOptions.multTextilesOption.code === 'TA') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.seatTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.seatTextileGrade.code === 'GRCOM') {
                this.selectedOptions.seatTextileGrade = _selected;
              }
            }
          }

          // If Back, Arm/Seat
          if (this.selectedOptions.multTextilesOption.code === 'BT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.armSeatTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.armSeatTextileGrade.code === 'GRCOM') {
                this.selectedOptions.armSeatTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Seat
        if (_trigger === 'seatTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.seatTextileGrade;

          // If Seat, Back
          if (this.selectedOptions.multTextilesOption.code === 'TA') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.backTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.backTextileGrade.code === 'GRCOM') {
                this.selectedOptions.backTextileGrade = _selected;
              }
            }
          }

          // If Seat, Arm/Back
          if (this.selectedOptions.multTextilesOption.code === 'ST') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.armBackTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.armBackTextileGrade.code === 'GRCOM') {
                this.selectedOptions.armBackTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Arm
        if (_trigger === 'seatBackTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.seatBackTextileGrade;

          // If Arm, Seat/Back
          if (this.selectedOptions.multTextilesOption.code === 'AT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.armTextileGrade = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.selectedOptions.armTextileGrade.code === 'GRCOM') {
                this.selectedOptions.armTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Side A
        if (_trigger === 'sideATextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.sideATextileGrade;

          // If Side A/Side B
          if (this.selectedOptions.multTextilesOption.code === 'AB') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.sideBTextileGrade = _selected;
            } else {
              // Only change the side b grade if it's still set to GRCOM
              if (this.selectedOptions.sideBTextileGrade.code === 'GRCOM') {
                this.selectedOptions.sideBTextileGrade = _selected;
              }
            }
          }
        }

        // If triggered by Side B
        if (_trigger === 'sideBTextileGrade') {
          // Set selected code
          var _selected = this.selectedOptions.sideBTextileGrade;

          // If Side A/Side B
          if (this.selectedOptions.multTextilesOption.code === 'AB') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.selectedOptions.sideATextileGrade = _selected;
            } else {
              // Only change the side a grade if it's still set to GRCOM
              if (this.selectedOptions.sideATextileGrade.code === 'GRCOM') {
                this.selectedOptions.sideATextileGrade = _selected;
              }
            }
          }
        }
      }
    },
    chordTableDependencies() {
      // Ensure that we're checking against
      // Chord tables, and if not, ignore
      if (this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') {
        // Loop through available wood finishes
        // and set finish as first value matching
        // the conditional set by either insert
        for (var i = 0; i < this.productData.woodFinishes.length; i++) {
          // Match if our species is set to Ash AND
          // wood finish doesn't have a code of WCW
          // OR our species is set to Walnut and we
          // have a code of WCW
          if ((this.productData.woodFinishes[i].code !== 'WCW' && (this.selectedOptions.woodFinish.code === 'WCW' || this.selectedOptions.woodFinish.code === '' || this.selectedOptions.woodFinish.code === null) && (this.selectedOptions.largeInsertTopMaterial.code === 'VAL' || this.selectedOptions.smallInsertTopMaterial.code === 'VAS')) || (this.productData.woodFinishes[i].code === 'WCW' && (this.selectedOptions.woodFinish.code !== 'WCW' || this.selectedOptions.woodFinish.code === '' || this.selectedOptions.woodFinish.code === null) && (this.selectedOptions.largeInsertTopMaterial.code === 'VWL' || this.selectedOptions.smallInsertTopMaterial.code === 'VWS'))) {
            // Set wood finish to this value
            this.selectedOptions.woodFinish = this.productData.woodFinishes[i]
            break;
          }
        }
      }
    },
    speciesDependencies() {
        // Ensure we have multiple species
        // before running conditionals
        if (this.availableOptions.species) {

            // If wood finishes exist for frames ensure we default
            // to finish available for selected species
            if (this.availableOptions.woodFinishes && !this.availableOptions.legMaterials) {

                // Loop through available wood finishes
                // and set finish as first value matching
                // the conditional set by species type
                for (var i = 0; i < this.productData.woodFinishes.length; i++) {

                    // Match if our species is set to Ash AND
                    // wood finish doesn't have a code of WCW
                    // OR our species is set to Walnut and we
                    // have a code of WCW
                    if (((this.productData.woodFinishes[i].code !== 'WCW' && this.productData.woodFinishes[i].code !== 'WCB') && this.selectedOptions.species.code === 'A') || (this.productData.woodFinishes[i].code === 'WCW' && this.selectedOptions.species.code === 'W') || (this.productData.woodFinishes[i].code === 'WCB' && this.selectedOptions.species.code === 'B')) {

                        // Set wood finish to this value
                        this.selectedOptions.woodFinish = this.productData.woodFinishes[i]
                        break;
                    }
                }
            }

            // If table top materials exist for frames ensure we
            // default to finish available for selected species
            if (this.availableOptions.tableTopMaterials) {

                // Loop through available table top materials
                // and set finish as first value matching
                // the conditional set by species type, but
                // only do all of this if one of our use-cases
                // has been found and is a match
                for (var i = 0; i < this.productData.tableTopMaterials.length; i++) {

                    // Match if our species is set to Ash AND
                    // wood finish doesn't have a code of WCW
                    // OR our species is set to Walnut and we
                    // have a code of WCW
                    if (((this.productData.tableTopMaterials[i].code !== 'WTW' && this.productData.tableTopMaterials[i].code !== 'WTB' && this.productData.tableTopMaterials[i].code !== 'VTW' && this.productData.tableTopMaterials[i].code !== 'VTB') && this.selectedOptions.species.code === 'A' && (this.selectedOptions.tableTopMaterial.code === 'WTW' || this.selectedOptions.tableTopMaterial.code === 'WTB' || this.selectedOptions.tableTopMaterial.code === 'VTW' || this.selectedOptions.tableTopMaterial.code === 'VTB')) ||


                    ((this.productData.tableTopMaterials[i].code !== 'WTA' && this.productData.tableTopMaterials[i].code !== 'WTB' && this.productData.tableTopMaterials[i].code !== 'VTA' && this.productData.tableTopMaterials[i].code !== 'VTB') && this.selectedOptions.species.code === 'W' && (this.selectedOptions.tableTopMaterial.code === 'WTA' || this.selectedOptions.tableTopMaterial.code === 'WTB' || this.selectedOptions.tableTopMaterial.code === 'VTA' || this.selectedOptions.tableTopMaterial.code === 'VTB')) ||


                    ((this.productData.tableTopMaterials[i].code !== 'WTA' && this.productData.tableTopMaterials[i].code !== 'WTW' && this.productData.tableTopMaterials[i].code !== 'VTA' && this.productData.tableTopMaterials[i].code !== 'VTW' && this.productData.tableTopMaterials[i].code !== 'CT1' && this.productData.tableTopMaterials[i].code !== 'CT2' && this.productData.tableTopMaterials[i].code !== 'CT3' && this.productData.tableTopMaterials[i].code !== 'CT4' && this.productData.tableTopMaterials[i].code !== 'CT5' && this.productData.tableTopMaterials[i].code !== 'CT6' && this.productData.tableTopMaterials[i].code !== 'CT7') && this.selectedOptions.species.code === 'B' && (this.selectedOptions.tableTopMaterial.code === 'WTA' || this.selectedOptions.tableTopMaterial.code === 'WTW' || this.selectedOptions.tableTopMaterial.code === 'VTA' || this.selectedOptions.tableTopMaterial.code === 'VTB' || this.selectedOptions.tableTopMaterial.code === 'CT1' || this.selectedOptions.tableTopMaterial.code === 'CT2' || this.selectedOptions.tableTopMaterial.code === 'CT3' || this.selectedOptions.tableTopMaterial.code === 'CT4' || this.selectedOptions.tableTopMaterial.code === 'CT5' || this.selectedOptions.tableTopMaterial.code === 'CT6' || this.selectedOptions.tableTopMaterial.code === 'CT7'))) {

                        // Set wood finish to this value
                        this.selectedOptions.tableTopMaterial = this.productData.tableTopMaterials[i]
                        break;
                    }
                }
            }

            // If partitions exist, default them to the correct type based on
            // species changes being made via the UI
            if (this.availableOptions.partitions) {

                // Loop through each partition and set where a match exists
                for (var i = 0; i < this.productData.partitions.length; i++) {

                    // If we have a match of sorts, set this to the default
                    if (this.selectedOptions.partitions.code !== 'XX' && ((this.productData.partitions[i].code === 'SCR1B' && this.selectedOptions.species.code === 'B') || (this.productData.partitions[i].code === 'SCR1A' && this.selectedOptions.species.code === 'A') || (this.productData.partitions[i].code === 'SCR1W' && this.selectedOptions.species.code === 'W') || (this.productData.partitions[i].code === 'SCR1OM' && this.selectedOptions.species.code === 'OM'))) {

                        // Set partition to this value
                        this.selectedOptions.partitions = this.productData.partitions[i]
                        break;
                    }
                }
            }
        }

        // Dependencies for Chroma Low & High Tables
        if (this.productData.title === 'HK01-T1' || this.productData.title === 'HK01-T2') {

            // Loop through available wood finishes
            // and set finish as first value matching
            // the conditional set by species type
            for (var i = 0; i < this.productData.woodFinishes.length; i++) {
                // Match if our species is set to Ash AND
                // wood finish doesn't have a code of WCW
                // OR our species is set to Walnut and we
                // have a code of WCW
                if ((this.productData.woodFinishes[i].code !== 'WCW' && this.selectedOptions.tableTopMaterial.code === 'VTA') || (this.productData.woodFinishes[i].code === 'WCW' && this.selectedOptions.tableTopMaterial.code === 'VTW')) {

                    // Set wood finish to this value
                    this.selectedOptions.woodFinish = this.productData.woodFinishes[i]
                    break;
                }
            }
        }
        
        // If B-Side product
        if (this.isBSide()) {
          
          // Loop through available finishes for side a and
          // set to the first matching finish for top type
          for (var i = 0; i < this.productData.tableTopMaterialFinishesSideA.length; i++) {
            
            if (
              (this.selectedOptions.tableTopMaterial.code === 'VTA' && this.isAshFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'VTW' && this.isWalnutFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'FTT' && this.isForboFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'SST' && this.isSolidSurfaceFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'CTT' && this.isCorkFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'LTT' && this.isLaminateFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'RTT' && this.isReallyFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
            ) {
              this.selectedOptions.tableTopMaterialFinishSideA = this.productData.tableTopMaterialFinishesSideA[i]
              break;
            }
          }
          
          // Loop through available finishes for side b and
          // set to the first matching finish for top type
          for (var i = 0; i < this.productData.tableTopMaterialFinishesSideB.length; i++) {
            
            if (
              (this.selectedOptions.tableTopMaterial.code === 'VTA' && this.isAshFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'VTW' && this.isWalnutFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'FTT' && this.isForboFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'SST' && this.isSolidSurfaceFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'CTT' && this.isCorkFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'LTT' && this.isLaminateFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.selectedOptions.tableTopMaterial.code === 'RTT' && this.isReallyFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
            ) {
              this.selectedOptions.tableTopMaterialFinishSideB = this.productData.tableTopMaterialFinishesSideB[i]
              break;
            }
          }
        }
    },
    tablesVsPower() {

        // If Maru 3 slat, 3/4 IND config 3
        if (this.productData.name === 'Maru 3-slat, 3/4-length Indoor Configuration 3') {

            // Only run if a table is selected
            if (this.selectedOptions.tables.code !== 'XX') {

                // Only run if power module selected isn't XX or PM1
                if (this.selectedOptions.powerModules.code !== 'XX' && this.selectedOptions.powerModules.code !== 'PM1') {

                    // Set to PM1
                    this.selectedOptions.powerModules = this.productData.powerModules[1];
                }
            }
        }
    },
    isMaru() {
        // Create array of Maru product base code(s)
        const maruProducts = [
                                'SG03-IND-S7-86W',
                                'SG03-IND-S7-86W-BC4-BG4',
                                'SG03-IND-S7-86W-BE4',
                                'SG03-IND-S7-86W-BC4R-BG4R',
                                'SG03-IND-S5-86W',
                                'SG03-IND-S5-86W-BC2-BG2',
                                'SG03-IND-S5-86W-BC2-BG4',
                                'SG03-IND-S5-86W-BC4-BG2',
                                'SG03-IND-S5-86W-BC2',
                                'SG03-IND-S5-86W-BG2',
                                'SG03-IND-S3-86W',
                                'SG03-IND-S3-66W',
                                'SG03-OUT-S7-86W',
                                'SG03-OUT-S7-86W-BC4-BG4',
                                'SG03-OUT-S7-86W-BE4',
                                'SG03-OUT-S7-86W-BC4R-BG4R',
                                'SG03-OUT-S5-86W',
                                'SG03-OUT-S5-86W-BC2-BG2',
                                'SG03-OUT-S5-86W-BC2-BG4',
                                'SG03-OUT-S5-86W-BC4-BG2',
                                'SG03-OUT-S5-86W-BC2',
                                'SG03-OUT-S5-86W-BG2',
                                'SG03-OUT-S3-86W',
                                'SG03-OUT-S3-66W',
                                'SG03-IND-BOL-40W',
                                'SG03-OUT-BOL-40W',
                                'SG03-IND-PAD-40W',
                                'SG03-OUT-PAD-40W',
                                'SG03-IND-PAD-60W',
                                'SG03-OUT-PAD-60W',
                                'SG03-IND-T1',
                                'SG03-IND-T2',
                                'SG03-IND-SCR1',
                                'SG03-OUT-SCR1',
                                'SG03-IND-PM',
                                'SG03-IND-PS'
                            ];

        // If the current product is found in the Maru Products array, return a
        // true result, on call of isMaru()
        if (maruProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isApril() {
        // Create array of April product base code(s)
        const aprilProducts = [
                                'JY02-10',
                                'JY02-16',
                                'JY02-17'
                            ];

        // If the current product is found in the April Products array, return a
        // true result, on call of isApril()
        if (aprilProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isBSide() {
        // Create array of B-Side product base code(s)
        const bSideProducts = [
                                'MM03-T1',
                                'MM03-T2',
                                'MM03-T3'
                            ];

        // If the current product is found in the B-Side Products array, return a
        // true result, on call of isBSide()
        if (bSideProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isAshFinish(finish) {
        // Create array of veneer ash finishes
        const ashFinishes = [
                                'WCA',
                                'WGA',
                                'WOA',
                                'WNB',
                                'WNP',
                                'WBA'
                            ];

        // If the current finish is found in the ash finish array
        if (ashFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isWalnutFinish(finish) {
        // Create array of walnut finishes
        const walnutFinishes = [
                                'WCW'
                            ];

        // If the current finish is found in the walnut finish array
        if (walnutFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isForboFinish(finish) {
        // Create array of forbo finishes
        const forboFinishes = [
                                'FAS',
                                'FBU',
                                'FPW',
                                'FSA',
                                'FCH',
                                'FPE',
                                'FMU',
                                'FVA',
                                'FIR',
                                'FSB',
                                'FAQ',
                                'FMB',
                                'FOL',
                                'FPO'
                            ];

        // If the current finish is found in the forbo finish array
        if (forboFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isSolidSurfaceFinish(finish) {
        // Create array of solid surface finishes
        const solidSurfaceFinishes = [
                                'YUS',
                                'KIS',
                                'HIS',
                                'BLS',
                                'CWS'
                            ];

        // If the current finish is found in the solid surface finish array
        if (solidSurfaceFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isCorkFinish(finish) {
        // Create array of cork finishes
        const corkFinishes = [
                                'CNC',
                                'CDK'
                            ];

        // If the current finish is found in the cork finish array
        if (corkFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isLaminateFinish(finish) {
        // Create array of laminate finishes
        const laminateFinishes = [
                                'LTF',
                                'LTP',
                                'LTW',
                                'LTA'
                            ];

        // If the current finish is found in the laminate finish array
        if (laminateFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isReallyFinish(finish) {
        // Create array of really finishes
        const reallyFinishes = [
                                'RCW',
                                'RBD'
                            ];

        // If the current finish is found in the really finish array
        if (reallyFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    saveProduct() {
      // Set loading
      this.isLoading = 1;
      
      // Build product array for saving
      const productConfiguration = {
          'productID': this.productId,
          'productName': this.productData.slug != 'custom-product' ? this.productData.name : this.customProductName,
          'productSlug': this.productData.slug,
          'productDescription': this.productDescription,
          'optionsFinishesTextiles': this.optionsFinishesTextiles,
          'quantity': this.quantity,
          'baseCode': this.productData.slug != 'custom-product' ? this.productData.title : this.customProductCode,
          'baseCom': currency(this.productData.baseCom),
          'productCode': this.productData.slug != 'custom-product' ? this.fullCode : this.customProductCode,
          'productDiscount': this.productDiscount,
          'oversizeGoods': this.oversizeGoods,
          'unitListPrice': this.unitListPrice + this.unitTextilePrice,
          'unitTextilePrice': this.unitTextilePrice,
          'unitNetPrice': this.unitNetPrice,
          'unitTaxPrice': this.unitTaxPrice,
          'extendedListPrice': this.extendedListPrice + this.extendedTextilePrice,
          'extendedTextilePrice': this.extendedTextilePrice,
          'extendedNetPrice': this.extendedNetPrice,
          'extendedTaxPrice': this.extendedTaxPrice,
          'yardageTotal': this.productData.yardage.total ? parseFloat(this.productData.yardage.total) : 0,
          'yardageSeat': this.productData.yardage.seat ? parseFloat(this.productData.yardage.seat) : 0,
          'yardageBack': this.productData.yardage.back ? parseFloat(this.productData.yardage.back) : 0,
          'yardageArm': this.productData.yardage.arm ? parseFloat(this.productData.yardage.arm) : 0,
          'yardagePad': this.productData.yardage.pad ? parseFloat(this.productData.yardage.pad) : 0,
          'yardageTop': this.productData.yardage.top ? parseFloat(this.productData.yardage.top) : 0,
          'yardageTAN': this.productData.yardage.tan ? parseFloat(this.productData.yardage.tan) : 0,
          'yardageSET': this.productData.yardage.set ? parseFloat(this.productData.yardage.set) : 0,
          'yardageSideA': this.productData.yardage.sideA ? parseFloat(this.productData.yardage.sideA) : 0,
          'yardageSideB': this.productData.yardage.sideB ? parseFloat(this.productData.yardage.sideB) : 0,
          'moduleOne': this.selectedOptions.module0.id ? [{
            id: parseInt(this.selectedOptions.module0.id),
            code: this.selectedOptions.module0.code,
            name: this.selectedOptions.module0.name
          }] : [],
          'moduleTwo': this.selectedOptions.module1.id ? [{
            id: parseInt(this.selectedOptions.module1.id),
            code: this.selectedOptions.module1.code,
            name: this.selectedOptions.module1.name
          }] : [],
          'moduleThree': this.selectedOptions.module2.id ? [{
            id: parseInt(this.selectedOptions.module2.id),
            code: this.selectedOptions.module2.code,
            name: this.selectedOptions.module2.name
          }] : [],
          'moduleFour': this.selectedOptions.module3.id ? [{
            id: parseInt(this.selectedOptions.module3.id),
            code: this.selectedOptions.module3.code,
            name: this.selectedOptions.module3.name
          }] : [],
          'moduleFive': this.selectedOptions.module4.id ? [{
            id: parseInt(this.selectedOptions.module4.id),
            code: this.selectedOptions.module4.code,
            name: this.selectedOptions.module4.name
          }] : [],
          'productTag': this.selectedOptions.productTag,
          'species': this.selectedOptions.species.id ? [{
            id: parseInt(this.selectedOptions.species.id),
            code: this.selectedOptions.species.code,
            name: this.selectedOptions.species.name
          }] : [],
          'armHeight': this.selectedOptions.armHeight.id ? [{
            id: parseInt(this.selectedOptions.armHeight.id),
            code: this.selectedOptions.armHeight.code,
            name: this.selectedOptions.armHeight.name
          }] : [],
          'tableTopSize': this.selectedOptions.tableTopSize.id ? [{
            id: parseInt(this.selectedOptions.tableTopSize.id),
            code: this.selectedOptions.tableTopSize.code,
            name: this.selectedOptions.tableTopSize.name
          }] : [],
          'armCapMaterial': this.selectedOptions.armCapMaterial.id ? [{
            id: parseInt(this.selectedOptions.armCapMaterial.id),
            code: this.selectedOptions.armCapMaterial.code,
            name: this.selectedOptions.armCapMaterial.name
          }] : [],
          'armCapMaterialName': (this.selectedOptions.armCapMaterial.code !== 'WAC') ? this.selectedOptions.armCapMaterialName : '',
          'tableTopMaterial': this.selectedOptions.tableTopMaterial.code && ((((this.isTwoSeat === 1 || this.isThreeSeat === 1) && (this.selectedOptions.module0.code === 'INL' || this.selectedOptions.module2.code === 'INL' || this.selectedOptions.module4.code === 'INL')) || (this.availableOptions.modules === false) && !this.isMaru()) || (this.isMaru() && ((this.availableOptions.tableTopMaterials && !this.availableOptions.tables) || (this.availableOptions.tableTopMaterials && this.availableOptions.tables && this.selectedOptions.tables.code != 'XX')))) ? [{
            id: parseInt(this.selectedOptions.tableTopMaterial.id),
            code: this.selectedOptions.tableTopMaterial.code,
            name: this.selectedOptions.tableTopMaterial.name
          }] : [],
          'pads': this.selectedOptions.pads.id ? [{
            id: parseInt(this.selectedOptions.pads.id),
            code: this.selectedOptions.pads.code,
            name: this.selectedOptions.pads.name
          }] : [],
          'tables': this.selectedOptions.tables.id ? [{
            id: parseInt(this.selectedOptions.tables.id),
            code: this.selectedOptions.tables.code,
            name: this.selectedOptions.tables.name
          }] : [],
          'partitions': this.selectedOptions.partitions.id ? [{
            id: parseInt(this.selectedOptions.partitions.id),
            code: this.selectedOptions.partitions.code,
            name: this.selectedOptions.partitions.name
          }] : [],
          'powerModules': this.selectedOptions.powerModules.id ? [{
            id: parseInt(this.selectedOptions.powerModules.id),
            code: this.selectedOptions.powerModules.code,
            name: this.selectedOptions.powerModules.name
          }] : [],
          'tableTopMaterialName': (this.selectedOptions.tableTopMaterial.code !== 'WTT' && this.selectedOptions.tableTopMaterial.code !== 'WTA' && this.selectedOptions.tableTopMaterial.code !== 'WTW' && this.selectedOptions.tableTopMaterial.code !== 'VTA' && this.selectedOptions.tableTopMaterial.code !== 'VTW' && this.selectedOptions.tableTopMaterial.code !== 'VTB' && this.selectedOptions.tableTopMaterial.code !== 'UPH' && this.selectedOptions.tableTopMaterial.code !== 'WTB') && this.selectedOptions.tableTopMaterialName !== '' ? this.selectedOptions.tableTopMaterialName : '',
          'tableTopMaterialFinishSideA': this.selectedOptions.tableTopMaterialFinishSideA.id ? [{
            id: parseInt(this.selectedOptions.tableTopMaterialFinishSideA.id),
            code: this.selectedOptions.tableTopMaterialFinishSideA.code,
            name: this.selectedOptions.tableTopMaterialFinishSideA.name
          }] : [],
          'tableTopMaterialFinishSideB': this.selectedOptions.tableTopMaterialFinishSideB.id && (this.selectedOptions.tableTopMaterial.code === 'FTT' || this.selectedOptions.tableTopMaterial.code === 'LTT') ? [{
            id: parseInt(this.selectedOptions.tableTopMaterialFinishSideB.id),
            code: this.selectedOptions.tableTopMaterialFinishSideB.code,
            name: this.selectedOptions.tableTopMaterialFinishSideB.name
          }] : [],
          'tableTopMaterialForboFinish': this.selectedOptions.tableTopMaterial.code === 'FTT' && this.selectedOptions.tableTopMaterialForboFinish.id ? [{
            id: parseInt(this.selectedOptions.tableTopMaterialForboFinish.id),
            code: this.selectedOptions.tableTopMaterialForboFinish.code,
            name: this.selectedOptions.tableTopMaterialForboFinish.name
          }] : [],
          'tableTopMaterialMetalFinish': this.selectedOptions.tableTopMaterial.code === 'MTT' && this.selectedOptions.tableTopMaterialMetalFinish.id ? [{
            id: parseInt(this.selectedOptions.tableTopMaterialMetalFinish.id),
            code: this.selectedOptions.tableTopMaterialMetalFinish.code,
            name: this.selectedOptions.tableTopMaterialMetalFinish.name
          }] : [],
          'largeInsertTopMaterial': this.selectedOptions.largeInsertTopMaterial.id ? [{
            id: parseInt(this.selectedOptions.largeInsertTopMaterial.id),
            code: this.selectedOptions.largeInsertTopMaterial.code,
            name: this.selectedOptions.largeInsertTopMaterial.name
          }] : [],
          'largeInsertTopMaterialName': this.selectedOptions.largeInsertTopMaterial.code === 'FTL' && this.selectedOptions.largeInsertTopMaterialName !== '' ? this.selectedOptions.largeInsertTopMaterialName : '',
          'largeInsertTopMaterialForboFinish': this.selectedOptions.largeInsertTopMaterial.code === 'FTL' && this.selectedOptions.largeInsertTopMaterialForboFinish.id ? [{
            id: parseInt(this.selectedOptions.largeInsertTopMaterialForboFinish.id),
            code: this.selectedOptions.largeInsertTopMaterialForboFinish.code,
            name: this.selectedOptions.largeInsertTopMaterialForboFinish.name
          }] : [],
          'largeInsertTopMaterialSolidSurfaceFinish': this.selectedOptions.largeInsertTopMaterial.code === 'SSL' && this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.id ? [{
            id: parseInt(this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.id),
            code: this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code,
            name: this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.name
          }] : [],
          'smallInsertTopMaterial': this.selectedOptions.smallInsertTopMaterial.id ? [{
            id: parseInt(this.selectedOptions.smallInsertTopMaterial.id),
            code: this.selectedOptions.smallInsertTopMaterial.code,
            name: this.selectedOptions.smallInsertTopMaterial.name
          }] : [],
          'smallInsertTopMaterialName': this.selectedOptions.smallInsertTopMaterial.code === 'FTS' && this.selectedOptions.smallInsertTopMaterialName !== '' ? this.selectedOptions.smallInsertTopMaterialName : '',
          'smallInsertTopMaterialForboFinish': this.selectedOptions.smallInsertTopMaterial.code === 'FTS' && this.selectedOptions.smallInsertTopMaterialForboFinish.id ? [{
            id: parseInt(this.selectedOptions.smallInsertTopMaterialForboFinish.id),
            code: this.selectedOptions.smallInsertTopMaterialForboFinish.code,
            name: this.selectedOptions.smallInsertTopMaterialForboFinish.name
          }] : [],
          'smallInsertTopMaterialSolidSurfaceFinish': this.selectedOptions.smallInsertTopMaterial.code === 'SSS' && this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.id ? [{
            id: parseInt(this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.id),
            code: this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code,
            name: this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.name
          }] : [],
          'caseworkSurfaceMaterial': this.selectedOptions.caseworkSurfaceMaterial.id ? [{
            id: parseInt(this.selectedOptions.caseworkSurfaceMaterial.id),
            code: this.selectedOptions.caseworkSurfaceMaterial.code,
            name: this.selectedOptions.caseworkSurfaceMaterial.name
          }] : [],
          'caseworkSurfaceMaterialName': this.selectedOptions.caseworkSurfaceMaterialName,
          'columnShroudPaint': this.selectedOptions.columnShroudPaint.id ? [{
            id: parseInt(this.selectedOptions.columnShroudPaint.id),
            code: this.selectedOptions.columnShroudPaint.code,
            name: this.selectedOptions.columnShroudPaint.name
          }] : [],
          'legMaterial': this.selectedOptions.legMaterial.id ? [{
            id: parseInt(this.selectedOptions.legMaterial.id),
            code: this.selectedOptions.legMaterial.code,
            name: this.selectedOptions.legMaterial.name
          }] : [],
          'metalFinish': this.selectedOptions.metalFinish.id ? [{
            id: parseInt(this.selectedOptions.metalFinish.id),
            code: this.selectedOptions.metalFinish.code,
            name: this.selectedOptions.metalFinish.name
          }] : [],
          'finishType': this.selectedOptions.finishType,
          'woodFinish': this.selectedOptions.woodFinish.id ? [{
            id: parseInt(this.selectedOptions.woodFinish.id),
            code: this.selectedOptions.woodFinish.code,
            name: this.selectedOptions.woodFinish.name
          }] : [],
          'paintedWood': this.selectedOptions.paintedWood.id ? [{
            id: parseInt(this.selectedOptions.paintedWood.id),
            code: this.selectedOptions.paintedWood.code,
            name: this.selectedOptions.paintedWood.name
          }] : [],
          'stoneQuartzTableTop': this.selectedOptions.stoneQuartzTableTop.id ? [{
            id: parseInt(this.selectedOptions.stoneQuartzTableTop.id),
            code: this.selectedOptions.stoneQuartzTableTop.code,
            name: this.selectedOptions.stoneQuartzTableTop.name
          }] : [],
          'swivelBaseType': this.selectedOptions.swivelBaseType.id ? [{
            id: parseInt(this.selectedOptions.swivelBaseType.id),
            code: this.selectedOptions.swivelBaseType.code,
            name: this.selectedOptions.swivelBaseType.name
          }] : [],
          'leatherArmWrap': this.selectedOptions.leatherArmWrap.id ? [{
            id: parseInt(this.selectedOptions.leatherArmWrap.id),
            code: this.selectedOptions.leatherArmWrap.code,
            name: this.selectedOptions.leatherArmWrap.name
          }] : [],
          'moistureBarrier': this.selectedOptions.moistureBarrier,
          'removeableCovers': this.selectedOptions.removeableCovers,
          'calTb133': this.selectedOptions.calTb133,
          'casters': this.selectedOptions.casters,
          'removeableArmPads': this.selectedOptions.removeableArmPads,
          'hangingFixture': this.selectedOptions.hangingFixture,
          'hingesOne': this.selectedOptions.hinge0.id ? [{
            id: parseInt(this.selectedOptions.hinge0.id),
            code: this.selectedOptions.hinge0.code,
            name: this.selectedOptions.hinge0.name
          }] : [],
          'hingesTwo': this.selectedOptions.hinge1.id ? [{
            id: parseInt(this.selectedOptions.hinge1.id),
            code: this.selectedOptions.hinge1.code,
            name: this.selectedOptions.hinge1.name
          }] : [],
          'hingesThree': this.selectedOptions.hinge2.id ? [{
            id: parseInt(this.selectedOptions.hinge2.id),
            code: this.selectedOptions.hinge2.code,
            name: this.selectedOptions.hinge2.name
          }] : [],
          'drawerLiner': this.selectedOptions.drawerLiner,
          'paperTowelDispenser': this.selectedOptions.paperTowelDispenser,
          'extraAdjustableShelf': this.selectedOptions.extraAdjustableShelf,
          'fileHolders': this.selectedOptions.fileHolders,
          'gloveCutOuts': this.selectedOptions.gloveCutOuts,
          'pull': this.selectedOptions.pull.id ? [{
            id: parseInt(this.selectedOptions.pull.id),
            code: this.selectedOptions.pull.code,
            name: this.selectedOptions.pull.name
          }] : [],
          'wrap': this.selectedOptions.wrap.id ? [{
            id: parseInt(this.selectedOptions.wrap.id),
            code: this.selectedOptions.wrap.code,
            name: this.selectedOptions.wrap.name
          }] : [],
          'veneerOuterCase': this.selectedOptions.veneerOuterCase.id ? [{
            id: parseInt(this.selectedOptions.veneerOuterCase.id),
            code: this.selectedOptions.veneerOuterCase.code,
            name: this.selectedOptions.veneerOuterCase.name
          }] : [],
          'singleTextileGrade': this.selectedOptions.singleTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.singleTextileGrade.id),
            code: this.selectedOptions.singleTextileGrade.code,
            name: this.selectedOptions.singleTextileGrade.name
          }] : [],
          'singleTextileGradeName': this.selectedOptions.singleTextileGradeName,
          'multTextilesOption': this.selectedOptions.multTextilesOption.id ? [{
            id: parseInt(this.selectedOptions.multTextilesOption.id),
            code: this.selectedOptions.multTextilesOption.code,
            name: this.selectedOptions.multTextilesOption.name
          }] : [],
          'armTextileGrade': this.selectedOptions.armTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.armTextileGrade.id),
            code: this.selectedOptions.armTextileGrade.code,
            name: this.selectedOptions.armTextileGrade.name
          }] : [],
          'armTextileGradeName': this.selectedOptions.armTextileGradeName,
          'armBackTextileGrade': this.selectedOptions.armBackTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.armBackTextileGrade.id),
            code: this.selectedOptions.armBackTextileGrade.code,
            name: this.selectedOptions.armBackTextileGrade.name
          }] : [],
          'armBackTextileGradeName': this.selectedOptions.armBackTextileGradeName,
          'armSeatTextileGrade': this.selectedOptions.armSeatTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.armSeatTextileGrade.id),
            code: this.selectedOptions.armSeatTextileGrade.code,
            name: this.selectedOptions.armSeatTextileGrade.name
          }] : [],
          'armSeatTextileGradeName': this.selectedOptions.armSeatTextileGradeName,
          'backTextileGrade': this.selectedOptions.backTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.backTextileGrade.id),
            code: this.selectedOptions.backTextileGrade.code,
            name: this.selectedOptions.backTextileGrade.name
          }] : [],
          'backTextileGradeName': this.selectedOptions.backTextileGradeName,
          'seatTextileGrade': this.selectedOptions.seatTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.seatTextileGrade.id),
            code: this.selectedOptions.seatTextileGrade.code,
            name: this.selectedOptions.seatTextileGrade.name
          }] : [],
          'seatTextileGradeName': this.selectedOptions.seatTextileGradeName,
          'seatBackTextileGrade': this.selectedOptions.seatBackTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.seatBackTextileGrade.id),
            code: this.selectedOptions.seatBackTextileGrade.code,
            name: this.selectedOptions.seatBackTextileGrade.name
          }] : [],
          'seatBackTextileGradeName': this.selectedOptions.seatBackTextileGradeName,
          'sideATextileGrade': this.selectedOptions.sideATextileGrade.id ? [{
            id: parseInt(this.selectedOptions.sideATextileGrade.id),
            code: this.selectedOptions.sideATextileGrade.code,
            name: this.selectedOptions.sideATextileGrade.name
          }] : [],
          'sideATextileGradeName': this.selectedOptions.sideATextileGradeName,
          'sideBTextileGrade': this.selectedOptions.sideBTextileGrade.id ? [{
            id: parseInt(this.selectedOptions.sideBTextileGrade.id),
            code: this.selectedOptions.sideBTextileGrade.code,
            name: this.selectedOptions.sideBTextileGrade.name
          }] : [],
          'sideBTextileGradeName': this.selectedOptions.sideBTextileGradeName,
          'additionalNotes': this.selectedOptions.additionalNotes,
          'priceTAN': currency(this.productData.pricing.TAN),
          'priceSET': currency(this.productData.pricing.SET),
          'priceINL': currency(this.productData.pricing.INL),
          'priceIA': currency(this.productData.pricing.IA),
          'priceXX': currency(this.productData.pricing.XX),
          'priceWAC': currency(this.productData.pricing.WAC),
          'priceCA1': currency(this.productData.pricing.CA1),
          'priceCA2': currency(this.productData.pricing.CA2),
          'priceCA3': currency(this.productData.pricing.CA3),
          'priceCA4': currency(this.productData.pricing.CA4),
          'priceCA5': currency(this.productData.pricing.CA5),
          'priceCA6': currency(this.productData.pricing.CA6),
          'priceCA7': currency(this.productData.pricing.CA7),
          'priceLTT': currency(this.productData.pricing.LTT),
          'priceFTT': currency(this.productData.pricing.FTT),
          'priceWTT': currency(this.productData.pricing.WTT),
          'priceWTA': currency(this.productData.pricing.WTA),
          'priceWTB': currency(this.productData.pricing.WTB),
          'priceWTW': currency(this.productData.pricing.WTW),
          'priceVTA': currency(this.productData.pricing.VTA),
          'priceVTW': currency(this.productData.pricing.VTW),
          'priceUPH': currency(this.productData.pricing.UPH),
          'priceCT1': currency(this.productData.pricing.CT1),
          'priceCT2': currency(this.productData.pricing.CT2),
          'priceCT3': currency(this.productData.pricing.CT3),
          'priceCT4': currency(this.productData.pricing.CT4),
          'priceCT5': currency(this.productData.pricing.CT5),
          'priceCT6': currency(this.productData.pricing.CT6),
          'priceCT7': currency(this.productData.pricing.CT7),
          'priceSTT': currency(this.productData.pricing.STT),
          'priceMTT': currency(this.productData.pricing.MTT),
          'priceWCW': currency(this.productData.pricing.WCW),
          'priceWCA': currency(this.productData.pricing.WCA),
          'priceWGA': currency(this.productData.pricing.WGA),
          'priceWOA': currency(this.productData.pricing.WOA),
          'priceWWA': currency(this.productData.pricing.WWA),
          'priceWNP': currency(this.productData.pricing.WNP),
          'priceCS1': currency(this.productData.pricing.CS1),
          'priceCS2': currency(this.productData.pricing.CS2),
          'priceCS3': currency(this.productData.pricing.CS3),
          'priceCS4': currency(this.productData.pricing.CS4),
          'priceCS5': currency(this.productData.pricing.CS5),
          'priceCS6': currency(this.productData.pricing.CS6),
          'priceCS7': currency(this.productData.pricing.CS7),
          'priceLM': currency(this.productData.pricing.LM),
          'priceLW': currency(this.productData.pricing.LW),
          'priceMLG': currency(this.productData.pricing.MLG),
          'priceMSG': currency(this.productData.pricing.MSG),
          'priceMMB': currency(this.productData.pricing.MMB),
          'priceMDG': currency(this.productData.pricing.MDG),
          'priceMSM': currency(this.productData.pricing.MSM),
          'priceMNB': currency(this.productData.pricing.MNB),
          'priceMYL': currency(this.productData.pricing.MYL),
          'priceMGG': currency(this.productData.pricing.MGG),
          'priceMCR': currency(this.productData.pricing.MCR),
          'priceMSS': currency(this.productData.pricing.MSS),
          'priceSBF': currency(this.productData.pricing.SBF),
          'priceSBR': currency(this.productData.pricing.SBR),
          'priceL002': currency(this.productData.pricing.L002),
          'priceL003': currency(this.productData.pricing.L003),
          'priceL007': currency(this.productData.pricing.L007),
          'priceL009': currency(this.productData.pricing.L009),
          'priceL027': currency(this.productData.pricing.L027),
          'priceL032': currency(this.productData.pricing.L032),
          'priceMB': currency(this.productData.pricing.MB),
          'priceRC': currency(this.productData.pricing.RC),
          'priceFB': currency(this.productData.pricing.FB),
          'priceCAS': currency(this.productData.pricing.CAS),
          'priceAP': currency(this.productData.pricing.AP),
          'priceDJ': currency(this.productData.pricing.DJ),
          'priceHL1': currency(this.productData.pricing.HL1),
          'priceHR1': currency(this.productData.pricing.HR1),
          'priceHL2': currency(this.productData.pricing.HL2),
          'priceHR2': currency(this.productData.pricing.HR2),
          'priceHL3': currency(this.productData.pricing.HL3),
          'priceHR3': currency(this.productData.pricing.HR3),
          'priceP1': currency(this.productData.pricing.P1),
          'priceP2': currency(this.productData.pricing.P2),
          'priceP3': currency(this.productData.pricing.P3),
          'priceFW': currency(this.productData.pricing.FW),
          'priceWL': currency(this.productData.pricing.WL),
          'priceWR': currency(this.productData.pricing.WR),
          'priceNW': currency(this.productData.pricing.NW),
          'priceDL': currency(this.productData.pricing.DL),
          'priceVA': currency(this.productData.pricing.VA),
          'priceVW': currency(this.productData.pricing.VW),
          'priceTD': currency(this.productData.pricing.TD),
          'priceAS': currency(this.productData.pricing.AS),
          'priceFH': currency(this.productData.pricing.FH),
          'priceGH': currency(this.productData.pricing.GH),
          'priceTA': currency(this.productData.pricing.TA),
          'priceAT': currency(this.productData.pricing.AT),
          'priceST': currency(this.productData.pricing.ST),
          'priceBT': currency(this.productData.pricing.BT),
          'priceAB': currency(this.productData.pricing.AB),
          'priceGRCOM': currency(this.textileData.priceGRCOM),
          'priceGR01': currency(this.textileData.priceGR01),
          'priceGR02': currency(this.textileData.priceGR02),
          'priceGR03': currency(this.textileData.priceGR03),
          'priceGR04': currency(this.textileData.priceGR04),
          'priceGR05': currency(this.textileData.priceGR05),
          'priceGR06': currency(this.textileData.priceGR06),
          'priceGR07': currency(this.textileData.priceGR07),
          'priceGR08': currency(this.textileData.priceGR08),
          'priceGR09': currency(this.textileData.priceGR09),
          'priceGR10': currency(this.textileData.priceGR10),
          'priceGR11': currency(this.textileData.priceGR11),
          'priceGR12': currency(this.textileData.priceGR12),
          'priceGR13': currency(this.textileData.priceGR13),
          'priceGR14': currency(this.textileData.priceGR14),
          'priceGR15': currency(this.textileData.priceGR15),
          'priceB': currency(this.productData.pricing.B),
          'priceA': currency(this.productData.pricing.A),
          'priceW': currency(this.productData.pricing.W),
          'priceOM': currency(this.productData.pricing.OM),
          'priceLA': currency(this.productData.pricing.LA),
          'priceMA': currency(this.productData.pricing.MA),
          'priceHA': currency(this.productData.pricing.HA),
          'priceLTL': currency(this.productData.pricing.LTL),
          'priceFTL': currency(this.productData.pricing.FTL),
          'priceSSL': currency(this.productData.pricing.SSL),
          'priceVAL': currency(this.productData.pricing.VAL),
          'priceVWL': currency(this.productData.pricing.VWL),
          'priceXXS': currency(this.productData.pricing.XXS),
          'priceLTS': currency(this.productData.pricing.LTS),
          'priceFTS': currency(this.productData.pricing.FTS),
          'priceSSS': currency(this.productData.pricing.SSS),
          'priceVAS': currency(this.productData.pricing.VAS),
          'priceVWS': currency(this.productData.pricing.VWS),
          'priceSAS': currency(this.productData.pricing.SAS),
          'priceSWS': currency(this.productData.pricing.SWS),
          'priceSTS': currency(this.productData.pricing.STS),
          'priceRWM': currency(this.productData.pricing.RWM),
          'priceRVQ': currency(this.productData.pricing.RVQ),
          'priceRCQ': currency(this.productData.pricing.RCQ),
          'priceRIQ': currency(this.productData.pricing.RIQ),
          'priceROQ': currency(this.productData.pricing.ROQ),
          'priceTSS': currency(this.productData.pricing.TSS),
          'priceTSM': currency(this.productData.pricing.TSM),
          'priceTSL': currency(this.productData.pricing.TSL),
          'pricePC2PC6PG2PG6': currency(this.productData.pricing.PC2PC6PG2PG6),
          'pricePB4RPE2PE6PH4R': currency(this.productData.pricing.PB4RPE2PE6PH4R),
          'pricePB4RPD4RPF4RPH4R': currency(this.productData.pricing.PB4RPD4RPF4RPH4R),
          'pricePC4PG4': currency(this.productData.pricing.PC4PG4),
          'pricePC4PG2': currency(this.productData.pricing.PC4PG2),
          'pricePC2PG4': currency(this.productData.pricing.PC2PG4),
          'pricePC4': currency(this.productData.pricing.PC4),
          'pricePG4': currency(this.productData.pricing.PG4),
          'pricePC2': currency(this.productData.pricing.PC2),
          'pricePC2PG2': currency(this.productData.pricing.PC2PG2),
          'priceP60D2': currency(this.productData.pricing.P60D2),
          'priceT1E2T1E6': currency(this.productData.pricing.T1E2T1E6),
          'priceT1E2': currency(this.productData.pricing.T1E2),
          'priceT1E3': currency(this.productData.pricing.T1E3),
          'priceT1E4': currency(this.productData.pricing.T1E4),
          'priceT1A4T1I2': currency(this.productData.pricing.T1A4T1I2),
          'priceT1A2T1I4': currency(this.productData.pricing.T1A2T1I4),
          'priceT1G2': currency(this.productData.pricing.T1G2),
          'priceT1F2': currency(this.productData.pricing.T1F2),
          'priceT2E2T2E6': currency(this.productData.pricing.T2E2T2E6),
          'priceT2E2': currency(this.productData.pricing.T2E2),
          'priceT2E3': currency(this.productData.pricing.T2E3),
          'priceT2E4': currency(this.productData.pricing.T2E4),
          'priceT2A4T2I2': currency(this.productData.pricing.T2A4T2I2),
          'priceT2A2T2I4': currency(this.productData.pricing.T2A2T2I4),
          'priceT2G2': currency(this.productData.pricing.T2G2),
          'priceT2F2': currency(this.productData.pricing.T2F2),
          'priceSCR1B': currency(this.productData.pricing.SCR1B),
          'priceSCR1A': currency(this.productData.pricing.SCR1A),
          'priceSCR1W': currency(this.productData.pricing.SCR1W),
          'priceSCR1OM': currency(this.productData.pricing.SCR1OM),
          'priceVTB': currency(this.productData.pricing.VTB),
          'priceWCB': currency(this.productData.pricing.WCB),
          'priceWOB': currency(this.productData.pricing.WOB),
          'priceWWB': currency(this.productData.pricing.WWB),
          'priceWGB': currency(this.productData.pricing.WGB),
          'priceWBA': currency(this.productData.pricing.WBA),
          'priceWBB': currency(this.productData.pricing.WBB),
          'pricePEW': currency(this.productData.pricing.PEW),
          'pricePCY': currency(this.productData.pricing.PCY),
          'pricePDP': currency(this.productData.pricing.PDP),
          'pricePFG': currency(this.productData.pricing.PFG),
          'pricePOB': currency(this.productData.pricing.POB),
          'pricePBO': currency(this.productData.pricing.PBO),
          'pricePSG': currency(this.productData.pricing.PSG),
          'pricePM1': currency(this.productData.pricing.PM1),
          'pricePM2': currency(this.productData.pricing.PM2),
          'priceFAS': currency(this.productData.pricing.FAS),
          'priceFBU': currency(this.productData.pricing.FBU),
          'priceFPW': currency(this.productData.pricing.FPW),
          'priceFSA': currency(this.productData.pricing.FSA),
          'priceFCH': currency(this.productData.pricing.FCH),
          'priceFPE': currency(this.productData.pricing.FPE),
          'priceFMU': currency(this.productData.pricing.FMU),
          'priceFVA': currency(this.productData.pricing.FVA),
          'priceFIR': currency(this.productData.pricing.FIR),
          'priceFSB': currency(this.productData.pricing.FSB),
          'priceFAQ': currency(this.productData.pricing.FAQ),
          'priceFMB': currency(this.productData.pricing.FMB),
          'priceFOL': currency(this.productData.pricing.FOL),
          'priceFPO': currency(this.productData.pricing.FPO),
          'priceYUS': currency(this.productData.pricing.YUS),
          'priceKIS': currency(this.productData.pricing.KIS),
          'priceHIS': currency(this.productData.pricing.HIS),
          'priceBLS': currency(this.productData.pricing.BLS),
          'priceSST': currency(this.productData.pricing.SST),
          'priceCTT': currency(this.productData.pricing.CTT),
          'priceRTT': currency(this.productData.pricing.RTT),
          'priceWNB': currency(this.productData.pricing.WNB),
          'priceCNC': currency(this.productData.pricing.CNC),
          'priceCDK': currency(this.productData.pricing.CDK),
          'priceLTF': currency(this.productData.pricing.LTF),
          'priceLTP': currency(this.productData.pricing.LTP),
          'priceLTW': currency(this.productData.pricing.LTW),
          'priceLTA': currency(this.productData.pricing.LTA),
          'priceRCW': currency(this.productData.pricing.RCW),
          'priceRBD': currency(this.productData.pricing.RBD),
          'priceMBT': currency(this.productData.pricing.MBT)
      };


      // Push product into quote products array
      this.quoteData.products.push({'product': productConfiguration});

      // Create temp data
      const tempProductData = JSON.parse(JSON.stringify(this.quoteData.products));

      // Format data for each individual product
      tempProductData.map((item, index) => {

          // Set unique block id
          var blockId = 'new:'+(index+1);

          // Add block id to product sort order
          this.productsSortOrder.push(blockId);

          // Set product block
          var productBlock  = {
              'product': {
                 'configuration': JSON.stringify(item.product)
              }
          }

          // Push block to formatted product data
          this.formattedProductData[blockId] = productBlock;
      });

      const freightSurchargeTotal = ((this.quoteData.freightSurcharge > 0 ? ((this.quoteListPrice * this.quoteData.freightSurcharge) / 100) : 0) + (this.quoteData.liftGate >= 1 ? this.quoteData.liftGateUpcharge : 0) + (this.quoteData.residentialDelivery >= 1 ? this.quoteData.residentialDeliveryUpcharge : 0) + (this.quoteData.deliveryAppointment >= 1 ? this.quoteData.deliveryAppointmentUpcharge : 0));

      const minimumOrderSurchargeTotal = (this.totalUnits < this.quoteData.minimumOrderThreshold && this.quoteData.waiveMinimumOrderSurcharge !== 'yes') ? this.quoteData.minimumOrderSurcharge : 0;

      const totalTaxPrice = this.quoteData.salesTax > 0 ? Number(parseFloat(((this.quoteNetPrice + freightSurchargeTotal + minimumOrderSurchargeTotal) * this.quoteData.salesTax) / 100).toFixed(2).replace(/\.00/g,'')) : 0;

      const quoteTotalPrice = Number(parseFloat(this.quoteNetPrice + freightSurchargeTotal + minimumOrderSurchargeTotal + totalTaxPrice).toFixed(2).replace(/\.00/g,''));

      // Set up variables for saving
      const variables = {
          'id': this.quoteId,
          'title': this.quoteData.customerName+' - '+this.quoteData.project,
          'customerName': this.quoteData.customerName,
          'customerContact': this.quoteData.customerContact,
          'emailAddress': this.quoteData.emailAddress,
          'project': this.quoteData.project,
          'address': this.quoteData.address,
          'terms': this.quoteData.terms,
          'shippingType': this.quoteData.shippingType,
          'shipTo': this.quoteData.shipTo,
          'specifiedBy': this.quoteData.specifiedBy,
          'dealer': this.quoteData.dealer,
          'architect': this.quoteData.architect,
          'contact': this.quoteData.contact,
          'shipDate': this.quoteData.shipDate,
          'createdBy': this.quoteData.createdBy,
          'leadTime': this.quoteData.leadTime,
          'notes': this.quoteData.notes,
          'productsJSON': {
              sortOrder: this.productsSortOrder,
              blocks: this.formattedProductData,
          },
          'totalListPrice': this.quoteListPrice,
          'totalTextilePrice': this.quoteTextilePrice,
          'totalNetPrice': this.quoteNetPrice,
          'totalTaxPrice': totalTaxPrice,
          'totalQuotePrice': quoteTotalPrice,
          'minimumOrderSurcharge': this.quoteData.minimumOrderSurcharge,
          'minimumOrderThreshold': this.quoteData.minimumOrderThreshold,
          'lastSavedDiscount': this.productDiscount,
          'quoteType': this.quoteData.quoteType,
          'waiveMinimumOrderSurcharge': this.quoteData.waiveMinimumOrderSurcharge,
          'authorId': this.quoteData.author.id,
          'lastApprovalRequestDate': this.quoteData.lastApprovalRequestDate,
          'approvalState': this.quoteData.approvalState,
          'approvalMessage': this.quoteData.approvalMessage,
          'dateQuoteUpdated': this.quoteData.dateQuoteUpdated,
          'validUntilDate': this.quoteData.validUntilDate,
          'lastSaveBy': parseFloat(this.userId)
      }

      // Execute the query
      executeMutation(this.quoteApi, saveQuoteMutation, variables, (data) => {
          this.submitted = true;
          this.productSaved = true;
          window.location.replace('/quote/edit/'+this.quoteId+'#'+(this.quoteData.products.length));
          console.log('submitted');
      });
    },
    onLeave() {
      if (!this.productSaved) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
  },
  computed: {
    fullCode() {
      let code = `
        ${this.productData.title}
        ${(this.availableOptions.pads && this.selectedOptions.pads.code !== 'XX') ? `-${this.selectedOptions.pads.code}` : ''}
        ${(this.availableOptions.species && this.selectedOptions.species.code) ? `-${this.selectedOptions.species.code}` : ''}
        ${(this.availableOptions.tables && this.selectedOptions.tables.code !== 'XX') ? `-${this.selectedOptions.tables.code}` : ''}
        ${(this.availableOptions.partitions && this.selectedOptions.partitions.code !== 'XX') ? `-${this.selectedOptions.partitions.code}` : ''}
        ${this.selectedOptions.module0.code ? `-${this.selectedOptions.module0.code}` : ''}
        ${this.isThreeSeat === 1 && this.selectedOptions.module0.code === 'SET' ? `-NA` : this.selectedOptions.module1.code ? `-${this.selectedOptions.module1.code}` : ''}
        ${this.isThreeSeat === 1 && this.selectedOptions.module0.code === 'SET' ? `-NA` : this.selectedOptions.module2.code ? `-${this.selectedOptions.module2.code}` : ''}
        ${this.isThreeSeat === 1 && this.selectedOptions.module2.code === 'SET' ? `-NA` : this.selectedOptions.module3.code ? `-${this.selectedOptions.module3.code}` : ''}
        ${this.isThreeSeat === 1 && this.selectedOptions.module2.code === 'SET' ? `-NA` : this.selectedOptions.module4.code ? `-${this.selectedOptions.module4.code}` : ''}
        ${(this.availableOptions.armHeights && this.selectedOptions.armHeight.code) ? `-${this.selectedOptions.armHeight.code}` : ''}
        ${this.selectedOptions.tableTopSize.code ? `-${this.selectedOptions.tableTopSize.code}` : ''}
        ${this.selectedOptions.armCapMaterial.code ? `-${this.selectedOptions.armCapMaterial.code}` : ''}
        ${this.selectedOptions.tableTopMaterial.code && ((((this.isTwoSeat === 1 || this.isThreeSeat === 1) && (this.selectedOptions.module0.code === 'INL' || this.selectedOptions.module2.code === 'INL' || this.selectedOptions.module4.code === 'INL')) || (this.availableOptions.modules === false) && !this.isMaru()) || (this.isMaru() && ((this.availableOptions.tableTopMaterials && !this.availableOptions.tables) || (this.availableOptions.tableTopMaterials && this.availableOptions.tables && this.selectedOptions.tables.code != 'XX')))) ? `-${this.selectedOptions.tableTopMaterial.code}` : ''}
        ${this.selectedOptions.tableTopMaterialFinishSideA.code ? `-${this.selectedOptions.tableTopMaterialFinishSideA.code}` : ''}
        ${this.selectedOptions.tableTopMaterialFinishSideB.code && (this.selectedOptions.tableTopMaterial.code === 'FTT' || this.selectedOptions.tableTopMaterial.code === 'LTT') ? `-${this.selectedOptions.tableTopMaterialFinishSideB.code}` : ''}
        ${this.selectedOptions.tableTopMaterial.code === 'FTT' && this.selectedOptions.tableTopMaterialForboFinish.code ? `-${this.selectedOptions.tableTopMaterialForboFinish.code}` : ''}
        ${this.selectedOptions.tableTopMaterial.code === 'MTT' && this.selectedOptions.tableTopMaterialMetalFinish.code ? `-${this.selectedOptions.tableTopMaterialMetalFinish.code}` : ''}
        ${this.selectedOptions.largeInsertTopMaterial.code ? `-${this.selectedOptions.largeInsertTopMaterial.code}` : ''}
        ${this.selectedOptions.largeInsertTopMaterial.code === 'FTL' && this.selectedOptions.largeInsertTopMaterialForboFinish.code ? `-${this.selectedOptions.largeInsertTopMaterialForboFinish.code}` : ''}
        ${this.selectedOptions.largeInsertTopMaterial.code === 'SSL' && this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code ? `-${this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code}` : ''}
        ${this.selectedOptions.smallInsertTopMaterial.code ? `-${this.selectedOptions.smallInsertTopMaterial.code}` : ''}
        ${this.selectedOptions.smallInsertTopMaterial.code === 'FTS' && this.selectedOptions.smallInsertTopMaterialForboFinish.code ? `-${this.selectedOptions.smallInsertTopMaterialForboFinish.code}` : ''}
        ${this.selectedOptions.smallInsertTopMaterial.code === 'SSS' && this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code ? `-${this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code}` : ''}
        ${((this.selectedOptions.largeInsertTopMaterial.code === 'VAL' || this.selectedOptions.largeInsertTopMaterial.code === 'VWL') || (this.selectedOptions.smallInsertTopMaterial.code === 'VAS' || this.selectedOptions.smallInsertTopMaterial.code === 'VWS') || (this.selectedOptions.smallInsertTopMaterial.code === 'SAS' || this.selectedOptions.smallInsertTopMaterial.code === 'SWS') || this.productData.title == 'HK01-01') && this.selectedOptions.woodFinish.code ? `-${this.selectedOptions.woodFinish.code}` : ''}
        ${this.selectedOptions.smallInsertTopMaterial.code === 'STS' && this.selectedOptions.stoneQuartzTableTop.code ? `-${this.selectedOptions.stoneQuartzTableTop.code}` : ''}
        ${this.selectedOptions.caseworkSurfaceMaterial.code ? `-${this.selectedOptions.caseworkSurfaceMaterial.code}` : ''}
        ${this.selectedOptions.columnShroudPaint.code ? `-${this.selectedOptions.columnShroudPaint.code}` : ''}
        ${this.selectedOptions.legMaterial.code ? `-${this.selectedOptions.legMaterial.code}` : ''}
        ${this.selectedOptions.legMaterial.code !== 'LW' && this.selectedOptions.metalFinish.code && !(this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') ? `-${this.selectedOptions.metalFinish.code}` : ''}
        ${this.selectedOptions.legMaterial.code !== 'LM' && this.selectedOptions.woodFinish.code && !(this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3' || this.productData.title == 'HK01-01') && !((this.productData.title == 'HK01-T1' || this.productData.title == 'HK01-T2') && this.selectedOptions.tableTopMaterial.code == 'FTT') && !(this.availableOptions.finishType && this.selectedOptions.finishType === 'paintedWood') ? `-${this.selectedOptions.woodFinish.code}` : ''}
        ${this.selectedOptions.paintedWood.code && !(this.availableOptions.finishType && this.selectedOptions.finishType === 'woodFinish') ? `-${this.selectedOptions.paintedWood.code}` : ''}
        ${this.selectedOptions.legMaterial.code !== 'LW' && this.selectedOptions.metalFinish.code && (this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') ? `-${this.selectedOptions.metalFinish.code}` : ''}
        ${this.selectedOptions.swivelBaseType.code ? `-${this.selectedOptions.swivelBaseType.code}` : ''}
        ${(this.selectedOptions.leatherArmWrap.code && this.selectedOptions.leatherArmWrap.code !== 'LXXX') ? `-${this.selectedOptions.leatherArmWrap.code}` : ''}
        ${this.selectedOptions.moistureBarrier ? '-MB' : ''}
        ${this.selectedOptions.removeableCovers ? '-RC' : ''}
        ${this.selectedOptions.calTb133 ? '-FB' : ''}
        ${this.selectedOptions.casters ? '-CAS' : ''}
        ${this.selectedOptions.removeableArmPads ? '-AP' : ''}
        ${this.selectedOptions.hangingFixture ? '-DJ' : ''}
        ${this.selectedOptions.hinge0.code ? `-${this.selectedOptions.hinge0.code}` : ''}
        ${this.selectedOptions.hinge1.code ? `-${this.selectedOptions.hinge1.code}` : ''}
        ${this.selectedOptions.hinge2.code ? `-${this.selectedOptions.hinge2.code}` : ''}
        ${this.selectedOptions.pull.code ? `-${this.selectedOptions.pull.code}` : ''}
        ${this.selectedOptions.wrap.code ? `-${this.selectedOptions.wrap.code}` : ''}
        ${this.selectedOptions.drawerLiner ? '-DL' : ''}
        ${this.selectedOptions.veneerOuterCase.code ? `-${this.selectedOptions.veneerOuterCase.code}` : ''}
        ${this.selectedOptions.paperTowelDispenser ? '-TD' : ''}
        ${this.selectedOptions.extraAdjustableShelf ? '-AS' : ''}
        ${this.selectedOptions.fileHolders ? '-FH' : ''}
        ${this.selectedOptions.gloveCutOuts ? '-GH' : ''}
        ${(this.availableOptions.powerModules && this.selectedOptions.powerModules.code !== 'XX') ? `-${this.selectedOptions.powerModules.code}` : ''}
        ${this.selectedOptions.multTextilesOption.code && this.selectedOptions.multTextilesOption.code !== 'XX' ? `-${this.selectedOptions.multTextilesOption.code}` : ''}
      `;

      return code.replace(/^\s+|\s+$/gm, '').split('\n').join('');
    },
    fetchLineArtUrl() {
      let imageUrl = '';
      let filename = `
      ${this.productData.title}
      ${(this.availableOptions.species && this.selectedOptions.species.code && !this.isMaru()) ? `-${this.selectedOptions.species.code}` : ''}
      ${this.selectedOptions.module0.code ? `-${this.selectedOptions.module0.code}` : ''}
      ${this.isThreeSeat === 1 && this.selectedOptions.module0.code === 'SET' ? `-NA` : this.selectedOptions.module1.code ? `-${this.selectedOptions.module1.code}` : ''}
      ${this.isThreeSeat === 1 && this.selectedOptions.module0.code === 'SET' ? `-NA` : this.selectedOptions.module2.code ? `-${this.selectedOptions.module2.code}` : ''}
      ${this.isThreeSeat === 1 && this.selectedOptions.module2.code === 'SET' ? `-NA` : this.selectedOptions.module3.code ? `-${this.selectedOptions.module3.code}` : ''}
      ${this.isThreeSeat === 1 && this.selectedOptions.module2.code === 'SET' ? `-NA` : this.selectedOptions.module4.code ? `-${this.selectedOptions.module4.code}` : ''}
      ${(this.availableOptions.armHeights && this.selectedOptions.armHeight.code) ? `-${this.selectedOptions.armHeight.code}` : ''}
      ${(this.availableOptions.tableTopSizes && this.selectedOptions.tableTopSize.code) ? `-${this.selectedOptions.tableTopSize.code}` : ''}
      ${(this.availableOptions.pads && this.selectedOptions.pads.code !== 'XX') ? `-${this.selectedOptions.pads.code}` : ''}
      ${(this.availableOptions.tables && this.selectedOptions.tables.code !== 'XX') ? `-${this.selectedOptions.tables.code}` : ''}
      ${(this.availableOptions.partitions && this.selectedOptions.partitions.code !== 'XX') ? `-${this.selectedOptions.partitions.code}` : ''}
      `;
      filename = filename.replace(/^\s+|\s+$/gm, '').split('\n').join('')
      imageUrl = this.productData ? `/uploads/line-art/${filename}.svg` : '';
      return this.editingLoading ? '' : imageUrl;
    },
    fetchModuleLineArt() {
      let imageUrl = '';
      let filename = `
      ${'Tandem-'}
      ${ this.isThreeSeat ? 'Three-Seat' : 'Two-Seat' }
      `;
      filename = filename.replace(/^\s+|\s+$/gm, '').split('\n').join('')
      imageUrl = this.productData ? `/uploads/line-art/${filename}.svg` : '';
      return imageUrl;
    },
    fetchPullsLineArt() {
      let imageUrl = '';
      let filename = `
      ${'Pulls-'}
      ${ this.selectedOptions.pull.code }
      `;
      filename = filename.replace(/^\s+|\s+$/gm, '').split('\n').join('')
      imageUrl = this.productData ? `/uploads/line-art/${filename}.svg` : '';
      return imageUrl;
    },
    totalUnits: function() {
        return this.quoteData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.quantity);
          let valueCleaned =  parseFloat(value);
          return parseFloat(valueCleaned);
        }, 0);
    },
    unitListPrice: function() {
      let price = currency(this.productData.baseCom)
        .add((this.availableOptions.species && this.selectedOptions.species.code) ? this.productData.pricing[this.selectedOptions.species.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.module0.code ? this.productData.pricing[this.selectedOptions.module0.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.module1.code && !(this.isThreeSeat === 1 && this.selectedOptions.module0.code === 'SET') ? this.productData.pricing[this.selectedOptions.module1.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.module2.code && !(this.isThreeSeat === 1 && this.selectedOptions.module0.code === 'SET') ? this.productData.pricing[this.selectedOptions.module2.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.module3.code && !(this.isThreeSeat === 1 && this.selectedOptions.module2.code === 'SET') ? this.productData.pricing[this.selectedOptions.module3.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.module4.code && !(this.isThreeSeat === 1 && this.selectedOptions.module2.code === 'SET') ? this.productData.pricing[this.selectedOptions.module4.code.replace(/-/g, '')] : 0)
        .add((this.availableOptions.armHeights && this.selectedOptions.armHeight.code) ? this.productData.pricing[this.selectedOptions.armHeight.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.tableTopSize.code ? this.productData.pricing[this.selectedOptions.tableTopSize.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.armCapMaterial.code ? this.productData.pricing[this.selectedOptions.armCapMaterial.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.pads.code ? this.productData.pricing[this.selectedOptions.pads.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.tables.code ? this.productData.pricing[this.selectedOptions.tables.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.partitions.code ? this.productData.pricing[this.selectedOptions.partitions.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.powerModules.code ? this.productData.pricing[this.selectedOptions.powerModules.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.tableTopMaterial.code && ((((this.isTwoSeat === 1 || this.isThreeSeat === 1) && (this.selectedOptions.module0.code === 'INL' || this.selectedOptions.module2.code === 'INL' || this.selectedOptions.module4.code === 'INL')) || (this.availableOptions.modules === false) && !this.isMaru()) || (this.isMaru() && ((this.availableOptions.tableTopMaterials && !this.availableOptions.tables) || (this.availableOptions.tableTopMaterials && this.availableOptions.tables && this.selectedOptions.tables.code != 'XX')))) ? this.productData.pricing[this.selectedOptions.tableTopMaterial.code.replace(/-/g, '')] : 0) // Requires "INL" be set on at least one module, if modules exist
        .add(this.selectedOptions.tableTopMaterialFinishSideA.code ? this.productData.pricing[this.selectedOptions.tableTopMaterialFinishSideA.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.tableTopMaterialFinishSideB.code && (this.selectedOptions.tableTopMaterial.code === 'FTT' || this.selectedOptions.tableTopMaterial.code === 'LTT') ? this.productData.pricing[this.selectedOptions.tableTopMaterialFinishSideB.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.tableTopMaterial.code === 'FTT' && this.selectedOptions.tableTopMaterialForboFinish.code ? this.productData.pricing[this.selectedOptions.tableTopMaterialForboFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.tableTopMaterial.code === 'MTT' && this.selectedOptions.tableTopMaterialMetalFinish.code ? this.productData.pricing[this.selectedOptions.tableTopMaterialMetalFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.largeInsertTopMaterial.code ? this.productData.pricing[this.selectedOptions.largeInsertTopMaterial.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.largeInsertTopMaterial.code === 'FTL' && this.selectedOptions.largeInsertTopMaterialForboFinish.code ? this.productData.pricing[this.selectedOptions.largeInsertTopMaterialForboFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.largeInsertTopMaterial.code === 'SSL' && this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code ? this.productData.pricing[this.selectedOptions.largeInsertTopMaterialSolidSurfaceFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.smallInsertTopMaterial.code ? this.productData.pricing[this.selectedOptions.smallInsertTopMaterial.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.smallInsertTopMaterial.code === 'FTS' && this.selectedOptions.smallInsertTopMaterialForboFinish.code ? this.productData.pricing[this.selectedOptions.smallInsertTopMaterialForboFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.smallInsertTopMaterial.code === 'SSS' && this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code ? this.productData.pricing[this.selectedOptions.smallInsertTopMaterialSolidSurfaceFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.caseworkSurfaceMaterial.code ? this.productData.pricing[this.selectedOptions.caseworkSurfaceMaterial.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.columnShroudPaint.code ? this.productData.pricing[this.selectedOptions.columnShroudPaint.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.legMaterial.code ? this.productData.pricing[this.selectedOptions.legMaterial.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.metalFinish.code && this.selectedOptions.legMaterial.code !== 'LW' ? this.productData.pricing[this.selectedOptions.metalFinish.code.replace(/-/g, '')] : 0)
        .add((this.selectedOptions.woodFinish.code && this.selectedOptions.legMaterial.code !== 'LM') && !(this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') && !((this.productData.title == 'HK01-T1' || this.productData.title == 'HK01-T2') && this.selectedOptions.tableTopMaterial.code == 'FTT') && !(this.availableOptions.finishType && this.selectedOptions.finishType === 'paintedWood') ? this.productData.pricing[this.selectedOptions.woodFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.woodFinish.code && ((this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3' || this.productData.title == 'NC01-T1') && (this.selectedOptions.largeInsertTopMaterial.code == 'VAL' || this.selectedOptions.largeInsertTopMaterial.code == 'VWL' || this.selectedOptions.smallInsertTopMaterial.code == 'VAS' || this.selectedOptions.smallInsertTopMaterial.code == 'VWS' || this.selectedOptions.smallInsertTopMaterial.code == 'SAS' || this.selectedOptions.smallInsertTopMaterial.code == 'SWS')) ? this.productData.pricing[this.selectedOptions.woodFinish.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.paintedWood.code && !(this.availableOptions.finishType && this.selectedOptions.finishType === 'woodFinish') ? this.productData.pricing[this.selectedOptions.paintedWood.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.stoneQuartzTableTop.code && this.selectedOptions.smallInsertTopMaterial.code == 'STS' ? this.productData.pricing[this.selectedOptions.stoneQuartzTableTop.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.swivelBaseType.code ? this.productData.pricing[this.selectedOptions.swivelBaseType.code.replace(/-/g, '')] : 0)
        .add((this.selectedOptions.leatherArmWrap.code && this.selectedOptions.leatherArmWrap.code !== 'LXXX') ? this.productData.pricing[this.selectedOptions.leatherArmWrap.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.moistureBarrier ? this.productData.pricing.MB : 0)
        .add(this.selectedOptions.removeableCovers ? this.productData.pricing.RC : 0)
        .add(this.selectedOptions.calTb133 ? this.productData.pricing.FB : 0)
        .add(this.selectedOptions.casters ? this.productData.pricing.CAS : 0)
        .add(this.selectedOptions.removeableArmPads ? this.productData.pricing.AP : 0)
        .add(this.selectedOptions.hangingFixture ? this.productData.pricing.DJ: 0)
        .add(this.selectedOptions.hinge0.code ? this.productData.pricing[this.selectedOptions.hinge0.code+'1'.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.hinge1.code ? this.productData.pricing[this.selectedOptions.hinge1.code+'2'.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.hinge2.code ? this.productData.pricing[this.selectedOptions.hinge2.code+'3'.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.pull.code ? this.productData.pricing[this.selectedOptions.pull.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.wrap.code ? this.productData.pricing[this.selectedOptions.wrap.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.drawerLiner ? this.productData.pricing.DL : 0)
        .add(this.selectedOptions.veneerOuterCase.code ? this.productData.pricing[this.selectedOptions.veneerOuterCase.code.replace(/-/g, '')] : 0)
        .add(this.selectedOptions.paperTowelDispenser ? this.productData.pricing.TD : 0)
        .add(this.selectedOptions.extraAdjustableShelf ? this.productData.pricing.AS : 0)
        .add(this.selectedOptions.fileHolders ? this.productData.pricing.FH : 0)
        .add(this.selectedOptions.gloveCutOuts ? this.productData.pricing.GH : 0)
        .add(this.selectedOptions.multTextilesOption.code ? this.productData.pricing[this.selectedOptions.multTextilesOption.code.replace(/-/g, '')] : 0);

      // Round cents up to nearest dollar
      price.value = Math.ceil(price.value);

      return price.value;
    },
    unitTextilePrice() {
        // Reset values
        this.singleTextilePrice = 0;
        this.armTextilePrice = 0;
        this.backTextilePrice = 0;
        this.seatTextilePrice = 0;
        this.sideATextilePrice = 0;
        this.sideBTextilePrice = 0;
        this.moduleYardage = 0;

        // If single textile
        if (this.availableOptions.textileBasicOptions) {
          // If a single has module textiles
          if (this.availableOptions.modules) {
            if (this.productData.modules.length === 3) {
              this.moduleYardage += this.selectedOptions.module0.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
              this.moduleYardage += this.selectedOptions.module2.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
            } else if (this.productData.modules.length === 5) {
              if (this.selectedOptions.module0.code === 'SET') {
                this.moduleYardage += this.selectedOptions.module0.code === 'SET' ? parseFloat(this.productData.yardage.set) : 0;
                this.moduleYardage += this.selectedOptions.module4.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
              } else if (this.selectedOptions.module2.code === 'SET') {
                this.moduleYardage += this.selectedOptions.module0.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
                this.moduleYardage += this.selectedOptions.module2.code === 'SET' ? parseFloat(this.productData.yardage.set) : 0;
              } else {
                this.moduleYardage += this.selectedOptions.module0.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
                this.moduleYardage += this.selectedOptions.module2.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
                this.moduleYardage += this.selectedOptions.module4.code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
              }
            }
            this.singleTextilePrice = (this.moduleYardage * this.textileData[`price${this.selectedOptions.singleTextileGrade.code}`]);
          } else {
            if (this.productData.title == 'KN01-S1') {
                this.singleTextilePrice = (this.productData.yardage.total > 0 && this.selectedOptions.tableTopMaterial.code === 'UPH') ? (this.productData.yardage.total * this.textileData[`price${this.selectedOptions.singleTextileGrade.code.replace(/-/g, '')}`]) : 0;
            } else {
                this.singleTextilePrice = ((this.isMaru() || this.isApril()) && this.productData.pads.length && !this.productData.multTextilesOption.length) ? (parseFloat(this.selectedOptions.pads.padYardage) * this.textileData[`price${this.selectedOptions.singleTextileGrade.code.replace(/-/g, '')}`]) : (this.productData.yardage.total * this.textileData[`price${this.selectedOptions.singleTextileGrade.code.replace(/-/g, '')}`]);
            }
          }
        }

        // If multiple textile(s)
        if (this.availableOptions.multTextilesOption) {
          // If set to XX
          if (this.selectedOptions.multTextilesOption.code === 'XX') {
            // If working with daybed, set proper yardage, else function with default
            if (this.productData.title === 'GS01-37') {
              this.singleTextilePrice = ((this.availableOptions.armHeights ? (parseFloat(this.selectedOptions.armHeight.armYardage) + parseFloat(this.productData.yardage.seat) + parseFloat(this.productData.yardage.back)).toFixed(2) : this.productData.yardage.total) * this.textileData[`price${this.selectedOptions.singleTextileGrade.code.replace(/-/g, '')}`]).toFixed(2);
            } else {
              this.singleTextilePrice = (this.isMaru() && this.productData.pads.length) ? ((parseFloat(this.selectedOptions.pads.padYardage) + parseFloat(this.productData.yardage.total)) * this.textileData[`price${this.selectedOptions.singleTextileGrade.code.replace(/-/g, '')}`]) : (this.productData.yardage.total * this.textileData[`price${this.selectedOptions.singleTextileGrade.code.replace(/-/g, '')}`]);
            }
          } else {
              this.singleTextilePrice = 0;
          }
          // If set to TA
          if (this.selectedOptions.multTextilesOption.code === 'TA') {
            // Set seat
            this.seatTextilePrice = (this.isMaru() && this.productData.pads.length) ? (this.selectedOptions.pads.padYardage * this.textileData[`price${this.selectedOptions.seatTextileGrade.code.replace(/-/g, '')}`]) : (this.productData.yardage.seat * this.textileData[`price${this.selectedOptions.seatTextileGrade.code.replace(/-/g, '')}`]);
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.textileData[`price${this.selectedOptions.backTextileGrade.code.replace(/-/g, '')}`]);
          }
          // If set to AT
          if (this.selectedOptions.multTextilesOption.code === 'AT') {
            // If working with daybed, set proper yardage, else function with default
            if (this.productData.title === 'GS01-37') {
              // Set arm
              this.armTextilePrice =
                ((this.availableOptions.armHeights ? parseFloat(this.selectedOptions.armHeight.armYardage) : this.productData.yardage.arm) * this.textileData[`price${this.selectedOptions.armTextileGrade.code.replace(/-/g, '')}`]);
            } else {
              // Set arm
              this.armTextilePrice =
                (this.productData.yardage.arm * this.textileData[`price${this.selectedOptions.armTextileGrade.code.replace(/-/g, '')}`]);
            }
            // Set seat
            this.seatTextilePrice =
              (this.productData.yardage.seat * this.textileData[`price${this.selectedOptions.seatBackTextileGrade.code.replace(/-/g, '')}`]);
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.textileData[`price${this.selectedOptions.seatBackTextileGrade.code.replace(/-/g, '')}`]);
          }
          // If set to ST
          if (this.selectedOptions.multTextilesOption.code === 'ST') {
            // Set seat
            this.seatTextilePrice =
              (this.productData.yardage.seat * this.textileData[`price${this.selectedOptions.seatTextileGrade.code.replace(/-/g, '')}`]);
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.textileData[`price${this.selectedOptions.armBackTextileGrade.code.replace(/-/g, '')}`]);

            // If working with daybed, set proper yardage, else function with default
            if (this.productData.title === 'GS01-37') {
              // Set arm
              this.armTextilePrice =
                ((this.availableOptions.armHeights ? parseFloat(this.selectedOptions.armHeight.armYardage) : this.productData.yardage.arm) * this.textileData[`price${this.selectedOptions.armBackTextileGrade.code.replace(/-/g, '')}`]);
            } else {
              // Set arm
              this.armTextilePrice =
                (this.productData.yardage.arm * this.textileData[`price${this.selectedOptions.armBackTextileGrade.code.replace(/-/g, '')}`]);
            }
          }
          // If set to BT
          if (this.selectedOptions.multTextilesOption.code === 'BT') {
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.textileData[`price${this.selectedOptions.backTextileGrade.code.replace(/-/g, '')}`]);
            // Set seat
            this.seatTextilePrice =
              (this.productData.yardage.seat * this.textileData[`price${this.selectedOptions.armSeatTextileGrade.code.replace(/-/g, '')}`]);

            // If working with daybed, set proper yardage, else function with default
            if (this.productData.title === 'GS01-37') {
              // Set arm
              this.armTextilePrice =
                ((this.availableOptions.armHeights ? parseFloat(this.selectedOptions.armHeight.armYardage) : this.productData.yardage.arm) * this.textileData[`price${this.selectedOptions.armSeatTextileGrade.code.replace(/-/g, '')}`]);
            } else {
              // Set arm
              this.armTextilePrice =
                (this.productData.yardage.arm * this.textileData[`price${this.selectedOptions.armSeatTextileGrade.code.replace(/-/g, '')}`]);
            }
          }
          // If set to AB
          if (this.selectedOptions.multTextilesOption.code === 'AB') {
            // Set side a
            this.sideATextilePrice =
              (this.productData.yardage.sideA * this.textileData[`price${this.selectedOptions.sideATextileGrade.code.replace(/-/g, '')}`]);
            // Set side b
            this.sideBTextilePrice =
              (this.productData.yardage.sideB * this.textileData[`price${this.selectedOptions.sideBTextileGrade.code.replace(/-/g, '')}`]);
          }
        }

        // Add up totals
        let price = currency(this.singleTextilePrice).add(this.armTextilePrice).add(this.backTextilePrice).add(this.seatTextilePrice).add(this.sideATextilePrice).add(this.sideBTextilePrice);

        price.value = Math.ceil(price.value);
        return price.value;
    },
    unitNetPrice() {
        let listTextile = this.unitListPrice + this.unitTextilePrice;
        let price = currency(listTextile - (listTextile * this.productDiscount / 100));

        return price.value;
    },
    unitTaxPrice() {
        let price = currency((this.unitNetPrice * this.quoteData.salesTax) / 100);

        return price.value;
    },
    extendedListPrice: function() {
        let price = currency((this.unitListPrice) * this.quantity);

        price.value = Math.ceil(price.value);
        return price.value;
    },
    extendedTextilePrice: function() {
        let price = currency((this.unitTextilePrice) * this.quantity);

        price.value = Math.ceil(price.value);
        return price.value;
    },
    extendedNetPrice() {
        let price = currency((this.unitNetPrice) * this.quantity);

        return price.value;
    },
    extendedTaxPrice() {
        let price = currency((this.unitTaxPrice) * this.quantity);

        return price.value;
    },
    quoteListPrice: function() {
        return this.quoteData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedListPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteTextilePrice: function() {
        return this.quoteData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedTextilePrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteNetPrice: function() {
        return this.quoteData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedNetPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteTaxPrice: function() {
        return this.quoteData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedTaxPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteTotalPrice: function() {
        return this.quoteData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedNetPrice).add(item.product.extendedTaxPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    }
  },
  filters: {
    formatPrice(x) {
      if (!x) return;
      x = x.toString();
      x = Math.ceil(x);
      return currency(x).format({symbol: true});
    },
    formatNetPrice(x) {
      if (!x) return;
      x = x.toString();
      return currency(x).format({symbol: true});
    }
  },
  created: function () {
    // Check whether or not there is unsaved data
    window.onbeforeunload = () => {
      // Get saved results
      var result = this.onLeave();

      // If unsaved results exist,
      // display dialog box to user
      if (result) {
        return 'You have unsaved changes. Are you sure you want to leave?';
      }
    }
  }
}
</script>
