<template>
  <div class="o-layout">
    <Loader :loading="isLoading"></Loader>
    <div class="o-layout__primary" :class="{ 'is-loading' : editingLoading }" v-if="dataLoaded">

      <section class="c-section" v-if="productData.slug != 'custom-product' && availableOptions.species">

        <h2 class="c-section__heading">Species</h2>
        <ul class="c-options-list">

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">
              {{ productData.speciesLabelOverride ? productData.speciesLabelOverride : 'Wood Type' }}<br/>
              <small v-if="productData.speciesHelperText">({{ productData.speciesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.speciesLabelOverride ? productData.speciesLabelOverride : 'Wood Type' }}<br/>
                <small v-if="productData.speciesHelperText">({{ productData.speciesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.species[0]" @change="speciesDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.species">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

        </ul>
      </section>

      <section class="c-section" v-if="availableOptions.modules && productData.slug != 'custom-product'">
        <h2 class="c-section__heading">Modules</h2>
        <ul class="c-options-list">
          <li class="c-options-list__row" v-if="currProduct.moduleOne.length">
            <h3 class="c-options-list__heading">Module 1 (LSF)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 1 (LSF)</label>
              <div class="c-select">
                <select v-model="currProduct.moduleOne[0]" @change="moduleConditions(0)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[0]"
                    :value="{id: parseInt(option.id), code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
            <div class="c-options-list__input-group">
              <figure class="c-options-list__line-art c-options-list__line-art--module">
                <img :src="fetchModuleLineArt" alt="Module line art">
              </figure>
            </div>
          </li>

          <li class="c-options-list__row" v-if="currProduct.moduleTwo.length">
            <h3 class="c-options-list__heading">Module 2 (Arm)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 2 (Arm)</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && currProduct.moduleOne[0].code === 'SET' },'c-select']">
                <select v-model="currProduct.moduleTwo[0]" :disabled="this.isThreeSeat === 1 && currProduct.moduleOne[0].code === 'SET'" @change="moduleConditions(1)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[1]"
                    :value="{id: parseInt(option.id), code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="currProduct.moduleThree.length">
            <h3 class="c-options-list__heading">Module 3</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 3</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && currProduct.moduleOne[0].code === 'SET' },'c-select']">
                <select v-model="currProduct.moduleThree[0]" :disabled="this.isThreeSeat === 1 && currProduct.moduleOne[0].code === 'SET'" @change="moduleConditions(2)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[2]"
                    :value="{id: parseInt(option.id), code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="currProduct.moduleFour.length">
            <h3 class="c-options-list__heading">Module 4 (Arm)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 4 (Arm)</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && currProduct.moduleThree[0].code === 'SET' },'c-select']">
                <select v-model="currProduct.moduleFour[0]" :disabled="this.isThreeSeat === 1 && currProduct.moduleThree[0].code === 'SET'" @change="moduleConditions(3)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[3]"
                    :value="{id: parseInt(option.id), code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="currProduct.moduleFive.length">
            <h3 class="c-options-list__heading">Module 5 (RSF)</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Module 5 (RSF)</label>
              <div :class="[{ 'c-select -is-disabled' : this.isThreeSeat === 1 && currProduct.moduleThree[0].code === 'SET' },'c-select']">
                <select v-model="currProduct.moduleFive[0]" :disabled="this.isThreeSeat === 1 && currProduct.moduleThree[0].code === 'SET'" @change="moduleConditions(4)">
                  <option disabled="">- Select Option -</option>
                  <option
                    v-for="option in productData.modules[4]"
                    :value="{id: parseInt(option.id), code: option.code, name: option.name }"
                  >{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>
        </ul>
      </section>

      <section class="c-section" v-if="productData.slug != 'custom-product'">
        <div style="display:none;">{{ noTableTops = (this.isTwoSeat === 1 || this.isThreeSeat === 1) && (currProduct.moduleOne.length && currProduct.moduleOne[0].code != 'INL') && (currProduct.moduleThree.length && currProduct.moduleThree[0].code != 'INL') && (currProduct.moduleFive.length && currProduct.moduleFive[0].code != 'INL')}}</div>

        <h2 class="c-section__heading">Options</h2>
        <ul class="c-options-list">

          <li class="c-options-list__row" v-if="availableOptions.armHeights">
            <h3 class="c-options-list__heading">
              {{ productData.armHeightLabelOverride ? productData.armHeightLabelOverride : 'Arm Height' }}<br/>
              <small v-if="productData.armHeightHelperText">({{ productData.armHeightHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.armHeightLabelOverride ? productData.armHeightLabelOverride : 'Arm Height' }}<br/>
                <small v-if="productData.armHeightHelperText">({{ productData.armHeightHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.armHeight[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name, armYardage: option.armYardage}" v-for="(option, index) in productData.armHeights">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.finishType">
            <h3 class="c-options-list__heading">
                Finish Type
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                  Finish Type
              </label>
              <div class="c-select">
                <select id="finishType" name="finishType" v-model="currProduct.finishType">
                  <option value="woodFinish">Wood Finish</option>
                  <option value="paintedWood">Painted Wood</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && !availableOptions.legMaterials && !(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3' || productData.title == 'HK01-01' || productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && !this.isMaru() && !(availableOptions.finishType && currProduct.finishType === 'paintedWood')">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="woodFrameFinish" name="woodFrameFinish" v-model="currProduct.woodFinish[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="(option, index) in productData.woodFinishes" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WCW' && option.code !== 'WCB') && currProduct.species[0].code === 'A') || (availableOptions.species && option.code === 'WCW' && currProduct.species[0].code === 'W') || (availableOptions.species && option.code === 'WCB' && currProduct.species[0].code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.paintedWood && !(availableOptions.finishType && currProduct.finishType === 'woodFinish')">
            <h3 class="c-options-list__heading">
              {{ productData.paintedWoodLabelOverride ? productData.paintedWoodLabelOverride : 'Painted Wood' }}<br/>
              <small v-if="productData.paintedWoodHelperText">({{ productData.paintedWoodHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.paintedWoodLabelOverride ? productData.paintedWoodLabelOverride : 'Painted Wood' }}<br/>
                <small v-if="productData.paintedWoodHelperText">({{ productData.paintedWoodHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="paintedWoodFrameFinish" name="paintedWoodFrameFinish" v-model="currProduct.paintedWood[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.paintedWood">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.tableTopSizes">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopSizeLabelOverride ? productData.tableTopSizeLabelOverride : 'Table Top Size' }}<br/>
              <small v-if="productData.tableTopSizeHelperText">({{ productData.tableTopSizeHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopSizeLabelOverride ? productData.tableTopSizeLabelOverride : 'Table Top Size' }}<br/>
                <small v-if="productData.tableTopSizeHelperText">({{ productData.tableTopSizeHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.tableTopSize[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopSizes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.armCapMaterials">
            <h3 class="c-options-list__heading">
              {{ productData.armCapMaterialLabelOverride ? productData.armCapMaterialLabelOverride : 'Arm Cap Material' }}<br/>
              <small v-if="productData.armCapMaterialHelperText">({{ productData.armCapMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.armCapMaterialLabelOverride ? productData.armCapMaterialLabelOverride : 'Arm Cap Material' }}<br/>
                <small v-if="productData.armCapMaterialHelperText">({{ productData.armCapMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.armCapMaterial[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.armCapMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.corian.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.corian">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.armCapMaterial[0].code !== 'WAC'">
              <input class="c-input" type="text" id="armCapMaterialName" name="armCapMaterialName" placeholder="Arm Cap Material Name (Optional)" v-model="currProduct.armCapMaterialName" />
            </div>
            <div style="display: none;">{{ currProduct.armCapMaterialName = (currProduct.armCapMaterial[0].code !== 'WAC') ? currProduct.armCapMaterialName : '' }}</div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.pads" @change="padsDependencies">
            <h3 class="c-options-list__heading">
              {{ productData.padsLabelOverride ? productData.padsLabelOverride : 'Pads' }}<br/>
              <small v-if="productData.padsHelperText">({{ productData.padsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.padsLabelOverride ? productData.padsLabelOverride : 'Pads' }}<br/>
                <small v-if="productData.padsHelperText">({{ productData.padsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.pads[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name, padYardage: option.padYardage}" v-for="option in productData.pads">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.tables">
            <h3 class="c-options-list__heading">
              {{ productData.tablesLabelOverride ? productData.tablesLabelOverride : 'Tables' }}<br/>
              <small v-if="productData.tablesHelperText">({{ productData.tablesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tablesLabelOverride ? productData.tablesLabelOverride : 'Tables' }}<br/>
                <small v-if="productData.tablesHelperText">({{ productData.tablesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.tables[0]" :disabled="((productData.title == 'SG03-IND-S5-86W-BC2-BG4' || productData.title == 'SG03-IND-S5-86W-BC4-BG2') && currProduct.powerModules[0].code !== 'XX')" @change="tablesVsPower">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tables">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.partitions">
            <h3 class="c-options-list__heading">
              {{ productData.partitionsLabelOverride ? productData.partitionsLabelOverride : 'Partitions' }}<br/>
              <small v-if="productData.partitionsHelperText">({{ productData.partitionsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.partitionsLabelOverride ? productData.partitionsLabelOverride : 'Partitions' }}<br/>
                <small v-if="productData.partitionsHelperText">({{ productData.partitionsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.partitions[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.partitions" v-if="!availableOptions.species || (availableOptions.species && (option.code === 'XX' || (option.code === 'SCR1B' && currProduct.species[0].code === 'B') || (option.code === 'SCR1A' && currProduct.species[0].code === 'A') || (option.code === 'SCR1W' && currProduct.species[0].code === 'W') || (option.code === 'SCR1OM' && currProduct.species[0].code === 'OM')))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.tableTopMaterials && !isMaru()) || (isMaru() && ((availableOptions.tableTopMaterials && !availableOptions.tables) || (availableOptions.tableTopMaterials && availableOptions.tables && currProduct.tables[0].code != 'XX')))">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialLabelOverride ? productData.tableTopMaterialLabelOverride : 'Table Top Material' }}<br/>
              <small v-if="productData.tableTopMaterialHelperText">({{ productData.tableTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialLabelOverride ? productData.tableTopMaterialLabelOverride : 'Table Top Material' }}<br/>
                <small v-if="productData.tableTopMaterialHelperText">({{ productData.tableTopMaterialHelperText }})</small>
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="currProduct.tableTopMaterial[0]" :disabled="noTableTops" @change="speciesDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.tableTopMaterials" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WTW' && option.code !== 'VTW' && option.code !== 'VTB' && option.code !== 'WTB') && currProduct.species[0].code === 'A') || (availableOptions.species && (option.code === '' && option.code === 'VTW') && currProduct.species[0].code === 'W') || (availableOptions.species && (option.code !== 'WTA' && option.code !== 'VTA' && option.code !== 'VTB' && option.code !== 'WTB') && currProduct.species[0].code === 'W') || (availableOptions.species && (option.code === 'WTA' && option.code === 'VTA') && currProduct.species[0].code === 'A') || (availableOptions.species && (option.code === 'VTB' || option.code === 'FTT' || option.code === 'WTB') && currProduct.species[0].code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small v-if="(productData.tableTopMaterials && noTableTops === false)" class="c-select--more-info">
                <div v-for="(material, index) in productData.tableTopMaterials" v-if="material.code == 'FTT' && pricelists.forbo.length">
                    <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
                </div>

                <div v-for="(material, index) in productData.tableTopMaterials" v-if="material.code == 'CT1' && pricelists.corian.length">
                    <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.corian">Browse {{ option.title }}</a>
                </div>

                <div v-for="(material, index) in productData.tableTopMaterials" v-if="material.code == 'LTT' && pricelists.laminates.length">
                    <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.laminates">Browse {{ option.title }}</a>
                </div>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.tableTopMaterial[0].code !== 'WTT' && currProduct.tableTopMaterial[0].code !== 'WTA' && currProduct.tableTopMaterial[0].code !== 'WTB' && currProduct.tableTopMaterial[0].code !== 'WTW' && currProduct.tableTopMaterial[0].code !== 'VTA' && currProduct.tableTopMaterial[0].code !== 'VTW' && currProduct.tableTopMaterial[0].code !== 'VTB' && currProduct.tableTopMaterial[0].code !== 'UPH' && currProduct.tableTopMaterial[0].code !== 'FTT' && currProduct.tableTopMaterial[0].code !== 'MTT' && !isBSide()">
              <input class="c-input" type="text" id="tableTopMaterialName" name="tableTopMaterialName" placeholder="Table Top Material Name (Optional)" v-model="currProduct.tableTopMaterialName" :disabled="noTableTops" />
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.tableTopMaterial[0].code === 'FTT' && productData.tableTopMaterialForboFinishes.length && !isBSide()">
                <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                    <select v-model="currProduct.tableTopMaterialForboFinish[0]" :disabled="noTableTops">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div style="display: none;">{{ currProduct.tableTopMaterialName = (currProduct.tableTopMaterial[0].code !== 'WTT' && currProduct.tableTopMaterial[0].code !== 'WTA' && currProduct.tableTopMaterial[0].code !== 'WTW' && currProduct.tableTopMaterial[0].code !== 'VTA' && currProduct.tableTopMaterial[0].code !== 'VTW' && currProduct.tableTopMaterial[0].code !== 'VTB' && currProduct.tableTopMaterial[0].code !== 'UPH' && currProduct.tableTopMaterial[0].code !== 'FTT' && currProduct.tableTopMaterial[0].code !== 'WTB') ? currProduct.tableTopMaterialName : '' }}</div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.tableTopMaterialFinishesSideA && availableOptions.tableTopMaterials)">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialFinishSideALabelOverride ? productData.tableTopMaterialFinishSideALabelOverride : ((currProduct.tableTopMaterial[0].code === 'FTT' || currProduct.tableTopMaterial[0].code === 'LTT') ? 'Table Top Finish - Side A' : 'Table Top Finish') }}
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialFinishSideALabelOverride ? productData.tableTopMaterialFinishSideALabelOverride : ((currProduct.tableTopMaterial[0].code === 'FTT' || currProduct.tableTopMaterial[0].code === 'LTT') ? 'Table Top Finish - Side A' : 'Table Top Finish') }}<br/>
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="currProduct.tableTopMaterialFinishSideA[0]" :disabled="noTableTops">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialFinishesSideA" v-if="(currProduct.tableTopMaterial[0].code === 'VTA' && isAshFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'VTW' && isWalnutFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'FTT' && isForboFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'SST' && isSolidSurfaceFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'CTT' && isCorkFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'RTT' && isReallyFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'LTT' && isLaminateFinish(option.code))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.tableTopMaterialFinishesSideB && (availableOptions.tableTopMaterials && (currProduct.tableTopMaterial[0].code === 'FTT' || currProduct.tableTopMaterial[0].code === 'LTT')))">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialFinishSideBLabelOverride ? productData.tableTopMaterialFinishSideBLabelOverride : 'Table Top Finish - Side B' }}
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialFinishSideBLabelOverride ? productData.tableTopMaterialFinishSideBLabelOverride : 'Table Top Finish - Side B' }}<br/>
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="currProduct.tableTopMaterialFinishSideB[0]" :disabled="noTableTops">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialFinishesSideB" v-if="(currProduct.tableTopMaterial[0].code === 'VTA' && isAshFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'VTW' && isWalnutFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'FTT' && isForboFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'SST' && isSolidSurfaceFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'CTT' && isCorkFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'RTT' && isReallyFinish(option.code)) || (currProduct.tableTopMaterial[0].code === 'LTT' && isLaminateFinish(option.code))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.tableTopMaterialMetalFinishes">
            <h3 class="c-options-list__heading">
              {{ productData.tableTopMaterialMetalFinishLabelOverride ? productData.tableTopMaterialMetalFinishLabelOverride : 'Metal Finishes' }}
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.tableTopMaterialMetalFinishLabelOverride ? productData.tableTopMaterialMetalFinishLabelOverride : 'Metal Finishes' }}
              </label>
              <div :class="[{ 'c-select -is-disabled' : noTableTops },'c-select']">
                <select v-model="currProduct.tableTopMaterialMetalFinish[0]" :disabled="currProduct.tableTopMaterial[0].code !== 'MTT'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.tableTopMaterialMetalFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.metal.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.metal">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && !availableOptions.legMaterials && !(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3' || productData.title == 'HK01-01' || productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && this.isMaru()">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="woodFrameFinish" name="woodFrameFinish" v-model="currProduct.woodFinish[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WCW' && option.code !== 'WCB') && currProduct.species[0].code === 'A') || (availableOptions.species && option.code === 'WCW' && currProduct.species[0].code === 'W') || (availableOptions.species && option.code === 'WCB' && currProduct.species[0].code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && !availableOptions.legMaterials && (productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && currProduct.tableTopMaterial[0].code != 'FTT'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="woodFrameFinish" name="woodFrameFinish" v-model="currProduct.woodFinish[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="(option.code !== 'WCW' && currProduct.tableTopMaterial[0].code === 'VTA') || (option.code === 'WCW' && currProduct.tableTopMaterial[0].code === 'VTW')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.powerModules">
            <h3 class="c-options-list__heading">
              {{ productData.powerModulesLabelOverride ? productData.powerModulesLabelOverride : '(No. of) Power Modules' }}<br/>
              <small v-if="productData.powerModulesHelperText">({{ productData.powerModulesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.powerModulesLabelOverride ? productData.powerModulesLabelOverride : '(No. of) Power Modules' }}<br/>
                <small v-if="productData.powerModulesHelperText">({{ productData.powerModulesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.powerModules[0]" :disabled="((productData.title == 'SG03-IND-S5-86W-BC2-BG4' || productData.title == 'SG03-IND-S5-86W-BC4-BG2') && currProduct.tables[0].code !== 'XX')">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.powerModules" v-if="!(option.code === 'PM2' && productData.name === 'Maru 3-slat, 3/4-length Indoor Configuration 3' && currProduct.tables[0].code !== 'XX')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.multTextilesOption === true || availableOptions.textileBasicOptions === true) && (productData.title != 'KN01-S1' || (productData.title == 'KN01-S1' && currProduct.tableTopMaterial[0].code == 'UPH'))">

            <div class="c-options-list__multiple-headings">
              <h3 class="c-options-list__heading" v-if="availableOptions.multTextilesOption === true">Textile Configuration</h3>

              <label for="armTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AT'">
                Arm <small>(Total Yardage: {{ (productData.armHeights.length ? parseFloat(currProduct.armHeight[0].armYardage).toFixed(2) : productData.yardage.arm) }} yards)</small>
              </label>
              <label for="armBackTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'ST'">
                Arm, Back <small>(Total Yardage: {{ (productData.armHeights.length ? (parseFloat(currProduct.armHeight[0].armYardage) + parseFloat(productData.yardage.back)).toFixed(2) : parseFloat(productData.yardage.arm) + parseFloat(productData.yardage.back)) }} yards)</small>
              </label>
              <label for="armSeatTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'BT'">
                Arm, Seat <small>(Total Yardage: {{ (productData.armHeights.length ? (parseFloat(currProduct.armHeight[0].armYardage) + parseFloat(productData.yardage.seat)).toFixed(2) : parseFloat(productData.yardage.arm) + parseFloat(productData.yardage.seat)) }} yards)</small>
              </label>
              <label for="backTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && (currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption[0].code === 'BT')">
                Back <small>(Total Yardage: {{ productData.yardage.back }} yards)</small>
              </label>
              <label for="seatTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && (currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption[0].code === 'ST')">
                Seat <small>(Total Yardage: {{ (productData.pads.length ? (currProduct.pads[0].code !== 'XX' ? (parseFloat(currProduct.pads[0].padYardage)).toFixed(2) : productData.yardage.seat) : productData.yardage.seat) }} yards)</small>
              </label>
              <label for="seatBackTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AT'">
                Seat, Back <small>(Total Yardage: {{ parseFloat(productData.yardage.back) + parseFloat(productData.yardage.seat) }} yards)</small>
              </label>
              <label for="sideATextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AB'">
                Side A <small>(Total Yardage: {{ parseFloat(productData.yardage.sideA) }} yards)</small>
              </label>
              <label for="sideBTextileGrade" class="single-heading" v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AB'">
                Side B <small>(Total Yardage: {{ parseFloat(productData.yardage.sideB) }} yards)</small>
              </label>
              <label for="singleTextileGrade" class="single-heading" v-if="(availableOptions.multTextilesOption && currProduct.multTextilesOption[0].code === 'XX') || availableOptions.multTextilesOption === false">
                {{ productData.textileLabelOverride ? productData.textileLabelOverride : 'Textile' }} <small>(Total Yardage: {{ moduleYardage > 0 ? moduleYardage : (productData.armHeights.length ? (parseFloat(currProduct.armHeight[0].armYardage) + parseFloat(productData.yardage.seat) + parseFloat(productData.yardage.back)).toFixed(2) : (productData.pads.length ? (currProduct.pads[0].code !== 'XX' ? (parseFloat(currProduct.pads[0].padYardage) + parseFloat(productData.yardage.seat) + parseFloat(productData.yardage.back)).toFixed(2) : productData.yardage.total) : productData.yardage.total)) }} yards)</small><br/>
                <small v-if="productData.textileHelperText">({{ productData.textileHelperText }})</small>
              </label>
            </div>

            <div class="c-options-list__input-group">
              <label for="multTextilesOption" class="u-hidden-visually">Textile Configuration</label>
              <div v-if="availableOptions.multTextilesOption === true" class="c-select">
                <select id="multTextilesOption" name="multTextilesOption" v-model="currProduct.multTextilesOption[0]" @change="textileConditions">
                  <option disabled="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in productData.multTextilesOption">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AT'">
                <br/>
                <div class="c-select">
                  <select id="armTextileGrade" name="armTextileGrade" v-model="currProduct.armTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.armTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'ST'">
                <br/>
                <div class="c-select">
                  <select id="armBackTextileGrade" name="armBackTextileGrade" v-model="currProduct.armBackTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.armBackTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'BT'" @change="textileDependencies">
                <br/>
                <div class="c-select">
                  <select id="armSeatTextileGrade" name="armSeatTextileGrade" v-model="currProduct.armSeatTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.armSeatTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption[0].code === 'BT')">
                <br/>
                <div class="c-select">
                  <select id="backTextileGrade" name="backTextileGrade" v-model="currProduct.backTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.backTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption[0].code === 'ST')">
                <br/>
                <div class="c-select">
                  <select id="seatTextileGrade" name="seatTextileGrade" v-model="currProduct.seatTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.seatTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AT'">
                <br/>
                <div class="c-select">
                  <select id="seatBackTextileGrade" name="seatBackTextileGrade" v-model="currProduct.seatBackTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.seatBackTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AB'">
                <br/>
                <div class="c-select">
                  <select id="sideATextileGrade" name="sideATextileGrade" v-model="currProduct.sideATextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.sideATextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AB'">
                <br/>
                <div class="c-select">
                  <select id="sideBTextileGrade" name="sideBTextileGrade" v-model="currProduct.sideBTextileGrade[0]" @change="textileDependencies">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.sideBTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <div v-if="(availableOptions.multTextilesOption && currProduct.multTextilesOption[0].code === 'XX') || availableOptions.multTextilesOption === false">
                <br/>
                <div class="c-select">
                  <select id="singleTextileGrade" name="singleTextileGrade" v-model="currProduct.singleTextileGrade[0]" :disabled="availableOptions.multTextilesOption && currProduct.multTextilesOption[0].code !== 'XX'">
                    <option disabled="">- Select Option -</option>
                    <option :value="{id: parseInt(option.id), code: option.code, name: option.name}" v-for="option in textileData.grades">{{ option.name }}</option>
                  </select>
                </div>
                <small v-if="currProduct.singleTextileGrade[0].code === 'GRCOM'">Yardage listed is for non-patterned fabric of a bolt with greater than 54". For other textiles, please contact us.</small>
              </div>
              <small class="c-select--more-info" v-if="pricelists.textiles.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.textiles">Browse {{ option.title }}</a>
              </small>
            </div>

            <div class="c-options-list__input-group">
              <div class="c-select" v-if="availableOptions.multTextilesOption === true" style="visibility: hidden;">
                <select></select>
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AT'">
                <br/>
                <input class="c-input" type="text" id="armTextileGradeName" name="armTextileGradeName" placeholder="Arm Textile Name (Optional)" v-model="currProduct.armTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'ST'">
                <br/>
                <input class="c-input" type="text" id="armBackTextileGradeName" name="armBackTextileGradeName" placeholder="Arm, Back Textile Name (Optional)" v-model="currProduct.armBackTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'BT'">
                <br/>
                <input class="c-input" type="text" id="armSeatTextileGradeName" name="armSeatTextileGradeName" placeholder="Arm, Seat Textile Name (Optional)" v-model="currProduct.armSeatTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption[0].code === 'BT')">
                <br/>
                <input class="c-input" type="text" id="backTextileGradeName" name="backTextileGradeName" placeholder="Back Textile Name (Optional)" v-model="currProduct.backTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && (currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption[0].code === 'ST')">
                <br/>
                <input class="c-input" type="text" id="seatTextileGradeName" name="seatTextileGradeName" placeholder="Seat Textile Name (Optional)" v-model="currProduct.seatTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AT'">
                <br/>
                <input class="c-input" type="text" id="seatBackTextileGradeName" name="seatBackTextileGradeName" placeholder="Seat, Back Textile Name (Optional)" v-model="currProduct.seatBackTextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AB'">
                <br/>
                <input class="c-input" type="text" id="sideATextileGradeName" name="sideATextileGradeName" placeholder="Side A Textile Name (Optional)" v-model="currProduct.sideATextileGradeName" />
              </div>
              <div v-if="availableOptions.multTextilesOption === true && currProduct.multTextilesOption[0].code === 'AB'">
                <br/>
                <input class="c-input" type="text" id="sideBTextileGradeName" name="sideBTextileGradeName" placeholder="Side B Textile Name (Optional)" v-model="currProduct.sideBTextileGradeName" />
              </div>
              <div v-if="(availableOptions.multTextilesOption && currProduct.multTextilesOption[0].code === 'XX') || availableOptions.multTextilesOption === false">
                <br/>
                <input class="c-input" type="text" id="singleTextileGradeName" name="singleTextileGradeName" placeholder="Textile Name (Optional)" :disabled="availableOptions.multTextilesOption && currProduct.multTextilesOption[0].code !== 'XX'" v-model="currProduct.singleTextileGradeName" />
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.largeInsertTopMaterials">
            <h3 class="c-options-list__heading">
              {{ productData.largeInsertTopMaterialLabelOverride ? productData.largeInsertTopMaterialLabelOverride : 'Large Insert Top Material' }}<br/>
              <small v-if="productData.largeInsertTopMaterialHelperText">({{ productData.largeInsertTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.largeInsertTopMaterialLabelOverride ? productData.largeInsertTopMaterialLabelOverride : 'Large Insert Top Material' }}<br/>
                <small v-if="productData.largeInsertTopMaterialHelperText">({{ productData.largeInsertTopMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="largeInsertTopMaterial" name="largeInsertTopMaterial" v-model="currProduct.largeInsertTopMaterial[0]" @change="insertDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.largeInsertTopMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="currProduct.largeInsertTopMaterial[0].code == 'FTL' && pricelists.forbo.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
              </small>
              <small class="c-select--more-info" v-if="currProduct.largeInsertTopMaterial[0].code == 'SSL' && pricelists.solidSurface.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.solidSurface">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.largeInsertTopMaterial[0].code == 'FTL' && currProduct.largeInsertTopMaterialForboFinish.length">
                <div class="c-select">
                    <select v-model="currProduct.largeInsertTopMaterialForboFinish[0]">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.largeInsertTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.largeInsertTopMaterial[0].code == 'SSL' && currProduct.largeInsertTopMaterialSolidSurfaceFinish.length">
                <div class="c-select">
                    <select v-model="currProduct.largeInsertTopMaterialSolidSurfaceFinish[0]">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.largeInsertTopMaterialSolidSurfaceFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.smallInsertTopMaterials && currProduct.baseCode === 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
              <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
                <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="smallInsertTopMaterial" name="smallInsertTopMaterial" v-model="currProduct.smallInsertTopMaterial[0]" @change="idaShelfDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="currProduct.smallInsertTopMaterial[0].code == 'FTS' && pricelists.forbo.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
              </small>
              <small class="c-select--more-info" v-if="currProduct.smallInsertTopMaterial[0].code == 'SSS' && pricelists.solidSurface.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.solidSurface">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.smallInsertTopMaterial[0].code == 'FTS' && currProduct.smallInsertTopMaterialForboFinish.length">
                <div class="c-select">
                    <select v-model="currProduct.smallInsertTopMaterialForboFinish[0]">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.smallInsertTopMaterial[0].code == 'SSS' && currProduct.smallInsertTopMaterialSolidSurfaceFinish.length">
                <div class="c-select">
                    <select v-model="currProduct.smallInsertTopMaterialSolidSurfaceFinish[0]">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialSolidSurfaceFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.smallInsertTopMaterials && currProduct.baseCode !== 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
              <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.smallInsertTopMaterialLabelOverride ? productData.smallInsertTopMaterialLabelOverride : 'Small Insert Top Material' }}<br/>
                <small v-if="productData.smallInsertTopMaterialHelperText">({{ productData.smallInsertTopMaterialHelperText }})</small>
              </label>
              <div class="c-select">
                <select id="smallInsertTopMaterial" name="smallInsertTopMaterial" v-model="currProduct.smallInsertTopMaterial[0]" @change="insertDependencies">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="currProduct.smallInsertTopMaterial[0].code == 'FTS' && pricelists.forbo.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.forbo">Browse {{ option.title }}</a>
              </small>
              <small class="c-select--more-info" v-if="currProduct.smallInsertTopMaterial[0].code == 'SSS' && pricelists.solidSurface.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.solidSurface">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.smallInsertTopMaterial[0].code == 'FTS' && currProduct.smallInsertTopMaterialForboFinish.length">
                <div class="c-select">
                    <select v-model="currProduct.smallInsertTopMaterialForboFinish[0]">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialForboFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
            <div class="c-options-list__input-group" v-if="currProduct.smallInsertTopMaterial[0].code == 'SSS' && currProduct.smallInsertTopMaterialSolidSurfaceFinish.length">
                <div class="c-select">
                    <select v-model="currProduct.smallInsertTopMaterialSolidSurfaceFinish[0]">
                      <option disabled="" selected="">- Select Option -</option>
                      <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.smallInsertTopMaterialSolidSurfaceFinishes">{{ option.code }} - {{ option.name }}</option>
                    </select>
                </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.caseworkSurfaceMaterials">
            <h3 class="c-options-list__heading">
              {{ productData.caseworkSurfaceMaterialsLabelOverride ? productData.caseworkSurfaceMaterialsLabelOverride : 'Case Surface Material' }}<br/>
              <small v-if="productData.caseworkSurfaceMaterialsHelperText">({{ productData.caseworkSurfaceMaterialsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.caseworkSurfaceMaterialsLabelOverride ? productData.caseworkSurfaceMaterialsLabelOverride : 'Case Surface Material' }}<br/>
                <small v-if="productData.caseworkSurfaceMaterialsHelperText">({{ productData.caseworkSurfaceMaterialsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.caseworkSurfaceMaterial[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.caseworkSurfaceMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.corian.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.corian">Browse {{ option.title }}</a>
              </small>
            </div>
            <div class="c-options-list__input-group">
              <input class="c-input" type="text" id="caseworkSurfaceMaterialName" name="caseworkSurfaceMaterialName" placeholder="Case Surface Material Name (Optional)" v-model="currProduct.caseworkSurfaceMaterialName" />
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.columnShroudPaints">
            <h3 class="c-options-list__heading">
              {{ productData.colummnShroudPaintLabelOverride ? productData.columnShroudPaintLabelOverride : 'Column Shroud Paint' }}<br/>
              <small v-if="productData.columnShroudPaintHelperText">({{ productData.columnShroudPaintHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.columnShroudPaintLabelOverride ? productData.columnShroudPaintLabelOverride : 'Column Shroud Paint' }}<br/>
                <small v-if="productData.columnShroudPaintHelperText">({{ productData.columnShroudPaintHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.columnShroudPaint[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.columnShroudPaints">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.legMaterials && productData.title !== 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
              <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
                <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.legMaterial[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.legMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3')">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.woodFinish[0]" :disabled="currProduct.largeInsertTopMaterial[0].code !== 'VAL' && currProduct.largeInsertTopMaterial[0].code !== 'VWL' && currProduct.smallInsertTopMaterial[0].code !== 'VAS' && currProduct.smallInsertTopMaterial[0].code !== 'VWS'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="(currProduct.largeInsertTopMaterial[0].code === 'FTL' || currProduct.largeInsertTopMaterial[0].code === 'SSL' && (currProduct.smallInsertTopMaterial[0].code === 'FTS' || currProduct.smallInsertTopMaterial[0].code === 'SSS' || currProduct.smallInsertTopMaterial[0].code === 'STS' || currProduct.smallInsertTopMaterial[0].code === 'XXS')) || (option.code !== 'WCW' && (currProduct.largeInsertTopMaterial[0].code === 'VAL' || currProduct.smallInsertTopMaterial[0].code === 'VAS')) || (option.code === 'WCW' && (currProduct.largeInsertTopMaterial[0].code === 'VWL' || currProduct.smallInsertTopMaterial[0].code === 'VWS'))">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && productData.title == 'HK01-01'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.woodFinish[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="!availableOptions.species || (availableOptions.species && (option.code !== 'WCW' && option.code !== 'WCB') && currProduct[0].species.code === 'A') || (availableOptions.species && option.code === 'WCW' && currProduct.species[0].code === 'W') || (availableOptions.species && option.code === 'WCB' && currProduct.species[0].code === 'B')">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && availableOptions.smallInsertTopMaterials && productData.title === 'NC01-T1'">
              <h3 class="c-options-list__heading">
                  {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                  <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </h3>
              <div class="c-options-list__input-group">
                  <label for="" class="u-hidden-visually">
                      {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                      <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
                  </label>
                  <div class="c-select">
                      <select v-model="currProduct.woodFinish[0]" :disabled="currProduct.smallInsertTopMaterial[0].code !== 'VAS' && currProduct.smallInsertTopMaterial[0].code !== 'VWS' && currProduct.smallInsertTopMaterial[0].code !== 'SAS' && currProduct.smallInsertTopMaterial[0].code !== 'SWS'">
                          <option disabled="" selected="">- Select Option -</option>
                          <option :value="{id: option.id, code: option.code, name: option.name}" v-for="(option, index) in productData.woodFinishes" v-if="(currProduct.smallInsertTopMaterial[0].code !== 'VAS' && currProduct.smallInsertTopMaterial[0].code !== 'VWS' && currProduct.smallInsertTopMaterial[0].code !== 'SAS' && currProduct.smallInsertTopMaterial[0].code !== 'SWS') || ((currProduct.smallInsertTopMaterial[0].code === 'VAS' || currProduct.smallInsertTopMaterial[0].code === 'SAS') && (option.code !== 'WCW' && option.code !== 'WCB')) || ((currProduct.smallInsertTopMaterial[0].code === 'VWS' || currProduct.smallInsertTopMaterial[0].code === 'SWS') && option.code === 'WCW')">{{ option.code }} - {{ option.name }}</option>
                      </select>
                  </div>
                  <small class="c-select--more-info" v-if="pricelists.wood.length">
                  <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
                  </small>
              </div>
          </li>


          <li class="c-options-list__row" v-if="availableOptions.smallInsertTopMaterials && availableOptions.stoneQuartzTableTops">
            <h3 class="c-options-list__heading">
              {{ productData.stoneQuartzTableTopLabelOverride ? productData.stoneQuartzTableTopLabelOverride : 'Stone/Quartz Finishes' }}<br/>
              <small v-if="productData.stoneQuartzTableTopHelperText">({{ productData.stoneQuartzTableTopHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.stoneQuartzTableTopLabelOverride ? productData.stoneQuartzTableTopLabelOverride : 'Stone/Quartz Finishes' }}<br/>
                <small v-if="productData.stoneQuartzTableTopHelperText">({{ productData.stoneQuartzTableTopHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.stoneQuartzTableTop[0]" :disabled="currProduct.smallInsertTopMaterial[0].code !== 'STS'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.stoneQuartzTableTops">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.legMaterials && productData.title === 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
              <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.legMaterialsLabelOverride ? productData.legMaterialsLabelOverride : 'Leg Material' }}<br/>
                <small v-if="productData.legMaterialsHelperText">({{ productData.legMaterialsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.legMaterial[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.legMaterials">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="(availableOptions.metalFinishes && availableOptions.legMaterials) || (availableOptions.metalFinishes && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3'))">
            <h3 class="c-options-list__heading">
              {{ productData.metalFinishesLabelOverride ? productData.metalFinishesLabelOverride : 'Metal Finishes' }}<br/>
              <small v-if="productData.metalFinishesHelperText">({{ productData.metalFinishesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.metalFinishesLabelOverride ? productData.metalFinishesLabelOverride : 'Metal Finishes' }}<br/>
                <small v-if="productData.metalFinishesHelperText">({{ productData.metalFinishesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.metalFinish[0]" :disabled="!(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3') && currProduct.legMaterial[0].code === 'LW'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="(option, index) in productData.metalFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.metal.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.metal">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.metalFinishes && (productData.title == 'HK01-01' || productData.title == 'HK01-09' || productData.title == 'HK01-11' || isBSide())">
            <h3 class="c-options-list__heading">
              {{ productData.metalFinishesLabelOverride ? productData.metalFinishesLabelOverride : 'Metal Finishes' }}<br/>
              <small v-if="productData.metalFinishesHelperText">({{ productData.metalFinishesHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.metalFinishesLabelOverride ? productData.metalFinishesLabelOverride : 'Metal Finishes' }}<br/>
                <small v-if="productData.metalFinishesHelperText">({{ productData.metalFinishesHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.metalFinish[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="(option, index) in productData.metalFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.metal.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.metal">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.swivelBaseTypes">
            <h3 class="c-options-list__heading">
              {{ productData.swivelBaseTypeLabelOverride ? productData.swivelBaseTypeLabelOverride : 'Swivel Base Types' }}<br/>
              <small v-if="productData.swivelBaseTypeHelperText">({{ productData.swivelBaseTypeHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.swivelBaseTypeLabelOverride ? productData.swivelBaseTypeLabelOverride : 'Swivel Base Types' }}<br/>
                <small v-if="productData.swivelBaseTypeHelperText">({{ productData.swivelBaseTypeHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.swivelBaseType[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.swivelBaseTypes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.leatherArmWraps">
            <h3 class="c-options-list__heading">
              {{ productData.leatherArmWrapLabelOverride ? productData.leatherArmWrapLabelOverride : 'Leather Arm Wraps' }}<br/>
              <small v-if="productData.leatherArmWrapHelperText">({{ productData.leatherArmWrapHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.leatherArmWrapLabelOverride ? productData.leatherArmWrapLabelOverride : 'Leather Arm Wraps' }}<br/>
                <small v-if="productData.leatherArmWrapHelperText">({{ productData.leatherArmWrapHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.leatherArmWrap[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: option.id, code: option.code, name: option.name}" v-for="option in productData.leatherArmWraps">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.leather.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.leather">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.woodFinishes && availableOptions.legMaterials && productData.title !== 'NC01-T1'">
            <h3 class="c-options-list__heading">
              {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
              <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.woodFinishLabelOverride ? productData.woodFinishLabelOverride : 'Wood Finishes' }}<br/>
                <small v-if="productData.woodFinishHelperText">({{ productData.woodFinishHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.woodFinish[0]" :disabled="currProduct.legMaterial[0].code === 'LM'">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="(option, index) in productData.woodFinishes">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
              <small class="c-select--more-info" v-if="pricelists.wood.length">
                <a :href="option.link" class="pdf-link" target="_blank" :title="option.code" v-for="(option, index) in pricelists.wood">Browse {{ option.title }}</a>
              </small>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.moistureBarrier">
            <h3 class="c-options-list__heading">Moisture Barrier</h3>
            <div class="c-options-list__input-group">
              <label for="moistureBarrier" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="moistureBarrier" name="moistureBarrier" value="1" v-model="currProduct.moistureBarrier" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.removeableCovers">
            <h3 class="c-options-list__heading">Removeable Covers</h3>
            <div class="c-options-list__input-group">
              <label for="removeableCovers" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="removeableCovers" name="removeableCovers" value="1" v-model="currProduct.removeableCovers" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.calTb133">
            <h3 class="c-options-list__heading">CAL TB 133</h3>
            <div class="c-options-list__input-group">
              <label for="calTb133" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="calTb133" name="calTb133" value="1" v-model="currProduct.calTb133" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.casters">
            <h3 class="c-options-list__heading">Casters</h3>
            <div class="c-options-list__input-group">
              <label for="casters" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="casters" name="casters" value="1" v-model="currProduct.casters" :true-value="1" :false-value="0" :disabled="availableOptions.legMaterials && currProduct.legMaterial[0].code === 'LW'">
                <span class="c-checkbox__indicator"></span>
              </label>
              <div style="display:none;">{{ currProduct.casters = (availableOptions.legMaterials && currProduct.legMaterial[0].code === 'LW') ? 0 : currProduct.casters }}</div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.removeableArmPads">
            <h3 class="c-options-list__heading">Removeable Arm Pads (Pair)</h3>
            <div class="c-options-list__input-group">
              <label for="removeableArmPads" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="removeableArmPads" name="removeableArmPads" value="1" v-model="currProduct.removeableArmPads" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.hangingFixture">
            <h3 class="c-options-list__heading">Hanging Fixture</h3>
            <div class="c-options-list__input-group">
              <label for="hangingFixture" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="hangingFixture" name="hangingFixture" value="1" v-model="currProduct.hangingFixture" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="productData.hinges[0]">
            <h3 class="c-options-list__heading">Hinge Setup #1</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Hinge Setup #1</label>
              <div class="c-select">
                <select v-model="currProduct.hingesOne[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.hinges[0]">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="productData.hinges[1]">
            <h3 class="c-options-list__heading">Hinge Setup #2</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Hinge Setup #2</label>
              <div class="c-select">
                <select v-model="currProduct.hingesTwo[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.hinges[1]">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="productData.hinges[2]">
            <h3 class="c-options-list__heading">Hinge Setup #3</h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">Hinge Setup #3</label>
              <div class="c-select">
                <select v-model="currProduct.hingesThree[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.hinges[2]">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.pulls">
            <h3 class="c-options-list__heading">
              {{ productData.pullsLabelOverride ? productData.pullsLabelOverride : 'Pulls' }}<br/>
              <small v-if="productData.pullsHelperText">({{ productData.pullsHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.pullsLabelOverride ? productData.pullsLabelOverride : 'Pulls' }}<br/>
                <small v-if="productData.pullsHelperText">({{ productData.pullsHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.pull[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.pulls">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
            <div class="c-options-list__input-group">
              <figure class="c-options-list__line-art">
                <img :src="fetchPullsLineArt" alt="Pulls line art">
              </figure>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.wraps">
            <h3 class="c-options-list__heading">
              {{ productData.wrapLabelOverride ? productData.wrapLabelOverride : 'Wrap' }}<br/>
              <small v-if="productData.wrapHelperText">({{ productData.wrapHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.wrapLabelOverride ? productData.wrapLabelOverride : 'Wrap' }}<br/>
                <small v-if="productData.wrapHelperText">({{ productData.wrapHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.wrap[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.wraps">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.drawerLiner">
            <h3 class="c-options-list__heading">Drawer Liner</h3>
            <div class="c-options-list__input-group">
              <label for="drawerLiner" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="drawerLiner" name="drawerLiner" value="1" v-model="currProduct.drawerLiner" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.veneerOuterCase">
            <h3 class="c-options-list__heading">
              {{ productData.veneerOuterCaseLabelOverride ? productData.veneerOuterCaseLabelOverride : 'Veneer Outer Case' }}<br/>
              <small v-if="productData.veneerOuterCaseHelperText">({{ productData.veneerOuterCaseHelperText }})</small>
            </h3>
            <div class="c-options-list__input-group">
              <label for="" class="u-hidden-visually">
                {{ productData.veneerOuterCaseLabelOverride ? productData.veneerOuterCaseLabelOverride : 'Veneer Outer Case' }}<br/>
                <small v-if="productData.veneerOuterCaseHelperText">({{ productData.veneerOuterCaseHelperText }})</small>
              </label>
              <div class="c-select">
                <select v-model="currProduct.veneerOuterCase[0]">
                  <option disabled="" selected="">- Select Option -</option>
                  <option :value="{id: parseInt(option.id), code: option.code, name: option.name }" v-for="option in productData.veneerOuterCase">{{ option.code }} - {{ option.name }}</option>
                </select>
              </div>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.paperTowelDispenser">
            <h3 class="c-options-list__heading">Paper Towerl Dispenser Cut-Out</h3>
            <div class="c-options-list__input-group">
              <label for="paperTowelDispenser" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="paperTowelDispenser" name="paperTowelDispenser" value="1" v-model="currProduct.paperTowelDispenser" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.extraAdjustableShelf">
            <h3 class="c-options-list__heading">Extra Adustable Shelf</h3>
            <div class="c-options-list__input-group">
              <label for="extraAdjustableShelf" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="extraAdjustableShelf" name="extraAdjustableShelf" value="1" v-model="currProduct.extraAdjustableShelf" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.fileHolders">
            <h3 class="c-options-list__heading">File Holders</h3>
            <div class="c-options-list__input-group">
              <label for="fileHolders" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="fileHolders" name="fileHolders" value="1" v-model="currProduct.fileHolders" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>

          <li class="c-options-list__row" v-if="availableOptions.gloveCutOuts">
            <h3 class="c-options-list__heading">Glove Cut Outs</h3>
            <div class="c-options-list__input-group">
              <label for="gloveCutOuts" class="c-checkbox">
                <input class="c-checkbox__input" type="checkbox" id="gloveCutOuts" name="gloveCutOuts" value="1" v-model="currProduct.gloveCutOuts" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>
          </li>
        </ul>
      </section>

      <section class="c-section" v-if="productData.slug == 'custom-product'">

        <h2 class="c-section__heading">Product Details</h2>
        <ul class="c-options-list">

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Product Name</h3>
            <div class="c-options-list__input-group">
              <label for="productName" class="c-label u-hidden-visually">Product Name</label>
              <input class="c-input" type="text" id="productName" name="productName" v-model="currProduct.productName" />
            </div>
          </li>

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Product Code</h3>
            <div class="c-options-list__input-group">
              <label for="productCode" class="c-label u-hidden-visually">Product Code</label>
              <input class="c-input" type="text" id="productCode" name="productCode" v-model="currProduct.productCode" v-uppercase />
            </div>
          </li>

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Product Description</h3>
            <div class="c-options-list__input-group">
              <label for="productDescription" class="c-label u-hidden-visually">productDescription</label>
              <ckeditor :editor="editor" v-model="currProduct.productDescription" :config="editorConfig"></ckeditor>
            </div>
          </li>

          <li class="c-options-list__row">
            <h3 class="c-options-list__heading">Options, Finishes & Textiles</h3>
            <div class="c-options-list__input-group">
              <label for="optionsFinishesTextiles" class="c-label u-hidden-visually">Options, Finishes & Textiles</label>
              <textarea cols="30" rows="5" class="c-input" id="optionsFinishesTextiles" name="optionsFinishesTextiles" v-model="currProduct.optionsFinishesTextiles"></textarea>
            </div>
          </li>

        </ul>
      </section>

      <section class="c-section">
        <h2 class="c-section__heading">Additional Details</h2>

        <li class="c-options-list__row">
          <h3 class="c-options-list__heading">Product Tag(s)</h3>
          <div class="c-options-list__input-group">
            <label for="productTag" class="c-label u-hidden-visually">Product Tag(s)</label>
            <input class="c-input" type="text" id="productTag" name="productTag" v-model="currProduct.productTag" />
          </div>
        </li>

        <li class="c-options-list__row">
          <h3 class="c-options-list__heading">Additional Notes</h3>
          <div class="c-options-list__input-group">
            <label for="additionalNotes" class="c-label u-hidden-visually">Additional Notes</label>
            <textarea cols="30" rows="5" class="c-input" id="additionalNotes" name="additionalNotes" v-model="currProduct.additionalNotes"></textarea>
          </div>
        </li>
      </section>
    </div>

    <div class="o-layout__secondary" v-if="dataLoaded">
      <div class="c-sidebar">
        <h3 class="c-sidebar__heading" v-if="productData.slug != 'custom-product'">{{ productData.name }}</h3>
        <h3 class="c-sidebar__heading" v-if="productData.slug == 'custom-product'">Custom Product<br>{{ currProduct.productName }}</h3>
        <div style="display: none;" v-if="productData.slug != 'custom-product'">{{ currProduct.productName = productData.name }}</div>

        <div class="c-line-item" v-if="productData.slug != 'custom-product'">
          <span class="c-line-item__label">{{ currProduct.baseCode }}</span>
          <span class="c-line-item__price">List Price<br>{{ currProduct.baseCom | formatPrice }}</span>
        </div>

        <h3 class="c-sidebar__heading" v-if="availableOptions.modules && productData.slug != 'custom-product'">Modules</h3>
        <ul class="c-sidebar__list" v-if="availableOptions.modules && productData.slug != 'custom-product'">
          <li class="c-sidebar__list-item" v-if="currProduct.moduleOne.length && currProduct.moduleOne[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.moduleOne[0].code }} - {{ currProduct.moduleOne[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.moduleOne[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.moduleTwo.length && currProduct.moduleTwo[0].code && !(this.isThreeSeat === 1 && currProduct.moduleOne[0].code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.moduleTwo[0].code }} - {{ currProduct.moduleTwo[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.moduleTwo[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.moduleThree.length && currProduct.moduleThree[0].code && !(this.isThreeSeat === 1 && currProduct.moduleOne[0].code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.moduleThree[0].code }} - {{ currProduct.moduleThree[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.moduleThree[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.moduleFour.length && currProduct.moduleFour[0].code && !(this.isThreeSeat === 1 && currProduct.moduleThree[0].code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.moduleFour[0].code }} - {{ currProduct.moduleFour[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.moduleFour[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.moduleFive.length && currProduct.moduleFive[0].code && !(this.isThreeSeat === 1 && currProduct.moduleThree[0].code === 'SET')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.moduleFive[0].code }} - {{ currProduct.moduleFive[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.moduleFive[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>
        </ul>

        <h3 class="c-sidebar__heading" v-if="productData.slug != 'custom-product'">Options, Finishes, &amp; Textiles</h3>
        <ul class="c-sidebar__list" v-if="productData.slug != 'custom-product'">

          <li class="c-sidebar__list-item" v-if="availableOptions.species && currProduct.species[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.species[0].code }} - {{ currProduct.species[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.species[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.armHeights && currProduct.armHeight[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.armHeight[0].code }} - {{ currProduct.armHeight[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.armHeight[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.tableTopSize.length && currProduct.tableTopSize[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tableTopSize[0].code }} - {{ currProduct.tableTopSize[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.tableTopSize[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.armCapMaterial.length && currProduct.armCapMaterial[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.armCapMaterial[0].code }} - {{ currProduct.armCapMaterial[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.armCapMaterial[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.pads.length && (currProduct.pads[0].code && currProduct.pads[0].code !== 'XX')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.pads[0].code }} - {{ currProduct.pads[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.pads[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.tables.length && (currProduct.tables[0].code && currProduct.tables[0].code !== 'XX')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tables[0].code }} - {{ currProduct.tables[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.tables[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.partitions.length && (currProduct.partitions[0].code && currProduct.partitions[0].code !== 'XX')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.partitions[0].code }} - {{ currProduct.partitions[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.partitions[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.tableTopMaterial.length && currProduct.tableTopMaterial[0].code) && ((((availableOptions.modules === true) && ((currProduct.moduleOne.length && currProduct.moduleOne[0].code === 'INL') || (currProduct.moduleThree.length && currProduct.moduleThree[0].code === 'INL') || (currProduct.moduleFive.length && currProduct.moduleFive[0].code === 'INL'))) || (availableOptions.modules === false) && !isMaru()) || (isMaru() && ((availableOptions.tableTopMaterials && !availableOptions.tables) || (availableOptions.tableTopMaterials && availableOptions.tables && currProduct.tables[0].code != 'XX'))))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tableTopMaterial[0].code }} - {{ currProduct.tableTopMaterial[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.tableTopMaterial[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.tableTopMaterialFinishSideA.length && currProduct.tableTopMaterialFinishSideA[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tableTopMaterialFinishSideA[0].code }} - {{ currProduct.tableTopMaterialFinishSideA[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.tableTopMaterialFinishSideA[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.tableTopMaterialFinishSideB.length && currProduct.tableTopMaterialFinishSideB[0].code) && ((currProduct.tableTopMaterial.length && (currProduct.tableTopMaterial[0].code === 'FTT' || currProduct.tableTopMaterial[0].code === 'LTT')))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tableTopMaterialFinishSideB[0].code }} - {{ currProduct.tableTopMaterialFinishSideB[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.tableTopMaterialFinishSideB[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.tableTopMaterial.length && currProduct.tableTopMaterial[0].code === 'FTT') && (currProduct.tableTopMaterialForboFinish.length && currProduct.tableTopMaterialForboFinish[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tableTopMaterialForboFinish[0].code }} - {{ currProduct.tableTopMaterialForboFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.tableTopMaterialForboFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.tableTopMaterial.length && currProduct.tableTopMaterial[0].code === 'MTT') && (currProduct.tableTopMaterialMetalFinish.length && currProduct.tableTopMaterialMetalFinish[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.tableTopMaterialMetalFinish[0].code }} - {{ currProduct.tableTopMaterialMetalFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.tableTopMaterialMetalFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>


          <li class="c-sidebar__list-item" v-if="(currProduct.largeInsertTopMaterial.length && currProduct.largeInsertTopMaterial[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.largeInsertTopMaterial[0].code }} - {{ currProduct.largeInsertTopMaterial[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.largeInsertTopMaterial[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.largeInsertTopMaterial.length && currProduct.largeInsertTopMaterial[0].code === 'FTL') && (currProduct.largeInsertTopMaterialForboFinish.length && currProduct.largeInsertTopMaterialForboFinish[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.largeInsertTopMaterialForboFinish[0].code }} - {{ currProduct.largeInsertTopMaterialForboFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.largeInsertTopMaterialForboFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.largeInsertTopMaterial.length && currProduct.largeInsertTopMaterial[0].code === 'SSL') && (currProduct.largeInsertTopMaterialSolidSurfaceFinish.length && currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code }} - {{ currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.smallInsertTopMaterial.length && currProduct.smallInsertTopMaterial[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.smallInsertTopMaterial[0].code }} - {{ currProduct.smallInsertTopMaterial[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.smallInsertTopMaterial[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.smallInsertTopMaterial.length && currProduct.smallInsertTopMaterial[0].code === 'FTS') && (currProduct.smallInsertTopMaterialForboFinish.length && currProduct.smallInsertTopMaterialForboFinish[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.smallInsertTopMaterialForboFinish[0].code }} - {{ currProduct.smallInsertTopMaterialForboFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.smallInsertTopMaterialForboFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.smallInsertTopMaterial.length && currProduct.smallInsertTopMaterial[0].code === 'SSS') && (currProduct.smallInsertTopMaterialSolidSurfaceFinish.length && currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code)">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code }} - {{ currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="productData.title === 'NC01-T1' && (currProduct.woodFinish.length && currProduct.woodFinish[0].code) && (currProduct.smallInsertTopMaterial.length && (currProduct.smallInsertTopMaterial[0].code === 'VAS' || currProduct.smallInsertTopMaterial[0].code === 'VWS' || currProduct.smallInsertTopMaterial[0].code === 'SAS' || currProduct.smallInsertTopMaterial[0].code === 'SWS'))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.woodFinish[0].code }} - {{ currProduct.woodFinish[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.woodFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.smallInsertTopMaterial.length && currProduct.smallInsertTopMaterial[0].code === 'STS') && (currProduct.stoneQuartzTableTop.length && currProduct.stoneQuartzTableTop[0].code) && productData.title === 'NC01-T1'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.stoneQuartzTableTop[0].code }} - {{ currProduct.stoneQuartzTableTop[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.stoneQuartzTableTop[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.caseworkSurfaceMaterial.length && currProduct.caseworkSurfaceMaterial[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.caseworkSurfaceMaterial[0].code }} - {{ currProduct.caseworkSurfaceMaterial[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.caseworkSurfaceMaterial[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.columnShroudPaint.length && currProduct.columnShroudPaint[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.columnShroudPaint[0].code }} - {{ currProduct.columnShroudPaint[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.columnShroudPaint[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.legMaterial.length && currProduct.legMaterial[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.legMaterial[0].code }} - {{ currProduct.legMaterial[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.legMaterial[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>
          
          <li class="c-sidebar__list-item" v-if="(currProduct.metalFinish.length && currProduct.metalFinish[0].code) && ((currProduct.legMaterial.length && currProduct.legMaterial[0].code === 'LM') || isBSide())">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.metalFinish[0].code }} - {{ currProduct.metalFinish[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.metalFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="((currProduct.woodFinish.length && currProduct.woodFinish[0].code) && (currProduct.legMaterial.length && currProduct.legMaterial[0].code !== 'LM')) || (!currProduct.legMaterial.length && currProduct.woodFinish.length && currProduct.woodFinish[0].code) && !(productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3') && !((productData.title == 'HK01-T1' || productData.title == 'HK01-T2') && currProduct.tableTopMaterial[0].code == 'FTT') && !(availableOptions.finishType && currProduct.finishType === 'paintedWood')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.woodFinish[0].code }} - {{ currProduct.woodFinish[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.woodFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="((currProduct.paintedWood.length && currProduct.paintedWood[0].code) && !(availableOptions.finishType && currProduct.finishType === 'woodFinish'))">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.paintedWood[0].code }} - {{ currProduct.paintedWood[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.paintedWood[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="((currProduct.largeInsertTopMaterial.length && (currProduct.largeInsertTopMaterial[0].code == 'VAL' || currProduct.largeInsertTopMaterial[0].code == 'VWL')) || (currProduct.smallInsertTopMaterial.length && (currProduct.smallInsertTopMaterial[0].code == 'VAS' || currProduct.smallInsertTopMaterial[0].code == 'VWS'))) && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.woodFinish[0].code }} - {{ currProduct.woodFinish[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.woodFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.metalFinish.length && (productData.title == 'HK01-01' || productData.title == 'HK01-09' || productData.title == 'HK01-11')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.metalFinish[0].code }} - {{ currProduct.metalFinish[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.metalFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.smallInsertTopMaterial.length && currProduct.smallInsertTopMaterial[0].code === 'STS') && (currProduct.stoneQuartzTableTop.length && currProduct.stoneQuartzTableTop[0].code) && productData.title !== 'NC01-T1'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.stoneQuartzTableTop[0].code }} - {{ currProduct.stoneQuartzTableTop[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.stoneQuartzTableTop[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.swivelBaseType.length">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.swivelBaseType[0].code }} - {{ currProduct.swivelBaseType[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.swivelBaseType[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.leatherArmWrap.length && currProduct.leatherArmWrap[0].code !== 'LXXX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.leatherArmWrap[0].code }} - {{ currProduct.leatherArmWrap[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.leatherArmWrap[0].code] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(currProduct.metalFinish.length && currProduct.metalFinish[0]).code && (productData.title == 'MM01-T1' || productData.title == 'MM01-T2' || productData.title == 'MM01-T3')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.metalFinish[0].code }} - {{ currProduct.metalFinish[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.metalFinish[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.moistureBarrier">
            <div class="c-line-item">
              <span class="c-line-item__label">MB - Moisture Barrier</span>
              <span class="c-line-item__price">{{ currProduct.priceMB | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.removeableCovers">
            <div class="c-line-item">
              <span class="c-line-item__label">RC - Removeable Covers</span>
              <span class="c-line-item__price">{{ currProduct.priceRC | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.calTb133">
            <div class="c-line-item">
              <span class="c-line-item__label">FB - CAL TB 133</span>
              <span class="c-line-item__price">{{ currProduct.priceFB | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.casters">
            <div class="c-line-item">
              <span class="c-line-item__label">CAS - Casters</span>
              <span class="c-line-item__price">{{ currProduct.priceCAS | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.removeableArmPads">
            <div class="c-line-item">
              <span class="c-line-item__label">AP - Removeable Arm Pads (Pair)</span>
              <span class="c-line-item__price">{{ currProduct.priceAP | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.hangingFixture">
            <div class="c-line-item">
              <span class="c-line-item__label">DJ - Hanging Fixture</span>
              <span class="c-line-item__price">{{ currProduct.priceDJ | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.powerModules.length && (currProduct.powerModules[0].code && currProduct.powerModules[0].code !== 'XX')">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.powerModules[0].code }} - {{ currProduct.powerModules[0].name }}</span>
              <span class="c-line-item__price">{{ productData.pricing[currProduct.powerModules[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.hingesOne.length && currProduct.hingesOne[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.hingesOne[0].code }} - {{ currProduct.hingesOne[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.hingesOne[0].code+'1'.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.hingesTwo.length && currProduct.hingesTwo[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.hingesTwo[0].code }} - {{ currProduct.hingesTwo[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.hingesTwo[0].code+'2'.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.hingesThree.length && currProduct.hingesThree[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.hingesThree[0].code }} - {{ currProduct.hingesThree[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.hingesThree[0].code+'3'.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.pull.length && currProduct.pull[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.pull[0].code }} - {{ currProduct.pull[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.pull[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.wrap.length && currProduct.wrap[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.wrap[0].code }} - {{ currProduct.wrap[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.wrap[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.drawerLiner">
            <div class="c-line-item">
              <span class="c-line-item__label">Drawer Liner</span>
              <span class="c-line-item__price">{{ currProduct.priceDL | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.veneerOuterCase.length && currProduct.veneerOuterCase[0].code">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.veneerOuterCase[0].code }} - {{ currProduct.veneerOuterCase[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct['price'+currProduct.veneerOuterCase[0].code.replace(/-/g, '')] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.paperTowelDispenser">
            <div class="c-line-item">
              <span class="c-line-item__label">Paper Towerl Dispenser Cut-Out</span>
              <span class="c-line-item__price">{{ currProduct.priceTD | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.extraAdjustableShelf">
            <div class="c-line-item">
              <span class="c-line-item__label">Extra Adjustable Shelf</span>
              <span class="c-line-item__price">{{ currProduct.priceAS | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.fileHolders">
            <div class="c-line-item">
              <span class="c-line-item__label">File Holders</span>
              <span class="c-line-item__price">{{ currProduct.priceFH | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="currProduct.gloveCutOuts">
            <div class="c-line-item">
              <span class="c-line-item__label">Glove Cut Outs</span>
              <span class="c-line-item__price">{{ currProduct.priceGH | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code !== 'XX'">
            <div class="c-line-item">
              <span class="c-line-item__label">{{ currProduct.multTextilesOption[0].code }} - {{ currProduct.multTextilesOption[0].name }}</span>
              <span class="c-line-item__price">{{ currProduct[`price${currProduct.multTextilesOption[0].code.replace(/-/g, '')}`] | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="(availableOptions.textileBasicOptions && (currProduct.multTextilesOption.length == false || (currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'XX')) && (productData.title != 'KN01-S1' || (productData.title == 'KN01-S1' && currProduct.tableTopMaterial[0].code == 'UPH')))">
            <div class="c-line-item">
              <span class="c-line-item__label">Textile - {{ currProduct.singleTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ singleTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'AT' && currProduct.armTextileGrade.length">
            <div class="c-line-item">
              <span class="c-line-item__label">Arm Textile - {{ currProduct.armTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ armTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'ST' && currProduct.armBackTextileGrade.length">
            <div class="c-line-item">
              <span class="c-line-item__label">Arm, Back Textile - {{ currProduct.armBackTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ (armTextilePrice + backTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'BT' && currProduct.armSeatTextileGrade.length">
            <div class="c-line-item">
              <span class="c-line-item__label">Arm, Seat Textile - {{ currProduct.armSeatTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ (armTextilePrice + backTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && (currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'BT')">
            <div class="c-line-item">
              <span class="c-line-item__label">Back Textile - {{ currProduct.backTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ backTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && (currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'TA' || currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'ST')">
            <div class="c-line-item">
              <span class="c-line-item__label">Seat Textile - {{ currProduct.seatTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ seatTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'AT' && currProduct.seatBackTextileGrade.length">
            <div class="c-line-item">
              <span class="c-line-item__label">Seat, Back Textile - {{ currProduct.seatBackTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ (seatTextilePrice + backTextilePrice) | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'AB' && currProduct.sideATextileGrade.length">
            <div class="c-line-item">
              <span class="c-line-item__label">Side A Textile - {{ currProduct.sideATextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ sideATextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item" v-if="availableOptions.multTextilesOption && currProduct.multTextilesOption.length && currProduct.multTextilesOption[0].code === 'AB' && currProduct.sideBTextileGrade.length">
            <div class="c-line-item">
              <span class="c-line-item__label">Side B Textile - {{ currProduct.sideBTextileGrade[0].name }}</span>
              <span class="c-line-item__price">{{ sideBTextilePrice | formatPrice }}</span>
            </div>
          </li>

          <li class="c-sidebar__list-item">
            <div class="c-line-item">
              <span class="c-line-item__label c-line-item__label--semi-bold"><br/>Total List</span>
              <span class="c-line-item__price"><br/>{{ (unitListPrice + unitTextilePrice) | formatPrice }}</span>
            </div>
          </li>
        </ul>

        <h3 class="c-sidebar__heading c-sidebar__heading--primary">
          {{ productData.slug != 'custom-product' ? fullCode : currProduct.productCode }}
          <div style="display:none;">{{ productData.slug != 'custom-product' ? (currProduct.productCode = fullCode) : (currProduct.productCode = currProduct.productCode, currProduct.baseCode = currProduct.productCode) }}</div>
        </h3>

        <figure class="c-sidebar__overview-line-art c-sidebar--sticky" v-if="productData && productData.slug != 'custom-product'">
          <transition name="fade">
            <img :src="fetchLineArtUrl" alt="Product line art">
          </transition>
        </figure>

        <div class="c-sidebar__overview">
          <div class="c-sidebar__input" v-if="productData.slug == 'custom-product'">
            <label for="" class="c-label">List Price ($)</label>
            <input type="text" class="c-input" v-model.number="currProduct.baseCom">
          </div>

          <div class="c-sidebar__half-half-inputs">
            <div class="c-sidebar__half-half-inputs__input-wrapper">
              <label for="" class="c-label">Quantity</label>
              <input type="number" class="c-input" v-model.number="currProduct.quantity" min="1">
            </div>

            <div class="c-sidebar__half-half-inputs__input-wrapper">
              <label for="" class="c-label">Discount (%)</label>
              <input type="number" class="c-input" v-model.number="currProduct.productDiscount" :min="0" :max="100">
            </div>
          </div>
          
          <div class="c-line-item c-line-item--center">
            <div>
              <label class="c-checkbox c-checkbox--stacked" for="oversizeGoods">
                Oversize Goods
                <input class="c-checkbox__input" type="checkbox" id="oversizeGoods" name="oversizeGoods" value="1" v-model="currProduct.oversizeGoods" :true-value="1" :false-value="0">
                <span class="c-checkbox__indicator"></span>
              </label>
            </div>

          </div>

          <div class="c-line-item" v-if="extendedListPrice">
            <span class="c-line-item__label c-line-item__label--semi-bold">Extended List</span>
            <span class="c-line-item__price">{{ extendedListPrice | formatPrice }}</span>
          </div>

          <div class="c-line-item" v-if="extendedNetPrice">
            <span class="c-line-item__label c-line-item__label--semi-bold">Extended Net</span>
            <span class="c-line-item__price">{{ extendedNetPrice | formatNetPrice }}</span>
          </div>

          <div style="display: none;">
            Unit List: {{ currProduct.unitListPrice = (unitListPrice + unitTextilePrice) }}<br/>
            Unit Textile: {{ currProduct.unitTextilePrice = unitTextilePrice }}<br/>
            Unit Net: {{ currProduct.unitNetPrice = unitNetPrice }}<br/>
            Unit Tax: {{ currProduct.unitTaxPrice = unitTaxPrice }}<br/>
            Extended List: {{ currProduct.extendedListPrice = extendedListPrice }}<br/>
            Extended Textile: {{ currProduct.extendedTextilePrice = extendedTextilePrice }}<br/>
            Extended Net: {{ currProduct.extendedNetPrice = extendedNetPrice }}<br/>
            Extended Tax: {{ currProduct.extendedTaxPrice = extendedTaxPrice }}<br/>
            Quote List: {{ workingData.totalListPrice = quoteListPrice }}<br/>
            Quote Textile: {{ workingData.totalTextilePrice = quoteTextilePrice }}<br/>
            Quote Net: {{ workingData.totalNetPrice = quoteNetPrice }}<br/>
            Freight: {{ freightSurchargeTotal = ((workingData.freightSurcharge > 0 ? ((workingData.totalListPrice * workingData.freightSurcharge) / 100) : 0) + (workingData.liftGate >= 1 ? workingData.liftGateUpcharge : 0) + (workingData.residentialDelivery >= 1 ? workingData.residentialDeliveryUpcharge : 0) + (workingData.deliveryAppointment >= 1 ? workingData.deliveryAppointmentUpcharge : 0)) }}<br/>
            Minimum Order Surcharge: {{ minimumOrderSurchargeTotal = (totalUnits < workingData.minimumOrderThreshold && workingData.waiveMinimumOrderSurcharge !== 'yes') ? workingData.minimumOrderSurcharge : 0 }}<br/>
            Quote Tax: {{ workingData.totalTaxPrice = workingData.salesTax > 0 ? Number(parseFloat(((workingData.totalNetPrice + freightSurchargeTotal + minimumOrderSurchargeTotal) * workingData.salesTax) / 100).toFixed(2).replace(/\.00/g,'')) : 0 }}<br/>
            Quote Total: {{ workingData.totalQuotePrice = Number(parseFloat(workingData.totalNetPrice + freightSurchargeTotal + minimumOrderSurchargeTotal + workingData.totalTaxPrice).toFixed(2).replace(/\.00/g,'')) }}
          </div>

          <button class="c-button c-button--primary c-button--fill" @click.prevent="saveProduct" v-bind:disabled="saveDisabled || (isLoading === 1)">Save</button>

          <div class="c-line-item c-line-item--center" style="justify-content: center;">
            <a :href="'/quote/edit/'+quoteId">Cancel</a>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import currency from 'currency.js';
import {_, isEqual} from 'vue-underscore';
import Loader from './Loader';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { print } from 'graphql';
import gql from 'graphql-tag';

const apiToken = 'sGev6T821A4zxzZvxpjpqgoN4e7LIcRB';
const apiUrl = '/api';
const saveQuoteMutation = gql`
    mutation quoteMutation(
        $id: ID,
        $quoteID: String,
        $title: String,
        $customerName: String,
        $company: String,
        $customerContact: String,
        $emailAddress: String,
        $customerPhone: String,
        $project: String,
        $address: String,
        $terms: String,
        $shippingType: String,
        $shipTo: String,
        $specifiedBy: String,
        $dealer: String,
        $architect: String,
        $contact: String,
        $shipDate: String,
        $createdBy: String,
        $leadTime: String,
        $notes: String,
        $productsJSON: productsJSON_MatrixInput,
        $salesTax: Number,
        $freightSurcharge: Number,
        $liftGate: Number,
        $liftGateUpcharge: Number,
        $residentialDelivery: Number,
        $residentialDeliveryUpcharge: Number,
        $deliveryAppointment: Number,
        $deliveryAppointmentUpcharge: Number,
        $totalListPrice: Number,
        $totalTextilePrice: Number,
        $totalNetPrice: Number,
        $totalTaxPrice: Number,
        $totalQuotePrice: Number,
        $minimumOrderSurcharge: Number,
        $minimumOrderThreshold: Number,
        $lastSavedDiscount: Number,
        $quoteType: String,
        $waiveMinimumOrderSurcharge: String,
        $authorId: ID,
        $lastApprovalRequestDate: String,
        $approvalState: String,
        $approvalMessage: String,
        $dateQuoteUpdated: String,
        $validUntilDate: String,
        $lastSaveBy: [Int]
    ) {
        save_quotes_quotes_Entry(
            id: $id,
            quoteID: $quoteID,
            title: $title,
            customerName: $customerName,
            company: $company,
            customerContact: $customerContact,
            emailAddress: $emailAddress,
            customerPhone: $customerPhone,
            project: $project,
            address: $address,
            terms: $terms,
            shippingType: $shippingType,
            shipTo: $shipTo,
            specifiedBy: $specifiedBy,
            dealer: $dealer,
            architect: $architect,
            contact: $contact,
            shipDate: $shipDate,
            createdBy: $createdBy,
            leadTime: $leadTime,
            notes: $notes,
            productsJSON: $productsJSON,
            salesTax: $salesTax,
            freightSurcharge: $freightSurcharge,
            liftGate: $liftGate,
            liftGateUpcharge: $liftGateUpcharge,
            residentialDelivery: $residentialDelivery,
            residentialDeliveryUpcharge: $residentialDeliveryUpcharge,
            deliveryAppointment: $deliveryAppointment,
            deliveryAppointmentUpcharge: $deliveryAppointmentUpcharge,
            totalListPrice: $totalListPrice,
            totalTextilePrice: $totalTextilePrice,
            totalNetPrice: $totalNetPrice,
            totalTaxPrice: $totalTaxPrice,
            totalQuotePrice: $totalQuotePrice,
            minimumOrderSurcharge: $minimumOrderSurcharge,
            minimumOrderThreshold: $minimumOrderThreshold,
            lastSavedDiscount: $lastSavedDiscount,
            quoteType: $quoteType,
            waiveMinimumOrderSurcharge: $waiveMinimumOrderSurcharge,
            authorId: $authorId,
            lastApprovalRequestDate: $lastApprovalRequestDate,
            approvalState: $approvalState,
            approvalMessage: $approvalMessage,
            dateQuoteUpdated: $dateQuoteUpdated,
            validUntilDate: $validUntilDate,
            lastSaveBy: $lastSaveBy
        ) {
            id
        }
    }
`;
const configureApi = (url, token) => {
  return {
    baseURL: url,
    headers: {
      'Authorization': `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest'
    }
  };
};
 // Execute a GraphQL query by sending an XHR to our api endpoint
const executeMutation = (api, query, variables, callback) => {
  api.post('', {
    query: print(query),
    variables: variables
  }).then((result) => {
    if (callback) {
      callback(result.data);
    }
  }).catch((error) => {
    console.log(error);
  })
};

export default {
  name: 'EditProduct',
  props: {
    'product-id': {
      type: Number,
      required: true
    },
    'product-index': {
      type: Number,
      required: true
    },
    'quote-id': {
      type: Number,
      required: true
    },
    'user-id': {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [ 'bold', 'italic', 'link' ]
        },
        link: {
            addTargetToExternalLinks: true,
        }
      },
      quoteApi: axios.create(configureApi(apiUrl, apiToken)),
      editingLoading: 1,
      isLoading: 1,
      dataLoaded: 0,
      productData: {},
      productsSortOrder: [],
      formattedProductData: {},
      saveDisabled: true,
      savedData: {},
      workingData: {},
      currProduct: {},
      textileData: {},
      availableOptions: {
        species: false,
        armHeights: false,
        modules: false,
        tableTopSizes: false,
        armCapMaterials: false,
        pads: false,
        tables: false,
        partitions: false,
        powerModules: false,
        tableTopMaterials: false,
        tableTopMaterialFinishesSideA: false,
        tableTopMaterialFinishesSideB: false,
        tableTopMaterialForboFinishes: false,
        tableTopMaterialMetalFinishes: false,
        largeInsertTopMaterials: false,
        largeInsertTopMaterialForboFinishes: false,
        largeInsertTopMaterialSolidSurfaceFinishes: false,
        smallInsertTopMaterials: false,
        smallInsertTopMaterialForboFinishes: false,
        smallInsertTopMaterialSolidSurfaceFinishes: false,
        caseworkSurfaceMaterials: false,
        columnShroudPaints: false,
        legMaterials: false,
        finishType: false,
        woodFinishes: false,
        paintedWood: false,
        metalFinishes: false,
        stoneQuartzTableTops: false,
        swivelBaseTypes: false,
        leatherArmWraps: false,
        moistureBarrier: false,
        removeableCovers: false,
        calTb133: false,
        casters: false,
        removeableArmPads: false,
        hangingFixture: false,
        drawerLiner: false,
        paperTowelDispenser: false,
        extraAdjustableShelf: false,
        fileHolders: false,
        gloveCutOuts: false,
        hinges: false,
        pulls: false,
        wraps: false,
        veneerOuterCase: false,
        textileBasicOptions: false,
        multTextilesOption: false
      },
      isTwoSeat: 0,
      isThreeSeat: 0,
      singleTextilePrice: 0,
      armTextilePrice: 0,
      armBackTextilePrice: 0,
      armSeatTextilePrice: 0,
      backTextilePrice: 0,
      seatTextilePrice: 0,
      seatBackTextilePrice: 0,
      sideATextilePrice: 0,
      sideBTextilePrice: 0,
      moduleYardage: 0
    }
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      var vm = this;
      vm.isLoading = 1;
      // Load quote data
      axios.get(`/quote/${this.quoteId}.json`)
        .then(function (response) {
          vm.savedData = JSON.parse(JSON.stringify(response.data));
          vm.workingData = response.data;
          vm.currProduct = response.data.products[vm.productIndex].product;
        })
        .catch(function (error) {
          console.log(error);
        })
        .then(function () {
          // Load product data
          axios.get(`/product/${vm.productId}.json`)
            .then(function (response) {
              vm.productData = response.data;
            })
            .catch(function (error) {
              console.log(error);
            })
            .then(function () {
              // Load textile data
              axios.get(`/textiles.json`)
                .then(function (response) {
                  vm.textileData = response.data;
                  
                  // If Orca, let's remove some textile options
                  if ((vm.productData.title === 'AB01-V') || (vm.productData.title === 'AB01-H')) {
                    
                    // Remove 2
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR02');
                    
                    // Remove 4
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR04');
                    
                    // Remove 6
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR06');
                    
                    // Remove 9
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR09');
                    
                    // Remove 11
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR11');
                    
                    // Remove 12
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR12');
                    
                    // Remove 13
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR13');
                    
                    // Remove 14
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR14');
                    
                    // Remove 15
                    vm.textileData.grades = vm.textileData.grades.filter(obj => obj.code !== 'GR15');
                  }
                  
                  
                  // Run functions
                  vm.validateOptions();
                  vm.setDefaultOptions();
                  vm.padsDependencies();
                  vm.tablesVsPower();
                  vm.idaShelfDependencies();
                })
                .catch(function (error) {
                  console.log(error);
                })
                .then(function () {
                  // Load textile data
                  axios.get(`/pricelists.json`)
                    .then(function (response) {
                      vm.pricelists = response.data;
                      vm.editingLoading = 0;
                      vm.dataLoaded = 1;
                    })
                    .catch(function (error) {
                      console.log(error);
                    })
                    .then(function () {
                      vm.isLoading = 0;
                    })
                });
            });
        });
    },
    validateOptions() {
        this.availableOptions.species = (this.productData.species.length > 1 || (this.isMaru() && this.productData.species.length > 0)) ? true : false;
        this.availableOptions.modules = this.productData.modules.length ? true : false;
        this.availableOptions.armHeights = this.productData.armHeights.length ? true : false;
        this.availableOptions.tableTopSizes = this.productData.tableTopSizes.length ? true : false;
        this.availableOptions.armCapMaterials = this.productData.armCapMaterials.length ? true : false;
        this.availableOptions.pads = this.productData.pads.length ? true : false;
        this.availableOptions.tables = this.productData.tables.length ? true : false;
        this.availableOptions.partitions = this.productData.partitions.length ? true : false;
        this.availableOptions.powerModules = this.productData.powerModules.length ? true : false;
        this.availableOptions.tableTopMaterials = this.productData.tableTopMaterials.length ? true : false;
        this.availableOptions.tableTopMaterialFinishesSideA = this.productData.tableTopMaterialFinishesSideA.length ? true : false;
        this.availableOptions.tableTopMaterialFinishesSideB = this.productData.tableTopMaterialFinishesSideB.length ? true : false;
        this.availableOptions.tableTopMaterialForboFinishes = this.productData.tableTopMaterialForboFinishes.length ? true : false;
        this.availableOptions.tableTopMaterialMetalFinishes = this.productData.tableTopMaterialMetalFinishes.length ? true : false;
        this.availableOptions.largeInsertTopMaterials = this.productData.largeInsertTopMaterials.length ? true : false;
        this.availableOptions.largeInsertTopMaterialForboFinishes = this.productData.largeInsertTopMaterialForboFinishes.length ? true : false;
        this.availableOptions.largeInsertTopMaterialSolidSurfaceFinishes = this.productData.largeInsertTopMaterialSolidSurfaceFinishes.length ? true : false;
        this.availableOptions.smallInsertTopMaterials = this.productData.smallInsertTopMaterials.length ? true : false;
        this.availableOptions.smallInsertTopMaterialForboFinishes = this.productData.smallInsertTopMaterialForboFinishes.length ? true : false;
        this.availableOptions.smallInsertTopMaterialSolidSurfaceFinishes = this.productData.smallInsertTopMaterialSolidSurfaceFinishes.length ? true : false;
        this.availableOptions.caseworkSurfaceMaterials = this.productData.caseworkSurfaceMaterials.length ? true : false;
        this.availableOptions.columnShroudPaints = this.productData.columnShroudPaints.length ? true : false;
        this.availableOptions.legMaterials = this.productData.legMaterials.length ? true : false;
        this.availableOptions.finishType = (this.productData.woodFinishes.length && this.productData.paintedWood.length) ? true : false;
        this.availableOptions.woodFinishes = this.productData.woodFinishes.length ? true : false;
        this.availableOptions.paintedWood = this.productData.paintedWood.length ? true : false;
        this.availableOptions.metalFinishes = this.productData.metalFinishes.length ? true : false;
        this.availableOptions.stoneQuartzTableTops = this.productData.stoneQuartzTableTops.length ? true : false;
        this.availableOptions.swivelBaseTypes = this.productData.swivelBaseTypes.length ? true : false;
        this.availableOptions.leatherArmWraps = this.productData.leatherArmWraps.length ? true : false;
        this.availableOptions.moistureBarrier = this.productData.otherOptions.filter(option => (option.code === 'MB')).length > 0;
        this.availableOptions.removeableCovers = this.productData.otherOptions.filter(option => (option.code === 'RC')).length > 0;
        this.availableOptions.calTb133 = this.productData.otherOptions.filter(option => (option.code === 'FB')).length > 0;
        this.availableOptions.casters = this.productData.otherOptions.filter(option => (option.code === 'CAS')).length > 0;
        this.availableOptions.removeableArmPads = this.productData.otherOptions.filter(option => (option.code === 'AP')).length > 0;
        this.availableOptions.hangingFixture = this.productData.otherOptions.filter(option => (option.code === 'DJ')).length > 0;
        this.availableOptions.drawerLiner = this.productData.otherOptions.filter(option => (option.code === 'DL')).length > 0;
        this.availableOptions.paperTowelDispenser = this.productData.otherOptions.filter(option => (option.code === 'TD')).length > 0;
        this.availableOptions.extraAdjustableShelf = this.productData.otherOptions.filter(option => (option.code === 'AS')).length > 0;
        this.availableOptions.fileHolders = this.productData.otherOptions.filter(option => (option.code === 'FH')).length > 0;
        this.availableOptions.gloveCutOuts = this.productData.otherOptions.filter(option => (option.code === 'GH')).length > 0;
        this.availableOptions.hinges = this.productData.hinges.length ? true : false;
        this.availableOptions.pulls = this.productData.pulls.length ? true : false;
        this.availableOptions.wraps = this.productData.wraps.length ? true : false;
        this.availableOptions.veneerOuterCase = this.productData.veneerOuterCase.length ? true : false;
        this.availableOptions.multTextilesOption =
          (((this.productData.yardage.seat > 0 && this.productData.yardage.back > 0)
          || (this.productData.yardage.seat > 0 && this.productData.yardage.arm > 0)
          || (this.productData.yardage.seat > 0 && this.productData.yardage.tan > 0)
          || (this.productData.yardage.seat > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.back > 0 && this.productData.yardage.arm > 0)
          || (this.productData.yardage.back > 0 && this.productData.yardage.tan > 0)
          || (this.productData.yardage.back > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.arm > 0 && this.productData.yardage.tan > 0)
          || (this.productData.yardage.arm > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.tan > 0 && this.productData.yardage.set > 0)
          || (this.productData.yardage.sideA > 0 && this.productData.yardage.sideB > 0))
          && this.productData.multTextilesOption.length)
            ? true : false;
        this.availableOptions.textileBasicOptions =
          (this.productData.yardage.seat > 0 ||
            this.productData.yardage.back > 0 ||
            this.productData.yardage.arm > 0 ||
            this.productData.yardage.tan > 0 ||
            this.productData.yardage.set > 0 ||
            this.productData.yardage.top > 0 ||
            this.productData.title == 'HK01-T1' ||
            this.productData.title == 'HK01-T2') ? true : false;
    },
    setDefaultOptions() {
        // Modules
        if (this.productData.modules.length === 3) {
            this.currProduct.moduleOne[0] = this.currProduct.moduleOne.length ? this.currProduct.moduleOne[0] : this.productData.modules[0][0];
            this.currProduct.moduleTwo[0] = this.currProduct.moduleTwo.length ? this.currProduct.moduleTwo[0] : this.productData.modules[1][1];
            this.currProduct.moduleThree[0] = this.currProduct.moduleThree.length ? this.currProduct.moduleThree[0] : this.productData.modules[2][0];
            this.isTwoSeat = 1;
            this.isThreeSeat = 0;
        } else if (this.productData.modules.length === 5) {
            this.currProduct.moduleOne[0] = this.currProduct.moduleOne.length ? this.currProduct.moduleOne[0] : this.productData.modules[0][0];
            this.currProduct.moduleTwo[0] = this.currProduct.moduleTwo.length ? this.currProduct.moduleTwo[0] : this.productData.modules[1][1];
            this.currProduct.moduleThree[0] = this.currProduct.moduleThree.length ? this.currProduct.moduleThree[0] : this.productData.modules[2][0];
            this.currProduct.moduleFour[0] = this.currProduct.moduleFour.length ? this.currProduct.moduleFour[0] : this.productData.modules[3][1];
            this.currProduct.moduleFive[0] = this.currProduct.moduleFive.length ? this.currProduct.moduleFive[0] : this.productData.modules[4][0];
            this.isTwoSeat = 0;
            this.isThreeSeat = 1;
        }

        // Hinges
        if (this.productData.hinges.length === 1) {
            this.currProduct.hingesOne[0] = this.currProduct.hingesOne.length ? this.currProduct.hingesOne[0] : this.productData.hinges[0][0];
        } else if (this.productData.hinges.length === 2) {
            this.currProduct.hingesOne[0] = this.currProduct.hingesOne.length ? this.currProduct.hingesOne[0] : this.productData.hinges[0][0];
            this.currProduct.hingesTwo[0] = this.currProduct.hingesTwo.length ? this.currProduct.hingesTwo[0] : this.productData.hinges[1][0];
        } else if (this.productData.hinges.length === 3) {
            this.currProduct.hingesOne[0] = this.currProduct.hingesOne.length ? this.currProduct.hingesOne[0] : this.productData.hinges[0][0];
            this.currProduct.hingesTwo[0] = this.currProduct.hingesTwo.length ? this.currProduct.hingesTwo[0] : this.productData.hinges[1][0];
            this.currProduct.hingesThree[0] = this.currProduct.hingesThree.length ? this.currProduct.hingesThree[0] : this.productData.hinges[2][0];
        }

        // Options
        if (this.availableOptions.species) this.currProduct.species[0] = this.currProduct.species.length ? this.currProduct.species[0] : this.productData.species[0];
        if (this.availableOptions.armHeights) this.currProduct.armHeight[0] = this.currProduct.armHeight.length ? this.currProduct.armHeight[0] : this.productData.armHeights[0];
        if (this.availableOptions.tableTopSizes) this.currProduct.tableTopSize[0] = this.currProduct.tableTopSize.length ? this.currProduct.tableTopSize[0] : this.productData.tableTopSizes[0];
        if (this.availableOptions.armCapMaterials) this.currProduct.armCapMaterial[0] = this.currProduct.armCapMaterial.length ? this.currProduct.armCapMaterial[0] : this.productData.armCapMaterials[0];
        if (this.availableOptions.pads) this.currProduct.pads[0] = this.currProduct.pads.length ? this.currProduct.pads[0] : this.productData.pads[0];
        if (this.availableOptions.tables) this.currProduct.tables[0] = this.currProduct.tables.length ? this.currProduct.tables[0] : this.productData.tables[0];
        if (this.availableOptions.partitions) this.currProduct.partitions[0] = this.currProduct.partitions.length ? this.currProduct.partitions[0] : this.productData.partitions[0];
        if (this.availableOptions.powerModules) this.currProduct.powerModules[0] = this.currProduct.powerModules.length ? this.currProduct.powerModules[0] : this.productData.powerModules[0];
        if (this.availableOptions.tableTopMaterials) this.currProduct.tableTopMaterial[0] = this.currProduct.tableTopMaterial.length ? this.currProduct.tableTopMaterial[0] : this.productData.tableTopMaterials[0];
        if (this.availableOptions.tableTopMaterialFinishesSideA) this.currProduct.tableTopMaterialFinishSideA[0] = this.currProduct.tableTopMaterialFinishSideA.length ? this.currProduct.tableTopMaterialFinishSideA[0] : this.productData.tableTopMaterialFinishesSideA[0];
        if (this.availableOptions.tableTopMaterialFinishesSideB) this.currProduct.tableTopMaterialFinishSideB[0] = this.currProduct.tableTopMaterialFinishSideB.length ? this.currProduct.tableTopMaterialFinishSideB[0] : this.productData.tableTopMaterialFinishesSideB[0];
        if (this.availableOptions.tableTopMaterialForboFinishes) this.currProduct.tableTopMaterialForboFinish[0] = this.currProduct.tableTopMaterialForboFinish.length ? this.currProduct.tableTopMaterialForboFinish[0] : this.productData.tableTopMaterialForboFinishes[0];
        if (this.availableOptions.tableTopMaterialMetalFinishes) this.currProduct.tableTopMaterialMetalFinish[0] = this.currProduct.tableTopMaterialMetalFinish.length ? this.currProduct.tableTopMaterialMetalFinish[0] : this.productData.tableTopMaterialMetalFinishes[0];
        if (this.availableOptions.largeInsertTopMaterials) this.currProduct.largeInsertTopMaterial[0] = this.currProduct.largeInsertTopMaterial.length ? this.currProduct.largeInsertTopMaterial[0] : this.productData.largeInsertTopMaterials[0];
        if (this.availableOptions.largeInsertTopMaterialForboFinishes) this.currProduct.largeInsertTopMaterialForboFinish[0] = this.currProduct.largeInsertTopMaterialForboFinish.length ? this.currProduct.largeInsertTopMaterialForboFinish[0] : this.productData.largeInsertTopMaterialForboFinishes[0];
        if (this.availableOptions.largeInsertTopMaterialSolidSurfaceFinishes) this.currProduct.largeInsertTopMaterialSolidSurfaceFinish[0] = this.currProduct.largeInsertTopMaterialSolidSurfaceFinish.length ? this.currProduct.largeInsertTopMaterialSolidSurfaceFinish[0] : this.productData.largeInsertTopMaterialSolidSurfaceFinishes[0];
        if (this.availableOptions.smallInsertTopMaterials) this.currProduct.smallInsertTopMaterial[0] = this.currProduct.smallInsertTopMaterial.length ? this.currProduct.smallInsertTopMaterial[0] : this.productData.smallInsertTopMaterials[0];
        if (this.availableOptions.smallInsertTopMaterialForboFinishes) this.currProduct.smallInsertTopMaterialForboFinish[0] = this.currProduct.smallInsertTopMaterialForboFinish.length ? this.currProduct.smallInsertTopMaterialForboFinish[0] : this.productData.smallInsertTopMaterialForboFinishes[0];
        if (this.availableOptions.smallInsertTopMaterialSolidSurfaceFinishes) this.currProduct.smallInsertTopMaterialSolidSurfaceFinish[0] = this.currProduct.smallInsertTopMaterialSolidSurfaceFinish.length ? this.currProduct.smallInsertTopMaterialSolidSurfaceFinish[0] : this.productData.smallInsertTopMaterialSolidSurfaceFinishes[0];
        if (this.availableOptions.caseworkSurfaceMaterials) this.currProduct.caseworkSurfaceMaterial[0] = this.currProduct.caseworkSurfaceMaterial.length ? this.currProduct.caseworkSurfaceMaterial[0]: this.productData.caseworkSurfaceMaterials[0];
        if (this.availableOptions.columnShroudPaints) this.currProduct.columnShroudPaint[0] = this.currProduct.columnShroudPaint.length ? this.currProduct.columnShroudPaint[0]: this.productData.columnShroudPaints[0];
        if (this.availableOptions.legMaterials) this.currProduct.legMaterial[0] = this.currProduct.legMaterial.length ? this.currProduct.legMaterial[0] : this.productData.legMaterials[0];
        if (this.availableOptions.woodFinishes) this.currProduct.woodFinish[0] = this.currProduct.woodFinish.length ? this.currProduct.woodFinish[0] : this.productData.woodFinishes[0];
        if (this.availableOptions.paintedWood) this.currProduct.paintedWood[0] = this.currProduct.paintedWood.length ? this.currProduct.paintedWood[0] : this.productData.paintedWood[0];
        if (this.availableOptions.metalFinishes) this.currProduct.metalFinish[0] = this.currProduct.metalFinish.length ? this.currProduct.metalFinish[0] : this.productData.metalFinishes[0];
        if (this.availableOptions.stoneQuartzTableTops) this.currProduct.stoneQuartzTableTop[0] = this.currProduct.stoneQuartzTableTop.length ? this.currProduct.stoneQuartzTableTop[0] : this.productData.stoneQuartzTableTops[0];
        if (this.availableOptions.swivelBaseTypes) this.currProduct.swivelBaseType[0] = this.currProduct.swivelBaseType.length ? this.currProduct.swivelBaseType[0] : this.productData.swivelBaseTypes[0];
        if (this.availableOptions.leatherArmWraps) this.currProduct.leatherArmWrap[0] = this.currProduct.leatherArmWrap.length ? this.currProduct.leatherArmWrap[0] : this.productData.leatherArmWraps[0];
        if (this.availableOptions.pulls) this.currProduct.pull[0] = this.currProduct.pull.length ? this.currProduct.pull[0] : this.productData.pulls[0];
        if (this.availableOptions.wraps) this.currProduct.wrap[0] = this.currProduct.wrap.length ? this.currProduct.wrap[0] : this.productData.wraps[0];
        if (this.availableOptions.veneerOuterCase) this.currProduct.veneerOuterCase[0] = this.currProduct.veneerOuterCase.length ? this.currProduct.veneerOuterCase[0] : this.productData.veneerOuterCase[0];

        // Set defaults if single textile grade
        if (this.availableOptions.textileBasicOptions) {
            this.currProduct.singleTextileGrade[0] = this.currProduct.singleTextileGrade.length ? this.currProduct.singleTextileGrade[0] : this.textileData.grades[0];
        }

        // Set defaults if multiple textile grades are available
        if (this.availableOptions.multTextilesOption) {
            this.currProduct.multTextilesOption[0] = this.currProduct.multTextilesOption.length ? this.currProduct.multTextilesOption[0] : this.productData.multTextilesOption[0];
            this.currProduct.armTextileGrade[0] = this.currProduct.armTextileGrade.length ? this.currProduct.armTextileGrade[0] : this.textileData.grades[0];
            this.currProduct.armBackTextileGrade[0] = this.currProduct.armBackTextileGrade.length ? this.currProduct.armBackTextileGrade[0] : this.textileData.grades[0];
            this.currProduct.armSeatTextileGrade[0] = this.currProduct.armSeatTextileGrade.length ? this.currProduct.armSeatTextileGrade[0] : this.textileData.grades[0];
            this.currProduct.backTextileGrade[0] = this.currProduct.backTextileGrade.length ? this.currProduct.backTextileGrade[0] : this.textileData.grades[0];
            this.currProduct.seatTextileGrade[0] = this.currProduct.seatTextileGrade.length ? this.currProduct.seatTextileGrade[0] : this.textileData.grades[0];
            this.currProduct.seatBackTextileGrade[0] = this.currProduct.seatBackTextileGrade.length ? this.currProduct.seatBackTextileGrade[0] : this.textileData.grades[0];
            this.currProduct.sideATextileGrade[0] = this.currProduct.sideATextileGrade.length ? this.currProduct.sideATextileGrade[0] : this.textileData.grades[0];
            this.currProduct.sideBTextileGrade[0] = this.currProduct.sideBTextileGrade.length ? this.currProduct.sideBTextileGrade[0] : this.textileData.grades[0];
        }
    },
    moduleConditions(x) {
      // Run on two seat product(s)
      if (this.isTwoSeat === 1) {
        // Check if module 1 was updated
        if (x === 0) {
          // If module 1 is set to INL, force TAN on module 3
          this.currProduct.moduleThree[0] = (this.currProduct.moduleOne[0].code === 'INL') ? this.productData.modules[2][0] : this.currProduct.moduleThree[0];
        }
        // Check if module 3 was updated
        if (x === 2) {
          // If module 3 is set to INL, force TAN on module 1
          this.currProduct.moduleOne[0] = (this.currProduct.moduleThree[0].code === 'INL') ? this.productData.modules[0][0] : this.currProduct.moduleOne[0];
        }
      }
      // Run on three seat product(s)
      if (this.isThreeSeat === 1) {
        // Check if module 1 was updated
        if (x === 0) {
          // If module 1 is set to SET, force TAN on module 3
          this.currProduct.moduleThree[0] = (this.currProduct.moduleOne[0].code === 'SET') ? this.productData.modules[2][0] : this.currProduct.moduleThree[0];
          // If setting module 1 to INL, for module(s) 3 and 5 to not be INL
          if (this.currProduct.moduleOne[0].code === 'INL') {
            // Check module 3 and reset if needed
            this.currProduct.moduleThree[0] = (this.currProduct.moduleThree[0].code === 'INL') ? this.productData.modules[2][0] : this.currProduct.moduleThree[0];
            // Check module 3 and reset if needed
            this.currProduct.moduleFive[0] = (this.currProduct.moduleFive[0].code === 'INL') ? this.productData.modules[4][0] : this.currProduct.moduleFive[0];
          }
        }
        // Check if module 2 was updated
        if (x === 1) {
          // Ensure that module(s) 2 and 4 are the same when module 3 is set to to INL
          if (this.currProduct.moduleThree[0].code === 'INL') {
            // Set module 4 to match module 2
            this.currProduct.moduleFour[0] = (this.currProduct.moduleTwo[0].code === 'IA') ? this.productData.modules[3][0] : this.productData.modules[3][1];
          }
        }
        // Check if module 3 was updated
        if (x === 2) {
          // If setting module 3 to INL, for module(s) 1 and 5 to not be INL
          // Also ensure that module(s) 2 and 4 are the same when setting module 3 to INL
          if (this.currProduct.moduleThree[0].code === 'INL') {
            // Check module 1 and reset if needed
            this.currProduct.moduleOne[0] = (this.currProduct.moduleOne[0].code === 'INL') ? this.productData.modules[0][0] : this.currProduct.moduleOne[0];
            // Check module 3 and reset if needed
            this.currProduct.moduleFive[0] = (this.currProduct.moduleFive[0].code === 'INL') ? this.productData.modules[4][0] : this.currProduct.moduleFive[0];
            // Set module 4 to match module 2 when setting module 3 to INL
            this.currProduct.moduleFour[0] = (this.currProduct.moduleTwo[0].code === 'IA') ? this.productData.modules[3][0] : this.productData.modules[3][1];
          }
        }
        // Check if module 4 was updated
        if (x === 3) {
          // Ensure that module(s) 2 and 4 are the same when module 3 is set to to INL
          if (this.currProduct.moduleThree[0].code === 'INL') {
            // Set module 2 to match module 4
            this.currProduct.moduleTwo[0] = (this.currProduct.moduleFour[0].code === 'IA') ? this.productData.modules[1][0] : this.productData.modules[1][1];
          }
        }
        // Check if module 5 was updated
        if (x === 4) {
          // If setting module 5 to INL, for module(s) 1 and 3 to not be INL
          if (this.currProduct.moduleFive[0].code === 'INL') {
            // Check module 1 and reset if needed
            this.currProduct.moduleOne[0] = (this.currProduct.moduleOne[0].code === 'INL') ? this.productData.modules[0][0] : this.currProduct.moduleOne[0];
            // Check module 3 and reset if needed
            this.currProduct.moduleThree[0] = (this.currProduct.moduleThree[0].code === 'INL') ? this.productData.modules[2][0] : this.currProduct.moduleThree[0];
          }
        }
      }
    },
    textileConditions() {
      if (this.currProduct.multTextilesOption.length && this.currProduct.multTextilesOption[0].code !== 'XX') {
        this.currProduct.singleTextileGrade[0] = this.textileData.grades[0];
      }
    },
    idaShelfDependencies(x) {

        if (this.availableOptions.woodFinishes && this.availableOptions.smallInsertTopMaterials && this.currProduct.baseCode === 'NC01-T1') {

            for (var i = 0; i < this.productData.woodFinishes.length; i++) {

                if (((this.productData.woodFinishes[i].code !== 'WCW' && this.productData.woodFinishes[i].code !== 'WCB' && (this.currProduct.woodFinish[0].code === 'WCW' || this.currProduct.woodFinish[0].code === 'WCB')) && (this.currProduct.smallInsertTopMaterial[0].code === 'VAS' || this.currProduct.smallInsertTopMaterial[0].code === 'SAS')) ||
                (this.productData.woodFinishes[i].code === 'WCW' && (this.currProduct.smallInsertTopMaterial[0].code === 'VWS' || this.currProduct.smallInsertTopMaterial[0].code === 'SWS'))) {

                    // Set wood finish to this value
                    this.currProduct.woodFinish[0] = this.productData.woodFinishes[i]
                    break;
                }
            }
        }
    },
    insertDependencies(x) {
      // Set the triggering select menu
      var _trigger = x.target.id;

      // If triggered by large insert
      if (_trigger === 'largeInsertTopMaterial') {
        // If large insert is set to ash
        // and small insert is set to walnut,
        // force small insert to ash
        if (this.currProduct.largeInsertTopMaterial[0].code === 'VAL' && this.currProduct.smallInsertTopMaterial[0].code === 'VWS') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.smallInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.smallInsertTopMaterials[i].code === 'VAS') {
              // Update selected
              _selected = this.productData.smallInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.currProduct.smallInsertTopMaterial[0] = _selected;
          }
        }

        // If large insert is set to walnut
        // and small insert is set to ash,
        // force small insert to walnut
        if (this.currProduct.largeInsertTopMaterial[0].code === 'VWL' && this.currProduct.smallInsertTopMaterial[0].code === 'VAS') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.smallInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.smallInsertTopMaterials[i].code === 'VWS') {
              // Update selected
              _selected = this.productData.smallInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.currProduct.smallInsertTopMaterial[0] = _selected;
          }
        }
      }

      // If triggered by small insert
      if (_trigger === 'smallInsertTopMaterial') {
        // If small insert is set to ash
        // and large insert is set to walnut,
        // force large insert to ash
        if (this.currProduct.smallInsertTopMaterial[0].code === 'VAS' && this.currProduct.largeInsertTopMaterial[0].code === 'VWL') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.largeInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.largeInsertTopMaterials[i].code === 'VAL') {
              // Update selected
              _selected = this.productData.largeInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.currProduct.largeInsertTopMaterial[0] = _selected;
          }
        }

        // If small insert is set to walnut
        // and large insert is set to ash,
        // force large insert to walnut
        if (this.currProduct.smallInsertTopMaterial[0].code === 'VWS' && this.currProduct.largeInsertTopMaterial[0].code === 'VAL') {
          // Set holder for our selection
          // that we want to pass onward
          var _selected = null;

          // Loop options and find the match,
          // then set it and move on
          for (var i = 0; i < this.productData.largeInsertTopMaterials.length; i++) {
            // If we find a match,
            // set it and move on
            if (this.productData.largeInsertTopMaterials[i].code === 'VWL') {
              // Update selected
              _selected = this.productData.largeInsertTopMaterials[i];
            }
          }
          // If selected has a value,
          // pass it on to our field
          if (_selected !== null) {
            this.currProduct.largeInsertTopMaterial[0] = _selected;
          }
        }
      }

      // Run Chord table dependencies
      this.chordTableDependencies();
    },
    padsDependencies() {

        // Run if Maru product
        if (this.isMaru()) {

            // Multiple textiles
            if (this.productData.pads.length && this.productData.multTextilesOption.length) {

                // If pads are selected, enable multiple textile
                if (this.currProduct.pads[0].code !== 'XX') {
                    this.availableOptions.multTextilesOption = true;
                } else {
                    this.availableOptions.multTextilesOption = false;

                    // Reset textile selections
                    this.currProduct.multTextilesOption[0] = this.productData.multTextilesOption[0];
                    this.currProduct.armTextileGrade[0] = this.textileData.grades[0];
                    this.currProduct.armBackTextileGrade[0] = this.textileData.grades[0];
                    this.currProduct.armSeatTextileGrade[0] = this.textileData.grades[0];
                    this.currProduct.backTextileGrade[0] = this.textileData.grades[0];
                    this.currProduct.seatTextileGrade[0] = this.textileData.grades[0];
                    this.currProduct.seatBackTextileGrade[0] = this.textileData.grades[0];
                }
            }

            // Single textile
            if (this.productData.pads.length && !this.productData.multTextilesOption.length) {

                // If pads are selected, enable basic textiles
                if (this.currProduct.pads[0].code !== 'XX') {
                    this.availableOptions.textileBasicOptions = true;
                } else {
                    this.availableOptions.textileBasicOptions = false;

                    // Reset textile selection
                    this.currProduct.singleTextileGrade[0] = this.textileData.grades[0];
                }
            }
        }

        // Run if April product
        if (this.isApril()) {

            // Single textile
            if (this.productData.pads.length && !this.productData.multTextilesOption.length) {

                // If pads are selected, enable basic textiles
                if (this.currProduct.pads[0].code !== 'XX') {
                    this.availableOptions.textileBasicOptions = true;
                } else {
                    this.availableOptions.textileBasicOptions = false;

                    // Reset textile selection
                    this.currProduct.singleTextileGrade[0] = this.textileData.grades[0];
                }
            }
        }
    },
    textileDependencies(x) {
      // Safe proof and only run if multiple textiles are available
      // and set not set to run as a single textile
      if (this.currProduct.multTextilesOption.length && this.currProduct.multTextilesOption[0].code !== 'XX') {
        // Set the triggering select menu
        var _trigger = x.target.id;

        // If triggered by Arm
        if (_trigger === 'armTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.armTextileGrade[0];

          // If Arm, Seat/Back
          if (this.currProduct.multTextilesOption[0].code === 'AT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.seatBackTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.seatBackTextileGrade[0].code === 'GRCOM') {
                this.currProduct.seatBackTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Arm/Back
        if (_trigger === 'armBackTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.armBackTextileGrade[0];

          // If Seat, Arm/Back
          if (this.currProduct.multTextilesOption[0].code === 'ST') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.seatTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.seatTextileGrade[0].code === 'GRCOM') {
                this.currProduct.seatTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Arm/Seat
        if (_trigger === 'armSeatTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.armSeatTextileGrade[0];

          // If Back, Arm/Seat
          if (this.currProduct.multTextilesOption[0].code === 'BT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.backTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.backTextileGrade[0].code === 'GRCOM') {
                this.currProduct.backTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Back
        if (_trigger === 'backTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.backTextileGrade[0];

          // If Seat, Back
          if (this.currProduct.multTextilesOption[0].code === 'TA') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.seatTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.seatTextileGrade[0].code === 'GRCOM') {
                this.currProduct.seatTextileGrade[0] = _selected;
              }
            }
          }

          // If Back, Arm/Seat
          if (this.currProduct.multTextilesOption[0].code === 'BT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.armSeatTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.armSeatTextileGrade[0].code === 'GRCOM') {
                this.currProduct.armSeatTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Seat
        if (_trigger === 'seatTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.seatTextileGrade[0];

          // If Seat, Back
          if (this.currProduct.multTextilesOption[0].code === 'TA') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.backTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.backTextileGrade[0].code === 'GRCOM') {
                this.currProduct.backTextileGrade[0] = _selected;
              }
            }
          }

          // If Seat, Arm/Back
          if (this.currProduct.multTextilesOption[0].code === 'ST') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.armBackTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.armBackTextileGrade[0].code === 'GRCOM') {
                this.currProduct.armBackTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Arm
        if (_trigger === 'seatBackTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.seatBackTextileGrade[0];

          // If Arm, Seat/Back
          if (this.currProduct.multTextilesOption[0].code === 'AT') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.armTextileGrade[0] = _selected;
            } else {
              // Only change the seat grade if it's still set to GRCOM
              if (this.currProduct.armTextileGrade[0].code === 'GRCOM') {
                this.currProduct.armTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Side A
        if (_trigger === 'sideATextileGrade') {
          // Set selected code
          var _selected = this.currProduct.sideATextileGrade[0];

          // If two sides
          if (this.currProduct.multTextilesOption[0].code === 'AB') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.sideBTextileGrade[0] = _selected;
            } else {
              // Only change the side b grade if it's still set to GRCOM
              if (this.currProduct.sideBTextileGrade[0].code === 'GRCOM') {
                this.currProduct.sideBTextileGrade[0] = _selected;
              }
            }
          }
        }

        // If triggered by Side B
        if (_trigger === 'sideBTextileGrade') {
          // Set selected code
          var _selected = this.currProduct.sideBTextileGrade[0];

          // If two sides
          if (this.currProduct.multTextilesOption[0].code === 'AB') {
            // If customer textile, else
            if (_selected.code === 'GRCOM') {
              this.currProduct.sideATextileGrade[0] = _selected;
            } else {
              // Only change the side a grade if it's still set to GRCOM
              if (this.currProduct.sideATextileGrade[0].code === 'GRCOM') {
                this.currProduct.sideATextileGrade[0] = _selected;
              }
            }
          }
        }
      }
    },
    chordTableDependencies() {
      // Ensure that we're checking against
      // Chord tables, and if not, ignore
      if (this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') {
        // Loop through available wood finishes
        // and set finish as first value matching
        // the conditional set by either insert
        for (var i = 0; i < this.productData.woodFinishes.length; i++) {
          // Match if our species is set to Ash AND
          // wood finish doesn't have a code of WCW
          // OR our species is set to Walnut and we
          // have a code of WCW
          if ((this.productData.woodFinishes[i].code !== 'WCW' && (this.currProduct.woodFinish[0].code === 'WCW' || this.currProduct.woodFinish[0].code === '' || this.currProduct.woodFinish[0].code === null) && (this.currProduct.largeInsertTopMaterial[0].code === 'VAL' || this.currProduct.smallInsertTopMaterial[0].code === 'VAS')) || (this.productData.woodFinishes[i].code === 'WCW' && (this.currProduct.woodFinish[0].code !== 'WCW' || this.currProduct.woodFinish[0].code === '' || this.currProduct.woodFinish[0].code === null) && (this.currProduct.largeInsertTopMaterial[0].code === 'VWL' || this.currProduct.smallInsertTopMaterial[0].code === 'VWS'))) {
            // Set wood finish to this value
            this.currProduct.woodFinish[0] = this.productData.woodFinishes[i]
            break;
          }
        }
      }
    },
    speciesDependencies() {
        // Ensure we have multiple species
        // before running conditionals
        if (this.availableOptions.species) {

            // If wood finishes exist for frames ensure we default
            // to finish available for selected species
            if (this.availableOptions.woodFinishes && !this.availableOptions.legMaterials) {

                // Loop through available wood finishes
                // and set finish as first value matching
                // the conditional set by species type
                for (var i = 0; i < this.productData.woodFinishes.length; i++) {

                    // Match if our species is set to Ash AND
                    // wood finish doesn't have a code of WCW
                    // OR our species is set to Walnut and we
                    // have a code of WCW
                    if (((this.productData.woodFinishes[i].code !== 'WCW' && this.productData.woodFinishes[i].code !== 'WCB') && this.currProduct.species[0].code === 'A') || (this.productData.woodFinishes[i].code === 'WCW' && this.currProduct.species[0].code === 'W') || (this.productData.woodFinishes[i].code === 'WCB' && this.currProduct.species[0].code === 'B')) {

                        // Set wood finish to this value
                        this.currProduct.woodFinish[0] = this.productData.woodFinishes[i]
                        break;
                    }
                }
            }

            // If table top materials exist for frames ensure we
            // default to finish available for selected species
            if (this.availableOptions.tableTopMaterials) {

                // Loop through available table top materials
                // and set finish as first value matching
                // the conditional set by species type, but
                // only do all of this if one of our use-cases
                // has been found and is a match
                for (var i = 0; i < this.productData.tableTopMaterials.length; i++) {

                    // Match if our species is set to Ash AND
                    // wood finish doesn't have a code of WCW
                    // OR our species is set to Walnut and we
                    // have a code of WCW
                    if (((this.productData.tableTopMaterials[i].code !== 'WTB' && this.productData.tableTopMaterials[i].code !== 'WTW' && this.productData.tableTopMaterials[i].code !== 'VTB' && this.productData.tableTopMaterials[i].code !== 'VTW') && this.currProduct.species[0].code === 'A' && (this.currProduct.tableTopMaterial[0].code === 'WTB' || this.currProduct.tableTopMaterial[0].code === 'WTW' || this.currProduct.tableTopMaterial.code === 'VTB' || this.currProduct.tableTopMaterial[0].code === 'VTW')) ||

                    ((this.productData.tableTopMaterials[i].code !== 'WTA' && this.productData.tableTopMaterials[i].code !== 'WTB' && this.productData.tableTopMaterials[i].code !== 'VTA' && this.productData.tableTopMaterials[i].code !== 'VTB') && this.currProduct.species[0].code === 'W' && (this.currProduct.tableTopMaterial[0].code === 'WTA' || this.currProduct.tableTopMaterial[0].code === 'WTB' || this.currProduct.tableTopMaterial[0].code === 'VTA' || this.currProduct.tableTopMaterial[0].code === 'VTB')) ||

                    ((this.productData.tableTopMaterials[i].code !== 'WTA' && this.productData.tableTopMaterials[i].code !== 'WTW' && this.productData.tableTopMaterials[i].code !== 'VTA' && this.productData.tableTopMaterials[i].code !== 'VTB' && this.productData.tableTopMaterials[i].code !== 'CT1' && this.productData.tableTopMaterials[i].code !== 'CT2' && this.productData.tableTopMaterials[i].code !== 'CT3' && this.productData.tableTopMaterials[i].code !== 'CT4' && this.productData.tableTopMaterials[i].code !== 'CT5' && this.productData.tableTopMaterials[i].code !== 'CT6' && this.productData.tableTopMaterials[i].code !== 'CT7') && this.currProduct.species[0].code === 'B' && (this.currProduct.tableTopMaterial[0].code === 'WTA' || this.currProduct.tableTopMaterial[0].code === 'WTW' || this.currProduct.tableTopMaterial[0].code === 'VTA' || this.currProduct.tableTopMaterial[0].code === 'VTB' || this.currProduct.tableTopMaterial[0].code === 'CT1' || this.currProduct.tableTopMaterial[0].code === 'CT2' || this.currProduct.tableTopMaterial[0].code === 'CT3' || this.currProduct.tableTopMaterial[0].code === 'CT4' || this.currProduct.tableTopMaterial[0].code === 'CT5' || this.currProduct.tableTopMaterial[0].code === 'CT6' || this.currProduct.tableTopMaterial[0].code === 'CT7'))) {

                        // Set wood finish to this value
                        this.currProduct.tableTopMaterial[0] = this.productData.tableTopMaterials[i]
                        break;
                    }
                }
            }

            // If partitions exist, default them to the correct type based on
            // species changes being made via the UI
            if (this.availableOptions.partitions) {

                // Loop through each partition and set where a match exists
                for (var i = 0; i < this.productData.partitions.length; i++) {

                    // If we have a match of sorts, set this to the default
                    if (this.currProduct.partitions[0].code !== 'XX' && ((this.productData.partitions[i].code === 'SCR1B' && this.currProduct.species[0].code === 'B') || (this.productData.partitions[i].code === 'SCR1A' && this.currProduct.species[0].code === 'A') || (this.productData.partitions[i].code === 'SCR1W' && this.currProduct.species[0].code === 'W') || (this.productData.partitions[i].code === 'SCR1OM' && this.currProduct.species[0].code === 'OM'))) {

                        // Set partition to this value
                        this.currProduct.partitions[0] = this.productData.partitions[i]
                        break;
                    }
                }
            }
        }

        // Dependencies for Chroma Low & High Tables
        if (this.productData.title === 'HK01-T1' || this.productData.title === 'HK01-T2') {

            // Loop through available wood finishes
            // and set finish as first value matching
            // the conditional set by species type
            for (var i = 0; i < this.productData.woodFinishes.length; i++) {
                // Match if our species is set to Ash AND
                // wood finish doesn't have a code of WCW
                // OR our species is set to Walnut and we
                // have a code of WCW
                if ((this.productData.woodFinishes[i].code !== 'WCW' && this.currProduct.tableTopMaterial[0].code === 'VTA') || (this.productData.woodFinishes[i].code === 'WCW' && this.currProduct.tableTopMaterial[0].code === 'VTW')) {

                    // Set wood finish to this value
                    this.currProduct.woodFinish[0] = this.productData.woodFinishes[i]
                    break;
                }
            }
        }
        
        // If B-Side product
        if (this.isBSide()) {
          
          // Loop through available finishes for side a and
          // set to the first matching finish for top type
          for (var i = 0; i < this.productData.tableTopMaterialFinishesSideA.length; i++) {
            
            if (
              (this.currProduct.tableTopMaterial[0].code === 'VTA' && this.isAshFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'VTW' && this.isWalnutFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'FTT' && this.isForboFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'SST' && this.isSolidSurfaceFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'CTT' && this.isCorkFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'LTT' && this.isLaminateFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'RTT' && this.isReallyFinish(this.productData.tableTopMaterialFinishesSideA[i].code))
            ) {
              this.currProduct.tableTopMaterialFinishSideA[0] = this.productData.tableTopMaterialFinishesSideA[i]
              break;
            }
          }
          
          // Loop through available finishes for side b and
          // set to the first matching finish for top type
          for (var i = 0; i < this.productData.tableTopMaterialFinishesSideB.length; i++) {
            
            if (
              (this.currProduct.tableTopMaterial[0].code === 'VTA' && this.isAshFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'VTW' && this.isWalnutFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'FTT' && this.isForboFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'SST' && this.isSolidSurfaceFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'CTT' && this.isCorkFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'LTT' && this.isLaminateFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
              || (this.currProduct.tableTopMaterial[0].code === 'RTT' && this.isReallyFinish(this.productData.tableTopMaterialFinishesSideB[i].code))
            ) {
              this.currProduct.tableTopMaterialFinishSideB[0] = this.productData.tableTopMaterialFinishesSideB[i]
              break;
            }
          }
        }
    },
    tablesVsPower() {
        // If Maru 3 slat, 3/4 IND config 3
        if (this.productData.name === 'Maru 3-slat, 3/4-length Indoor Configuration 3') {

            // Only run if a table is selected
            if (this.currProduct.tables[0].code !== 'XX') {

                // Only run if power module selected isn't XX or PM1
                if (this.currProduct.powerModules[0].code !== 'XX' && this.currProduct.powerModules[0].code !== 'PM1') {

                    // Set to PM1
                    this.currProduct.powerModules[0] = this.productData.powerModules[1];
                }
            }
        }
    },
    isMaru() {
        // Create array of Maru product base code(s)
        const maruProducts = [
                                'SG03-IND-S7-86W',
                                'SG03-IND-S7-86W-BC4-BG4',
                                'SG03-IND-S7-86W-BE4',
                                'SG03-IND-S7-86W-BC4R-BG4R',
                                'SG03-IND-S5-86W',
                                'SG03-IND-S5-86W-BC2-BG2',
                                'SG03-IND-S5-86W-BC2-BG4',
                                'SG03-IND-S5-86W-BC4-BG2',
                                'SG03-IND-S5-86W-BC2',
                                'SG03-IND-S5-86W-BG2',
                                'SG03-IND-S3-86W',
                                'SG03-IND-S3-66W',
                                'SG03-OUT-S7-86W',
                                'SG03-OUT-S7-86W-BC4-BG4',
                                'SG03-OUT-S7-86W-BE4',
                                'SG03-OUT-S7-86W-BC4R-BG4R',
                                'SG03-OUT-S5-86W',
                                'SG03-OUT-S5-86W-BC2-BG2',
                                'SG03-OUT-S5-86W-BC2-BG4',
                                'SG03-OUT-S5-86W-BC4-BG2',
                                'SG03-OUT-S5-86W-BC2',
                                'SG03-OUT-S5-86W-BG2',
                                'SG03-OUT-S3-86W',
                                'SG03-OUT-S3-66W',
                                'SG03-IND-BOL-40W',
                                'SG03-OUT-BOL-40W',
                                'SG03-IND-PAD-40W',
                                'SG03-OUT-PAD-40W',
                                'SG03-IND-PAD-60W',
                                'SG03-OUT-PAD-60W',
                                'SG03-IND-T1',
                                'SG03-IND-T2',
                                'SG03-IND-SCR1',
                                'SG03-OUT-SCR1',
                                'SG03-IND-PM',
                                'SG03-IND-PS'
                            ];

        // If the current product is found in the Maru Products array, return a
        // true result, on call of isMaru()
        if (maruProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isApril() {
        // Create array of April product base code(s)
        const aprilProducts = [
                                'JY02-10',
                                'JY02-16',
                                'JY02-17'
                            ];

        // If the current product is found in the April Products array, return a
        // true result, on call of isApril()
        if (aprilProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isProse() {
        // Create array of Prose product base code(s)
        const proseProducts = [
                                'GC01-T1',
                                'GC01-T2',
                                'GC01-01',
                                'GC01-02',
                                'GC01-10',
                                'GC01-11',
                                'GC01-15',
                                'GC01-22'
                            ];

        // If the current product is found in the Prose Products array, return a
        // true result, on call of isProse()
        if (proseProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isBSide() {
        // Create array of B-Side product base code(s)
        const bSideProducts = [
                                'MM03-T1',
                                'MM03-T2',
                                'MM03-T3'
                            ];

        // If the current product is found in the B-Side Products array, return a
        // true result, on call of isBSide()
        if (bSideProducts.includes(this.productData.title)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isAshFinish(finish) {
        // Create array of veneer ash finishes
        const ashFinishes = [
                                'WCA',
                                'WGA',
                                'WOA',
                                'WNB',
                                'WNP',
                                'WBA'
                            ];

        // If the current finish is found in the ash finish array
        if (ashFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isWalnutFinish(finish) {
        // Create array of walnut finishes
        const walnutFinishes = [
                                'WCW'
                            ];

        // If the current finish is found in the walnut finish array
        if (walnutFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isForboFinish(finish) {
        // Create array of forbo finishes
        const forboFinishes = [
                                'FAS',
                                'FBU',
                                'FPW',
                                'FSA',
                                'FCH',
                                'FPE',
                                'FMU',
                                'FVA',
                                'FIR',
                                'FSB',
                                'FAQ',
                                'FMB',
                                'FOL',
                                'FPO'
                            ];

        // If the current finish is found in the forbo finish array
        if (forboFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isSolidSurfaceFinish(finish) {
        // Create array of solid surface finishes
        const solidSurfaceFinishes = [
                                'YUS',
                                'KIS',
                                'HIS',
                                'BLS',
                                'CWS'
                            ];

        // If the current finish is found in the solid surface finish array
        if (solidSurfaceFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isCorkFinish(finish) {
        // Create array of cork finishes
        const corkFinishes = [
                                'CNC',
                                'CDK'
                            ];

        // If the current finish is found in the cork finish array
        if (corkFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isLaminateFinish(finish) {
        // Create array of laminate finishes
        const laminateFinishes = [
                                'LTF',
                                'LTP',
                                'LTW',
                                'LTA'
                            ];

        // If the current finish is found in the laminate finish array
        if (laminateFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    isReallyFinish(finish) {
        // Create array of really finishes
        const reallyFinishes = [
                                'RCW',
                                'RBD'
                            ];

        // If the current finish is found in the really finish array
        if (reallyFinishes.includes(finish)) {

            // Product found
            return true;
        } else {

            // Product not found
            return false;
        }
    },
    saveProduct() {
      this.isLoading = 1;
      // Clear large insert material name if needed
      if (this.currProduct.largeInsertTopMaterial.length) { this.currProduct.largeInsertTopMaterialName = '' }
      // Clear small insert material name if needed
      if (this.currProduct.smallInsertTopMaterial.length) { this.currProduct.smallInsertTopMaterialName = '' }
      // Clear table top material if needed
      if (this.isMaru() && this.currProduct.tables.length && this.currProduct.tables[0].code === 'XX') { this.currProduct.tableTopMaterial = [] }
      if (this.currProduct.tableTopMaterial.length && (this.currProduct.tableTopMaterial[0].code !== 'FTT' && this.currProduct.tableTopMaterial[0].code !== 'LTT')) { this.currProduct.tableTopMaterialFinishSideB = [] }
      // Create temp data
      const tempProductData = JSON.parse(JSON.stringify(this.workingData.products));

      // Remove name(s) nodes from product data for saving to craft db
      tempProductData.map((item, index) => {

          // Set unique block id
          var blockId = 'new:'+(index+1);

          // Add block id to product sort order
          this.productsSortOrder.push(blockId);

          // Set product block
          var productBlock  = {
              'product': {
                 'configuration': JSON.stringify(item.product)
              }
          }

          // Push block to formatted product data
          this.formattedProductData[blockId] = productBlock;
      });
      // Set up variables for saving
      const variables = {
          'id': this.quoteId,
          'title': this.workingData.customerName+' - '+this.workingData.project,
          'customerName': this.workingData.customerName,
          'customerContact': this.workingData.customerContact,
          'emailAddress': this.workingData.emailAddress,
          'project': this.workingData.project,
          'address': this.workingData.address,
          'terms': this.workingData.terms,
          'shippingType': this.workingData.shippingType,
          'shipTo': this.workingData.shipTo,
          'specifiedBy': this.workingData.specifiedBy,
          'dealer': this.workingData.dealer,
          'architect': this.workingData.architect,
          'contact': this.workingData.contact,
          'shipDate': this.workingData.shipDate,
          'createdBy': this.workingData.createdBy,
          'leadTime': this.workingData.leadTime,
          'notes': this.workingData.notes,
          'productsJSON': {
              sortOrder: this.productsSortOrder,
              blocks: this.formattedProductData,
          },
          'totalListPrice': this.quoteListPrice,
          'totalTextilePrice': this.quoteTextilePrice,
          'totalNetPrice': this.quoteNetPrice,
          'totalTaxPrice': this.workingData.totalTaxPrice,
          'totalQuotePrice': this.workingData.totalQuotePrice,
          'minimumOrderSurcharge': this.workingData.minimumOrderSurcharge,
          'minimumOrderThreshold': this.workingData.minimumOrderThreshold,
          'lastSavedDiscount': this.currProduct.productDiscount,
          'quoteType': this.workingData.quoteType,
          'waiveMinimumOrderSurcharge': this.workingData.waiveMinimumOrderSurcharge,
          'authorId': this.workingData.author.id,
          'lastApprovalRequestDate': this.workingData.lastApprovalRequestDate,
          'approvalState': this.workingData.approvalState,
          'approvalMessage': this.workingData.approvalMessage,
          'dateQuoteUpdated': this.workingData.dateQuoteUpdated,
          'validUntilDate': this.workingData.validUntilDate,
          'lastSaveBy': parseFloat(this.userId)
      }
      // Execute the query
      executeMutation(this.quoteApi, saveQuoteMutation, variables, (data) => {
          this.submitted = true;
          this.saveDisabled = true;
          //window.location.replace('/quote/edit/'+this.quoteId);
          window.location.replace('/quote/edit/'+this.quoteId+'#'+(this.productIndex + 1));
          console.log('submitted');
      });
    },
    onLeave() {
      if (!this.saveDisabled) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    workingData: {
        handler() {
            // Set state of save disabled based on whether a change has been made
            this.saveDisabled = _.isEqual(this.workingData, this.savedData);
        },
        deep: true
    }
  },
  computed: {
    fullCode() {
      let code = `
        ${this.currProduct.baseCode}
        ${(this.availableOptions.pads && this.currProduct.pads[0].code !== 'XX') ? `-${this.currProduct.pads[0].code}` : ''}
        ${(this.availableOptions.species && this.currProduct.species[0].code) ? `-${this.currProduct.species[0].code}` : ''}
        ${(this.availableOptions.tables && this.currProduct.tables[0].code !== 'XX') ? `-${this.currProduct.tables[0].code}` : ''}
        ${(this.availableOptions.partitions && this.currProduct.partitions[0].code !== 'XX') ? `-${this.currProduct.partitions[0].code}` : ''}
        ${(this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code) ? `-${this.currProduct.moduleOne[0].code}` : ''}
        ${(this.isThreeSeat === 1 && this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code === 'SET') ? `-NA` : (this.currProduct.moduleTwo.length && this.currProduct.moduleTwo[0].code) ? `-${this.currProduct.moduleTwo[0].code}` : ''}
        ${(this.isThreeSeat === 1 && this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code === 'SET') ? `-NA` : (this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code) ? `-${this.currProduct.moduleThree[0].code}` : ''}
        ${(this.isThreeSeat === 1 && this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code === 'SET') ? `-NA` : (this.currProduct.moduleFour.length && this.currProduct.moduleFour[0].code) ? `-${this.currProduct.moduleFour[0].code}` : ''}
        ${(this.isThreeSeat === 1 && this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code === 'SET') ? `-NA` : (this.currProduct.moduleFive.length && this.currProduct.moduleFive[0].code) ? `-${this.currProduct.moduleFive[0].code}` : ''}
        ${(this.availableOptions.armHeights && this.currProduct.armHeight[0].code) ? `-${this.currProduct.armHeight[0].code}` : ''}
        ${(this.availableOptions.tableTopSizes && this.currProduct.tableTopSize[0].code) ? `-${this.currProduct.tableTopSize[0].code}` : ''}
        ${(this.currProduct.armCapMaterial.length && this.currProduct.armCapMaterial[0].code) ? `-${this.currProduct.armCapMaterial[0].code}` : ''}
        ${(
          (this.currProduct.tableTopMaterial.length === 1 && this.currProduct.tableTopMaterial[0].code) &&
          (
            (this.availableOptions.modules === false) ||
            (
              (this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code === 'INL') ||
              (this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code === 'INL') ||
              (this.currProduct.moduleFive.length && this.currProduct.moduleFive[0].code === 'INL')
            )
          ) && !this.isMaru()
        ) || (
          (this.currProduct.tableTopMaterial.length === 1 && this.currProduct.tableTopMaterial[0].code) &&
          (this.isMaru() && ((this.availableOptions.tableTopMaterials && !this.availableOptions.tables) || (this.availableOptions.tableTopMaterials && this.availableOptions.tables && this.currProduct.tables[0].code !== 'XX')))
        ) ? `-${this.currProduct.tableTopMaterial[0].code}` : ''}
        ${(this.currProduct.tableTopMaterialFinishSideA.length === 1 && this.currProduct.tableTopMaterialFinishSideA[0].code) ? `-${this.currProduct.tableTopMaterialFinishSideA[0].code}` : ''}
        ${(this.currProduct.tableTopMaterial.length === 1 && (this.currProduct.tableTopMaterial[0].code === 'FTT' || this.currProduct.tableTopMaterial[0].code === 'LTT')) && (this.currProduct.tableTopMaterialFinishSideB.length === 1 && this.currProduct.tableTopMaterialFinishSideB[0].code) ? `-${this.currProduct.tableTopMaterialFinishSideB[0].code}` : ''}
        ${(this.currProduct.tableTopMaterial.length === 1 && this.currProduct.tableTopMaterial[0].code === 'FTT') && (this.currProduct.tableTopMaterialForboFinish.length === 1 && this.currProduct.tableTopMaterialForboFinish[0].code) ? `-${this.currProduct.tableTopMaterialForboFinish[0].code}` : ''}
        ${(this.currProduct.tableTopMaterial.length === 1 && this.currProduct.tableTopMaterial[0].code === 'MTT') && (this.currProduct.tableTopMaterialMetalFinish.length === 1 && this.currProduct.tableTopMaterialMetalFinish[0].code) ? `-${this.currProduct.tableTopMaterialMetalFinish[0].code}` : ''}
        ${this.availableOptions.largeInsertTopMaterials ? `-${this.currProduct.largeInsertTopMaterial[0].code}` : ''}
        ${(this.currProduct.largeInsertTopMaterial.length === 1 && this.currProduct.largeInsertTopMaterial[0].code === 'FTL') && (this.currProduct.largeInsertTopMaterialForboFinish.length === 1 && this.currProduct.largeInsertTopMaterialForboFinish[0].code) ? `-${this.currProduct.largeInsertTopMaterialForboFinish[0].code}` : ''}
        ${(this.currProduct.largeInsertTopMaterial.length === 1 && this.currProduct.largeInsertTopMaterial[0].code === 'SSL') && (this.currProduct.largeInsertTopMaterialSolidSurfaceFinish.length === 1 && this.currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code) ? `-${this.currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code}` : ''}
        ${this.availableOptions.smallInsertTopMaterials ? `-${this.currProduct.smallInsertTopMaterial[0].code}` : ''}
        ${(this.currProduct.smallInsertTopMaterial.length === 1 && this.currProduct.smallInsertTopMaterial[0].code === 'FTS') && (this.currProduct.smallInsertTopMaterialForboFinish.length === 1 && this.currProduct.smallInsertTopMaterialForboFinish[0].code) ? `-${this.currProduct.smallInsertTopMaterialForboFinish[0].code}` : ''}
        ${(this.currProduct.smallInsertTopMaterial.length === 1 && this.currProduct.smallInsertTopMaterial[0].code === 'SSS') && (this.currProduct.smallInsertTopMaterialSolidSurfaceFinish.length === 1 && this.currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code) ? `-${this.currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code}` : ''}
        ${((this.availableOptions.largeInsertTopMaterials && (this.currProduct.largeInsertTopMaterial[0].code === 'VAL' || this.currProduct.largeInsertTopMaterial[0].code === 'VWL')) || (this.availableOptions.smallInsertTopMaterials && (this.currProduct.smallInsertTopMaterial[0].code === 'VAS' || this.currProduct.smallInsertTopMaterial[0].code === 'VWS' || this.currProduct.smallInsertTopMaterial[0].code === 'SAS' || this.currProduct.smallInsertTopMaterial[0].code === 'SWS')) || this.productData.title == 'HK01-01') && this.currProduct.woodFinish[0].code ? `-${this.currProduct.woodFinish[0].code}` : ''}
        ${(this.availableOptions.smallInsertTopMaterials && this.currProduct.smallInsertTopMaterial[0].code === 'STS') && (this.currProduct.stoneQuartzTableTop && this.currProduct.stoneQuartzTableTop[0].code) ? `-${this.currProduct.stoneQuartzTableTop[0].code}` : ''}
        ${(this.currProduct.caseworkSurfaceMaterial.length && this.currProduct.caseworkSurfaceMaterial[0].code) ? `-${this.currProduct.caseworkSurfaceMaterial[0].code}` : ''}
        ${(this.currProduct.columnShroudPaint.length && this.currProduct.columnShroudPaint[0].code) ? `-${this.currProduct.columnShroudPaint[0].code}` : ''}
        ${(this.currProduct.legMaterial.length && this.currProduct.legMaterial[0].code) ? `-${this.currProduct.legMaterial[0].code}` : ''}
        ${((!this.currProduct.legMaterial.length || (this.currProduct.legMaterial.length && this.currProduct.legMaterial[0].code !== 'LW')) && (this.currProduct.metalFinish.length && this.currProduct.metalFinish[0].code)) && !(this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') ? `-${this.currProduct.metalFinish[0].code}` : ''}
        ${((!this.currProduct.legMaterial.length || (this.currProduct.legMaterial.length && this.currProduct.legMaterial[0].code !== 'LM')) && (this.currProduct.woodFinish.length && this.currProduct.woodFinish[0].code)) && !(this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3' || this.productData.title == 'HK01-01') && !((this.productData.title == 'HK01-T1' || this.productData.title == 'HK01-T2') && this.currProduct.tableTopMaterial[0].code == 'FTT') && !(this.availableOptions.finishType && this.currProduct.finishType === 'paintedWood') ? `-${this.currProduct.woodFinish[0].code}` : ''}
        ${(this.currProduct.paintedWood.length && this.currProduct.paintedWood[0].code) && !(this.availableOptions.finishType && this.currProduct.finishType === 'woodFinish') ? `-${this.currProduct.paintedWood[0].code}` : ''}
        ${(this.currProduct.metalFinish.length && this.currProduct.metalFinish[0].code) && (this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3') ? `-${this.currProduct.metalFinish[0].code}` : ''}
        ${(this.availableOptions.swivelBaseTypes && this.currProduct.swivelBaseType[0].code) ? `-${this.currProduct.swivelBaseType[0].code}` : ''}
        ${(this.availableOptions.leatherArmWraps && (this.currProduct.leatherArmWrap[0].code && this.currProduct.leatherArmWrap[0].code != 'LXXX')) ? `-${this.currProduct.leatherArmWrap[0].code}` : ''}
        ${this.currProduct.moistureBarrier ? '-MB' : ''}
        ${this.currProduct.removeableCovers ? '-RC' : ''}
        ${this.currProduct.calTb133 ? '-FB' : ''}
        ${this.currProduct.casters ? '-CAS' : ''}
        ${this.currProduct.removeableArmPads ? '-AP' : ''}
        ${this.currProduct.hangingFixture ? '-DJ' : ''}
        ${(this.currProduct.hingesOne.length && this.currProduct.hingesOne[0].code) ? `-${this.currProduct.hingesOne[0].code}` : ''}
        ${(this.currProduct.hingesTwo.length && this.currProduct.hingesTwo[0].code) ? `-${this.currProduct.hingesTwo[0].code}` : ''}
        ${(this.currProduct.hingesThree.length && this.currProduct.hingesThree[0].code) ? `-${this.currProduct.hingesThree[0].code}` : ''}
        ${(this.currProduct.pull.length && this.currProduct.pull[0].code) ? `-${this.currProduct.pull[0].code}` : ''}
        ${(this.currProduct.wrap.length && this.currProduct.wrap[0].code) ? `-${this.currProduct.wrap[0].code}` : ''}
        ${this.currProduct.drawerLiner ? '-DL' : ''}
        ${(this.currProduct.veneerOuterCase.length && this.currProduct.veneerOuterCase[0].code) ? `-${this.currProduct.veneerOuterCase[0].code}` : ''}
        ${this.currProduct.paperTowelDispenser ? '-TD' : ''}
        ${this.currProduct.extraAdjustableShelf ? '-AS' : ''}
        ${this.currProduct.fileHolders ? '-FH' : ''}
        ${this.currProduct.gloveCutOuts ? '-GH' : ''}
        ${(this.availableOptions.powerModules && this.currProduct.powerModules[0].code !== 'XX') ? `-${this.currProduct.powerModules[0].code}` : ''}
        ${(this.currProduct.multTextilesOption.length && this.currProduct.multTextilesOption[0].code && this.currProduct.multTextilesOption[0].code !== 'XX') ? `-${this.currProduct.multTextilesOption[0].code}` : ''}
      `;

      return code.replace(/^\s+|\s+$/gm, '').split('\n').join('');
    },
    fetchLineArtUrl() {
      let imageUrl = '';
      let filename = `
      ${this.productData.title}
      ${(this.availableOptions.species && this.currProduct.species[0].code && !this.isMaru()) ? `-${this.currProduct.species[0].code}` : ''}
      ${(this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code) ? `-${this.currProduct.moduleOne[0].code}` : ''}
      ${(this.isThreeSeat === 1 && this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code === 'SET') ? `-NA` : (this.currProduct.moduleTwo.length && this.currProduct.moduleTwo[0].code) ? `-${this.currProduct.moduleTwo[0].code}` : ''}
      ${(this.isThreeSeat === 1 && this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code === 'SET') ? `-NA` : (this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code) ? `-${this.currProduct.moduleThree[0].code}` : ''}
      ${(this.isThreeSeat === 1 && this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code === 'SET') ? `-NA` : (this.currProduct.moduleFour.length && this.currProduct.moduleFour[0].code) ? `-${this.currProduct.moduleFour[0].code}` : ''}
      ${(this.isThreeSeat === 1 && this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code === 'SET') ? `-NA` : (this.currProduct.moduleFive.length && this.currProduct.moduleFive[0].code) ? `-${this.currProduct.moduleFive[0].code}` : ''}
      ${(this.availableOptions.armHeights && this.currProduct.armHeight[0].code) ? `-${this.currProduct.armHeight[0].code}` : ''}      ${(this.availableOptions.tableTopSizes && this.currProduct.tableTopSize[0].code) ? `-${this.currProduct.tableTopSize[0].code}` : ''}
      ${(this.availableOptions.pads && this.currProduct.pads[0].code !== 'XX') ? `-${this.currProduct.pads[0].code}` : ''}
      ${(this.availableOptions.tables && this.currProduct.tables[0].code !== 'XX') ? `-${this.currProduct.tables[0].code}` : ''}
      ${(this.availableOptions.partitions && this.currProduct.partitions[0].code !== 'XX') ? `-${this.currProduct.partitions[0].code}` : ''}
      `;
      filename = filename.replace(/^\s+|\s+$/gm, '').split('\n').join('')
      imageUrl = this.productData ? `/uploads/line-art/${filename}.svg` : '';
      return this.editingLoading ? '' : imageUrl;
    },
    fetchModuleLineArt() {
      let imageUrl = '';
      let filename = `
      ${'Tandem-'}
      ${ this.isThreeSeat ? 'Three-Seat' : 'Two-Seat' }
      `;
      filename = filename.replace(/^\s+|\s+$/gm, '').split('\n').join('')
      imageUrl = this.productData ? `/uploads/line-art/${filename}.svg` : '';
      return imageUrl;
    },
    fetchPullsLineArt() {
      let imageUrl = '';
      let filename = `
      ${'Pulls-'}
      ${(this.currProduct.pull.length && this.currProduct.pull[0].code) ? `${this.currProduct.pull[0].code}` : ''}
      `;
      filename = filename.replace(/^\s+|\s+$/gm, '').split('\n').join('')
      imageUrl = this.productData ? `/uploads/line-art/${filename}.svg` : '';
      return imageUrl;
    },
    totalUnits: function() {
        return this.workingData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.quantity);
          let valueCleaned =  parseFloat(value);
          return parseFloat(valueCleaned);
        }, 0);
    },
    unitListPrice: function() {
        let price = currency(this.currProduct.baseCom)
          .add((this.availableOptions.species && this.currProduct.species[0].code) ? this.currProduct[`price${this.currProduct.species[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.moduleOne.length && this.currProduct.moduleOne[0].code) ? this.currProduct[`price${this.currProduct.moduleOne[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.moduleTwo.length && this.currProduct.moduleTwo[0].code) && !(this.isThreeSeat === 1 && this.currProduct.moduleOne[0].code === 'SET') ? this.currProduct[`price${this.currProduct.moduleTwo[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.moduleThree.length && this.currProduct.moduleThree[0].code) && !(this.isThreeSeat === 1 && this.currProduct.moduleOne[0].code === 'SET') ? this.currProduct[`price${this.currProduct.moduleThree[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.moduleFour.length && this.currProduct.moduleFour[0].code) && !(this.isThreeSeat === 1 && this.currProduct.moduleThree[0].code === 'SET') ? this.currProduct[`price${this.currProduct.moduleFour[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.moduleFive.length && this.currProduct.moduleFive[0].code) && !(this.isThreeSeat === 1 && this.currProduct.moduleThree[0].code === 'SET') ? this.currProduct[`price${this.currProduct.moduleFive[0].code.replace(/-/g, '')}`] : 0)
          .add((this.availableOptions.armHeights && this.currProduct.armHeight[0].code) ? this.currProduct[`price${this.currProduct.armHeight[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.tableTopSize.length && this.currProduct.tableTopSize[0].code) ? this.currProduct[`price${this.currProduct.tableTopSize[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.armCapMaterial.length && this.currProduct.armCapMaterial[0].code) ? this.currProduct[`price${this.currProduct.armCapMaterial[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.pads.length && this.currProduct.pads[0].code) ? this.currProduct[`price${this.currProduct.pads[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.tables.length && this.currProduct.tables[0].code) ? this.currProduct[`price${this.currProduct.tables[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.partitions.length && this.currProduct.partitions[0].code) ? this.currProduct[`price${this.currProduct.partitions[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.powerModules.length && this.currProduct.powerModules[0].code) ? this.currProduct[`price${this.currProduct.powerModules[0].code.replace(/-/g, '')}`] : 0)
          .add(((this.currProduct.tableTopMaterial.length && this.currProduct.tableTopMaterial[0].code) && (((this.isTwoSeat === 1 || this.isThreeSeat === 1) && (this.currProduct.moduleOne[0].code === 'INL' || this.currProduct.moduleThree[0].code === 'INL' || (this.currProduct.moduleFive.length && this.currProduct.moduleFive[0].code === 'INL'))) || (this.availableOptions.modules === false)) && !this.isMaru()) || ((this.currProduct.tableTopMaterial.length && this.currProduct.tableTopMaterial[0].code) && (this.isMaru() && ((this.availableOptions.tableTopMaterials && !this.availableOptions.tables) || (this.availableOptions.tableTopMaterials && this.availableOptions.tables && this.currProduct.tables[0].code !== 'XX')))) ? this.currProduct[`price${this.currProduct.tableTopMaterial[0].code.replace(/-/g, '')}`] : 0) // Requires "INL" be set on at least one module, if modules exist
          
          .add((this.currProduct.tableTopMaterialFinishSideA.length && this.currProduct.tableTopMaterialFinishSideA[0].code) ? this.productData.pricing[this.currProduct.tableTopMaterialFinishSideA[0].code.replace(/-/g, '')] : 0)
          
          .add(((this.currProduct.tableTopMaterialFinishSideB.length && this.currProduct.tableTopMaterialFinishSideB[0].code) && this.currProduct.tableTopMaterial.length === 1 && (this.currProduct.tableTopMaterial[0].code === 'FTT' || this.currProduct.tableTopMaterial[0].code === 'LTT')) ? this.productData.pricing[this.currProduct.tableTopMaterialFinishSideB[0].code.replace(/-/g, '')] : 0)
          
          .add((this.currProduct.tableTopMaterial.length && this.currProduct.tableTopMaterial[0].code === 'FTT') && (this.currProduct.tableTopMaterialForboFinish.length && this.currProduct.tableTopMaterialForboFinish[0].code) ? this.productData.pricing[this.currProduct.tableTopMaterialForboFinish[0].code.replace(/-/g, '')] : 0)    
          .add((this.currProduct.tableTopMaterial.length && this.currProduct.tableTopMaterial[0].code === 'MTT') && (this.currProduct.tableTopMaterialMetalFinish.length && this.currProduct.tableTopMaterialMetalFinish[0].code) ? this.productData.pricing[this.currProduct.tableTopMaterialMetalFinish[0].code.replace(/-/g, '')] : 0)
          .add((this.currProduct.largeInsertTopMaterial.length && this.currProduct.largeInsertTopMaterial[0].code) ? this.currProduct[`price${this.currProduct.largeInsertTopMaterial[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.largeInsertTopMaterial.length && this.currProduct.largeInsertTopMaterial[0].code === 'FTL') && (this.currProduct.largeInsertTopMaterialForboFinish.length && this.currProduct.largeInsertTopMaterialForboFinish[0].code) ? this.productData.pricing[this.currProduct.largeInsertTopMaterialForboFinish[0].code.replace(/-/g, '')] : 0)
          .add((this.currProduct.largeInsertTopMaterial.length && this.currProduct.largeInsertTopMaterial[0].code === 'SSL') && (this.currProduct.largeInsertTopMaterialSolidSurfaceFinish.length && this.currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code) ? this.productData.pricing[this.currProduct.largeInsertTopMaterialSolidSurfaceFinish[0].code.replace(/-/g, '')] : 0)
          .add((this.currProduct.smallInsertTopMaterial.length && this.currProduct.smallInsertTopMaterial[0].code) ? this.currProduct[`price${this.currProduct.smallInsertTopMaterial[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.smallInsertTopMaterial.length && this.currProduct.smallInsertTopMaterial[0].code === 'FTS') && (this.currProduct.smallInsertTopMaterialForboFinish.length && this.currProduct.smallInsertTopMaterialForboFinish[0].code) ? this.productData.pricing[this.currProduct.smallInsertTopMaterialForboFinish[0].code.replace(/-/g, '')] : 0)
          .add((this.currProduct.smallInsertTopMaterial.length && this.currProduct.smallInsertTopMaterial[0].code === 'SSS') && (this.currProduct.smallInsertTopMaterialSolidSurfaceFinish.length && this.currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code) ? this.productData.pricing[this.currProduct.smallInsertTopMaterialSolidSurfaceFinish[0].code.replace(/-/g, '')] : 0)
          .add((this.currProduct.caseworkSurfaceMaterial.length && this.currProduct.caseworkSurfaceMaterial[0].code) ? this.currProduct[`price${this.currProduct.caseworkSurfaceMaterial[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.columnShroudPaint.length && this.currProduct.columnShroudPaint[0].code) ? this.currProduct[`price${this.currProduct.columnShroudPaint[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.legMaterial.length && this.currProduct.legMaterial[0].code) ? this.currProduct[`price${this.currProduct.legMaterial[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.metalFinish.length && this.currProduct.metalFinish[0].code) && (this.currProduct.legMaterial.length && this.currProduct.legMaterial.code !== 'LW') ? this.currProduct[`price${this.currProduct.metalFinish[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.metalFinish.length && this.currProduct.metalFinish[0].code) && ((this.currProduct.legMaterial.length && this.currProduct.legMaterial.code !== 'LW') || !this.currProduct.legMaterial.length) ? this.currProduct[`price${this.currProduct.metalFinish[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.woodFinish.length && this.currProduct.woodFinish[0].code) && ((!this.currProduct.legMaterial.length || (this.currProduct.legMaterial.length && this.currProduct.legMaterial.code !== 'LM')) || this.isMaru()) && !(this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3' || this.productData.title == 'NC01-T1') && !((this.productData.title == 'HK01-T1' || this.productData.title == 'HK01-T2') && this.currProduct.tableTopMaterial[0].code == 'FTT') && !this.isProse() && !(this.availableOptions.finishType && this.currProduct.finishType === 'paintedWood') ? this.currProduct[`price${this.currProduct.woodFinish[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.woodFinish.length && this.currProduct.woodFinish[0].code) && ((this.productData.title == 'MM01-T1' || this.productData.title == 'MM01-T2' || this.productData.title == 'MM01-T3' || this.productData.title == 'NC01-T1') && ((this.currProduct.largeInsertTopMaterial.length && (this.currProduct.largeInsertTopMaterial[0].code == 'VAL' || this.currProduct.largeInsertTopMaterial[0].code == 'VWL')) || (this.currProduct.smallInsertTopMaterial.length && (this.currProduct.smallInsertTopMaterial[0].code == 'VAS' || this.currProduct.smallInsertTopMaterial[0].code == 'VWS' || this.currProduct.smallInsertTopMaterial[0].code == 'SAS' || this.currProduct.smallInsertTopMaterial[0].code == 'SWS')))) && !this.isProse() ? this.currProduct[`price${this.currProduct.woodFinish[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.woodFinish.length && this.currProduct.woodFinish[0].code) && this.isProse() ? this.currProduct[`price${this.currProduct.woodFinish[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.paintedWood.length && this.currProduct.paintedWood[0].code) && !(this.availableOptions.finishType && this.currProduct.finishType === 'woodFinish') ? this.currProduct[`price${this.currProduct.paintedWood[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.stoneQuartzTableTop.length && this.currProduct.stoneQuartzTableTop[0].code) && (this.currProduct.smallInsertTopMaterial.length && this.currProduct.smallInsertTopMaterial[0].code == 'STS') ? this.currProduct[`price${this.currProduct.stoneQuartzTableTop[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.swivelBaseType.length && this.currProduct.swivelBaseType[0].code) ? this.currProduct[`price${this.currProduct.swivelBaseType[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.leatherArmWrap.length && this.currProduct.leatherArmWrap[0].code && this.currProduct.leatherArmWrap[0].code !== 'LXXX') ? this.currProduct[`price${this.currProduct.leatherArmWrap[0].code.replace(/-/g, '')}`] : 0)
          .add(this.currProduct.moistureBarrier ? this.currProduct.priceMB : 0)
          .add(this.currProduct.removeableCovers ? this.currProduct.priceRC : 0)
          .add(this.currProduct.calTb133 ? this.currProduct.priceFB : 0)
          .add(this.currProduct.casters ? this.currProduct.priceCAS : 0)
          .add(this.currProduct.removeableArmPads ? this.currProduct.priceAP : 0)
          .add(this.currProduct.hangingFixture ? this.currProduct.priceDJ: 0)
          .add((this.currProduct.hingesOne.length && this.currProduct.hingesOne[0].code) ? this.currProduct[`price${this.currProduct.hingesOne[0].code.replace(/-/g, '')}1`] : 0)
          .add((this.currProduct.hingesTwo.length && this.currProduct.hingesTwo[0].code) ? this.currProduct[`price${this.currProduct.hingesTwo[0].code.replace(/-/g, '')}2`] : 0)
          .add((this.currProduct.hingesThree.length && this.currProduct.hingesThree[0].code) ? this.currProduct[`price${this.currProduct.hingesThree[0].code.replace(/-/g, '')}3`] : 0)
          .add((this.currProduct.pull.length && this.currProduct.pull[0].code) ? this.currProduct[`price${this.currProduct.pull[0].code.replace(/-/g, '')}`] : 0)
          .add((this.currProduct.wrap.length && this.currProduct.wrap[0].code) ? this.currProduct[`price${this.currProduct.wrap[0].code.replace(/-/g, '')}`] : 0)
          .add(this.currProduct.drawerLiner ? this.currProduct.priceDL : 0)
          .add((this.currProduct.veneerOuterCase.length && this.currProduct.veneerOuterCase[0].code) ? this.currProduct[`price${this.currProduct.veneerOuterCase[0].code.replace(/-/g, '')}`] : 0)
          .add(this.currProduct.paperTowelDispenser ? this.currProduct.priceTD : 0)
          .add(this.currProduct.extraAdjustableShelf ? this.currProduct.priceAS : 0)
          .add(this.currProduct.fileHolders ? this.currProduct.priceFH : 0)
          .add(this.currProduct.gloveCutOuts ? this.currProduct.priceGH : 0)
          .add((this.currProduct.multTextilesOption.length && this.currProduct.multTextilesOption[0].code) ? this.currProduct[`price${this.currProduct.multTextilesOption[0].code.replace(/-/g, '')}`] : 0);

        // Round cents up to nearest dollar
        price.value = Math.ceil(price.value);

        return price.value;
    },
    unitTextilePrice() {
        // Reset values
        this.singleTextilePrice = 0;
        this.armTextilePrice = 0;
        this.backTextilePrice = 0;
        this.seatTextilePrice = 0;
        this.sideATextilePrice = 0;
        this.sideBTextilePrice = 0;
        this.moduleYardage = 0;

        // If single textile
        if (this.availableOptions.textileBasicOptions) {
          // If a single has module textiles
          if (this.availableOptions.modules) {
            if (this.productData.modules.length === 3) {
              this.moduleYardage += this.currProduct.moduleOne[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
              this.moduleYardage += this.currProduct.moduleThree[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
            } else if (this.productData.modules.length === 5) {
              if (this.currProduct.moduleOne[0].code === 'SET') {
                this.moduleYardage += this.currProduct.moduleOne[0].code === 'SET' ? parseFloat(this.productData.yardage.set) : 0;
                this.moduleYardage += this.currProduct.moduleFive[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
              } else if (this.currProduct.moduleThree[0].code === 'SET') {
                this.moduleYardage += this.currProduct.moduleOne[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
                this.moduleYardage += this.currProduct.moduleThree[0].code === 'SET' ? parseFloat(this.productData.yardage.set) : 0;
              } else {
                this.moduleYardage += this.currProduct.moduleOne[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
                this.moduleYardage += this.currProduct.moduleThree[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
                this.moduleYardage += this.currProduct.moduleFive[0].code === 'TAN' ? parseFloat(this.productData.yardage.tan) : 0;
              }
            }
            this.singleTextilePrice = (this.moduleYardage * this.currProduct[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]);
          } else {
            if (this.productData.title == 'KN01-S1') {
                this.singleTextilePrice = (this.productData.yardage.total > 0 && this.currProduct.tableTopMaterial[0].code === 'UPH') ? (this.productData.yardage.total * this.textileData[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]) : 0;
            } else {
              this.singleTextilePrice = ((this.isMaru() || this.isApril()) && this.productData.pads.length && !this.productData.multTextilesOption.length) ? (parseFloat(this.currProduct.pads[0].padYardage) * this.textileData[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]) : (this.productData.yardage.total * this.textileData[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]);
            }

          }
        }

        // If multiple textile(s)
        if (this.availableOptions.multTextilesOption) {
          // If set to XX
          if (this.currProduct.multTextilesOption[0].code === 'XX') {
            // If working with daybed, set proper yardage, else function with default
            if (this.currProduct.baseCode === 'GS01-37') {
                this.singleTextilePrice = ((this.productData.armHeights.length ? (parseFloat(this.currProduct.armHeight[0].armYardage) + parseFloat(this.productData.yardage.seat) + parseFloat(this.productData.yardage.back)).toFixed(2) : this.productData.yardage.total) * this.currProduct[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]).toFixed(2);
            } else {
                this.singleTextilePrice = (this.isMaru() && this.productData.pads.length) ? ((parseFloat(this.currProduct.pads[0].padYardage) + parseFloat(this.productData.yardage.total)) * this.textileData[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]) : (this.productData.yardage.total * this.textileData[`price${this.currProduct.singleTextileGrade[0].code.replace(/-/g, '')}`]);

            }
          } else {
              this.singleTextilePrice = 0;
          }
          // If set to TA
          if (this.currProduct.multTextilesOption[0].code === 'TA') {
            // Set seat
            this.seatTextilePrice = (this.isMaru() && this.productData.pads.length) ? (this.currProduct.pads[0].padYardage * this.textileData[`price${this.currProduct.seatTextileGrade[0].code.replace(/-/g, '')}`]) : (this.productData.yardage.seat * this.textileData[`price${this.currProduct.seatTextileGrade[0].code.replace(/-/g, '')}`]);
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.currProduct[`price${this.currProduct.backTextileGrade[0].code.replace(/-/g, '')}`]);
          }
          // If set to AT
          if (this.currProduct.multTextilesOption[0].code === 'AT') {
            // If working with daybed, set proper yardage, else function with default
            if (this.currProduct.baseCode === 'GS01-37') {
              // Set arm
              this.armTextilePrice =
                ((this.productData.armHeights.length ? parseFloat(this.currProduct.armHeight[0].armYardage) : this.productData.yardage.arm) * this.currProduct[`price${this.currProduct.armTextileGrade[0].code.replace(/-/g, '')}`]);
            } else {
              // Set arm
              this.armTextilePrice =
                (this.productData.yardage.arm * this.currProduct[`price${this.currProduct.armTextileGrade[0].code.replace(/-/g, '')}`]);
            }
            // Set seat
            this.seatTextilePrice =
              (this.productData.yardage.seat * this.currProduct[`price${this.currProduct.seatBackTextileGrade[0].code.replace(/-/g, '')}`]);
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.currProduct[`price${this.currProduct.seatBackTextileGrade[0].code.replace(/-/g, '')}`]);
          }
          // If set to ST
          if (this.currProduct.multTextilesOption[0].code === 'ST') {
            // Set seat
            this.seatTextilePrice =
              (this.productData.yardage.seat * this.currProduct[`price${this.currProduct.seatTextileGrade[0].code.replace(/-/g, '')}`]);
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.currProduct[`price${this.currProduct.armBackTextileGrade[0].code.replace(/-/g, '')}`]);

            // If working with daybed, set proper yardage, else function with default
            if (this.currProduct.baseCode === 'GS01-37') {
              // Set arm
              this.armTextilePrice =
                ((this.productData.armHeights.length ? parseFloat(this.currProduct.armHeight[0].armYardage) : this.productData.yardage.arm) * this.currProduct[`price${this.currProduct.armBackTextileGrade[0].code.replace(/-/g, '')}`]);
            } else {
              // Set arm
              this.armTextilePrice =
                (this.productData.yardage.arm * this.currProduct[`price${this.currProduct.armBackTextileGrade[0].code.replace(/-/g, '')}`]);
            }
          }
          // If set to BT
          if (this.currProduct.multTextilesOption[0].code === 'BT') {
            // Set back
            this.backTextilePrice =
              (this.productData.yardage.back * this.currProduct[`price${this.currProduct.backTextileGrade[0].code.replace(/-/g, '')}`]);
            // Set seat
            this.seatTextilePrice =
              (this.productData.yardage.seat * this.currProduct[`price${this.currProduct.armSeatTextileGrade[0].code.replace(/-/g, '')}`]);

            // If working with daybed, set proper yardage, else function with default
            if (this.currProduct.baseCode === 'GS01-37') {
              // Set arm
              this.armTextilePrice =
                ((this.productData.armHeights.length ? parseFloat(this.currProduct.armHeight[0].armYardage) : this.productData.yardage.arm) * this.currProduct[`price${this.currProduct.armSeatTextileGrade[0].code.replace(/-/g, '')}`]);
            } else {
              // Set arm
              this.armTextilePrice =
                (this.productData.yardage.arm * this.currProduct[`price${this.currProduct.armSeatTextileGrade[0].code.replace(/-/g, '')}`]);
            }
          }
          // If set to AB
          if (this.currProduct.multTextilesOption[0].code === 'AB') {
            // Set side a
            this.sideATextilePrice = (this.productData.yardage.sideA * this.currProduct[`price${this.currProduct.sideATextileGrade[0].code.replace(/-/g, '')}`]);
            // Set side b
            this.sideBTextilePrice = (this.productData.yardage.sideB * this.currProduct[`price${this.currProduct.sideBTextileGrade[0].code.replace(/-/g, '')}`]);
          }
        }

        // Add up totals
        let price = currency(this.singleTextilePrice ? this.singleTextilePrice : 0).add(this.armTextilePrice ? this.armTextilePrice : 0).add(this.backTextilePrice ? this.backTextilePrice : 0).add(this.seatTextilePrice ? this.seatTextilePrice : 0).add(this.sideATextilePrice ? this.sideATextilePrice : 0).add(this.sideBTextilePrice ? this.sideBTextilePrice : 0);

        price.value = Math.ceil(price.value);
        return price.value;
    },
    unitNetPrice() {
        let listTextile = this.unitListPrice + this.unitTextilePrice;
        let price = currency(listTextile - (listTextile * this.currProduct.productDiscount / 100));

        return price.value;
    },
    unitTaxPrice() {
        let price = currency((this.unitNetPrice * this.workingData.salesTax) / 100);

        return price.value;
    },
    extendedListPrice: function() {
        let price = currency((this.unitListPrice + this.unitTextilePrice) * this.currProduct.quantity);

        price.value = Math.ceil(price.value);
        return price.value;
    },
    extendedTextilePrice: function() {
        let price = currency((this.unitTextilePrice) * this.currProduct.quantity);

        price.value = Math.ceil(price.value);
        return price.value;
    },
    extendedNetPrice() {
        let price = currency((this.unitNetPrice) * this.currProduct.quantity);

        return price.value;
    },
    extendedTaxPrice() {
        let price = currency((this.unitTaxPrice) * this.currProduct.quantity);

        return price.value;
    },
    quoteListPrice: function() {
        return this.workingData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedListPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteTextilePrice: function() {
        return this.workingData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedTextilePrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteNetPrice: function() {
        return this.workingData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedNetPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteTaxPrice: function() {
        return this.workingData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedTaxPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    },
    quoteTotalPrice: function() {
        return this.workingData.products.reduce((total, item, n) => {
          let value = currency(total).add(item.product.extendedNetPrice).add(item.product.extendedTaxPrice);
          let valueCleaned =  parseFloat(value).toFixed(2).replace(/\.00/g,'');
          return parseFloat(valueCleaned);
        }, 0);
    }
  },
  filters: {
    formatPrice(x) {
      if (!x) return;
      x = x.toString();
      x = Math.ceil(x);
      return currency(x).format({symbol: true});
    },
    formatNetPrice(x) {
      if (!x) return;
      x = x.toString();
      return currency(x).format({symbol: true});
    }
  },
  created: function () {
    // Check whether or not there is unsaved data
    window.onbeforeunload = () => {
      // Get saved results
      var result = this.onLeave();

      // If unsaved results exist,
      // display dialog box to user
      if (result) {
        return 'You have unsaved changes. Are you sure you want to leave?';
      }
    }
  }
}
</script>
