<template>
  <div :class="{ 'is-visible': loading }"><span>Loading...</span></div>
</template>

<style lang="scss" scoped>
  @import "../tokens.scss";

  div {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#fff, .85);
    opacity: 0;
    transition: opacity ease-in-out 0.2s;
    pointer-events: none;
    z-index: 2000;

    &.is-visible {
      opacity: 1;
    }
  }

  div span {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important;
  }

  div::after {
    content: '';
    animation: spin 750ms linear infinite;
    position: absolute;
    top: calc(50% - 16px);
    left: calc(50% - 16px);
    width: 24px;
    height: 24px;
    border: 4px solid map-get($colors, deep-blue);
    border-top-color: transparent;
    border-left-color: transparent;
    border-radius: 50%;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
</style>

<script>
  export default {
    name: 'Loader',
    props: ['loading']
  }
</script>
